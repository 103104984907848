<template>
  <div class="complaint">
    <div class="complaint-header">投诉处理</div>
    <div class="complaint-content">
      <div class="content-header">
        <a-checkbox
          v-model:checked="checkAll"
          :indeterminate="indeterminate"
          @change="onCheckAllChange"
        >
          全选
        </a-checkbox>
        <a-popconfirm
          title="是否需要删除?"
          ok-text="是"
          cancel-text="否"
          @confirm="confirm"
        >
          <p class="btn" @click="remove">删除</p>
        </a-popconfirm>
        <p class="btn" @click="read">标记已读</p>
      </div>
      <a-checkbox-group
        v-model:value="checkedList"
        style="width: 100%"
        @change="onChange"
      >
        <div
          class="content-list"
          v-for="(item, index) in plainOptions"
          :key="index"
        >
          <a-checkbox v-model:value="item.id" />
          <div
            @click="gotoDetails(item)"
            style="display: flex; align-items: center; width: 100%"
          >
            <span class="tag" :class="{ color: item.is_read == 2 }"></span>
            <span style="margin-left: 10px; width: 10%">投诉处理</span>
            <span style="color: #808695; margin-left: 30px; width: 70%">{{
              item.content
            }}</span>
            <span style="color: #808695; margin-left: 40px; width: 20%">{{
              item.create_at
            }}</span>
          </div>
        </div>
      </a-checkbox-group>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { complainList, delComplain, updateComplain } from '@/api/user'
import Message from '@/components/library/Message'
export default {
  setup () {
    // router 路由器（管理路由的-老大）
    const router = useRouter()
    const state = reactive({
      indeterminate: true,
      checkAll: false,
      checkedList: [],
    });
    const plainOptions = ref([])
    const onChange = (checkedList) => {
      console.log(checkedList)
      state.indeterminate = !!checkedList.length && checkedList.length < plainOptions.value.length;
      state.checkAll = checkedList.length === plainOptions.value.length;
    }
    const onCheckAllChange = (e) => {
      console.log(e)
      const value = []
      plainOptions.value.forEach(item => {
        value.push(item.id)
      })
      console.log(value)
      console.log(plainOptions.value)
      Object.assign(state, {
        checkedList: e.target.checked ? value : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    };
    const gotoDetails = (item) => {
      router.push({ path: `/account/complaint-details/${item.id}` })
    }
    const getList = async () => {
      const res = await complainList()
      plainOptions.value = res.data.list
      console.log(plainOptions.value)
    }
    const read = async () => {
      const res = await updateComplain({
        id: state.checkedList
      })
      if (res.code == 0) {
        getList()
      }
    }
    const confirm = async(e) => {
      console.log(e);
      if (state.checkedList.length == 0) {
        Message({ type: 'warn', message: '请经行勾选' })
        return
      }
      const res = await delComplain({
        id: state.checkedList
      })
      if (res.code == 0) {
        getList()
      }
    };
    const remove = async () => {

    }
    onMounted(() => {
      getList()
    })
    return {
      ...toRefs(state),
      plainOptions,
      onCheckAllChange,
      onChange,
      gotoDetails,
      getList,
      read,
      remove,
      confirm,
    }
  }
}
</script>
<style scoped lang="less">
.complaint {
  width: 940px;
  min-height: 860px;
  background: #ffffff;
  padding: 40px;
  .complaint-header {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #f1f1f4;
    font-size: 16px;
    .complaint-header_list {
      width: 90px;
      height: 38px;
      margin-right: 32px;
      display: flex;
      justify-content: center;
      font-size: 18px;
      &.active {
        border-bottom: 1px solid #296df1;
        color: #296df1;
      }
    }
  }
}
.complaint-content {
  width: 100%;
  .content-header {
    width: 100%;
    height: 77px;
    border-bottom: 1px solid #f1f1f4;
    display: flex;
    align-items: center;
    padding-left: 19px;
    .btn {
      padding: 4px 18px;
      border: 1px solid #dcdee2;
      border-radius: 2px;
      font-size: 14px;
      color: #515a6e;
      margin-left: 10px;
    }
  }
  .content-list {
    width: 100%;
    min-height: 44px;
    border: 1px solid #f1f1f4;
    border-top: none;
    padding-left: 19px;
    display: flex;
    align-items: center;
    .tag {
      display: block;
      width: 5px;
      height: 5px;
      background: #ff3c31;
      border-radius: 50%;
      margin-left: 22px;
      &.color {
        background: #ffffff;
      }
    }
  }
}
</style>