
<script>
/*
  知识点：
    1. createVNode (h)函数，可以用于创建虚拟DOM (配合render函数渲染到页面中)
    2. render 函数返回的内容其实就是 template 模板的内容
    3. 组件实例.$slots.default()  获取默认插槽数据
*/
import { createVNode } from 'vue'
export default {
  name: 'XtxBread',
  // Vue3 的 render 形参其实是当前组件的实例，相当于Vue2的this
  render ({ $slots }) {
    // 获取默认插槽
    // 获取默认插槽，过滤掉不符合规范的子项组件
    const slot = $slots.default().filter(item => item.type.name === 'XtxBreadItem' || item.type.name === 'Transition')
    console.log('默认插槽', slot)

    const arr = []
    // 遍历默认插槽的虚拟DOM数组
    slot.forEach((item, index) => {
      arr.push(item)
      if (index < slot.length - 1) {
        // 创建个虚拟 i 标签
        const iVNode = createVNode('i', { class: 'iconfont icon-angle-right' })
        arr.push(iVNode)
      }
    })

    // h函数可以用于创建虚拟DOM
    //   语法：h('标签名称', 标签属性, 标签内容)
    const boxVNode = createVNode('div', { class: 'xtx-bread' }, arr)
    // console.log('虚拟DOM(对象)', box)
    // render 函数 return 出来的内容其实就是 template 的内容
    return boxVNode
  }
}
</script>

<style scoped lang='less'>
.xtx-bread{
  display: flex;
  align-items: center;
  // padding: 25px 10px;
  &-item {
    a {
      color: #666;
      transition: all .4s;
      &:hover {
        color: @xtxColor;
      }
    }
  }
  i {
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
    line-height: 12px;
  }
}
</style>
