<template>
  <div>
    <!-- 购物车顶部 -->
    <cartHeader title="收银台" />
    <!-- 内容 -->
    <div class="content" v-if="isshow">
      <div class="content_son">
        <div class="content_br1">
          <div class="content_br1_header">
            <div style="display: flex">
              <img src="@/assets/images/fish@2x.png" alt="" srcset="" />
              <p style="display: flex; flex-direction: column">
                <span style="font-size: 26px; font-weight: 700; color: #17233d"
                  >订单已提交！</span
                >
                <!-- <span>请在24小时0分内完成支付，超时后将取消订单</span> -->
              </p>
            </div>
            <p>
              <span>应付总额：</span>
              <span style="font-size: 18px; color: #ff3c31; font-weight: 700"
                >￥{{ data.payment_amount }}</span
              >
            </p>
          </div>
          <div
            style="
              padding: 27px 90px 0 90px;
              font-size: 14px;
              color: #808695;
              height: 159px;
            "
          >
            <a-row>
              <a-col :span="3">订单号：</a-col>
              <a-col :span="12" style="color: #296df1">{{
                data.order_no
              }}</a-col>
            </a-row>
            <a-row style="margin-top: 10px">
              <a-col :span="3">收货信息：</a-col>
              <a-col :span="21"
                >{{ data.address }} ({{ data.name }}){{ data.phone }}
                {{ data.email }}
              </a-col>
            </a-row>
            <!-- <a-row style="margin-top: 10px">
              <a-col :span="3">产品名称：</a-col>
              <a-col :span="12">编制商务函件 x1</a-col>
            </a-row> -->
          </div>
        </div>
        <div class="content_br2">
          <p>请选择以下支付方式</p>
          <div class="content_br2_type">
            <p
              v-for="(item, index) in payWay"
              :key="index"
              :class="{ active: activIndex == index }"
              @click="changePay(item, index)"
            >
              <img :src="item.img" alt="" srcset="" />
            </p>
          </div>
          <div class="next">
            <div></div>
            <XtxButton type="primary" @click="submit">下一步</XtxButton>
          </div>
        </div>
      </div>
    </div>
    <div class="content" v-else>
      <div class="content_son">
        <div class="content_br1">
          <div class="content_br1_header">
            <div style="display: flex">
              <img src="@/assets/images/fish@2x.png" alt="" srcset="" />
              <p style="display: flex; flex-direction: column">
                <span style="font-size: 26px; font-weight: 700; color: #17233d">
                  {{
                    isGongHu
                      ? "请等待后台确认"
                      : "支付成功！我们会尽快为您发货！"
                  }}
                </span>
                <span>订单号：{{ data.order_no }}</span>
                <span>在线支付：{{ data.payment_amount }}元</span>
              </p>
            </div>
          </div>
          <div
            style="
              padding: 27px 90px 0 90px;
              font-size: 14px;
              color: #296df1;
              height: 80px;
            "
          >
            <router-link to="/" target="_blank">
              <span style="margin-right: 18px">继续逛逛</span>
            </router-link>
            <router-link
              v-if="!isGongHu"
              :to="`/account/order-details/${id}`"
              target="_blank"
            >
              <span style="margin-right: 18px; color: #f1f1f4">|</span>
              <span>查看订单详情</span>
            </router-link>
          </div>
        </div>
        <div class="Sales-service-content"></div>
      </div>
    </div>
    <!-- 公共底部 -->
    <AppFooter />
  </div>
  <a-modal
    v-model:visible="isbalance"
    width="250px"
    title="请输入支付密码"
    cancelText="取消"
    okText="确认"
    centered
    @ok="balancePay"
  >
    <a-input
      v-model:value="pay_pwd"
      placeholder="请输入"
      style="width: 200px; height: 35px; border: 1px solid #d9d9d9"
    />
  </a-modal>
</template>
<script>
// import vueQr from 'vue-qr'
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted, reactive } from "vue";
import cartHeader from "./components/cart-header.vue";
import AppFooter from "@/components/app-footer.vue";
import { Pay, queryOrderStatus } from "@/api/Orders";
import Message from "@/components/library/Message";
export default {
  components: { cartHeader, AppFooter },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isshow = ref(true);
    const isGongHu = ref(false);
    const data = ref("");
    const pay_way = ref(1);
    const activIndex = ref(0);
    const div = ref("");
    const id = ref("");
    const qrcodeUrl = ref("");
    const pay_pwd = ref("");
    const payWay = reactive([
      {
        status: 1,
        // 支付宝
        img: require("@/assets/images/zhifubao.png"),
      },
      {
        status: 2,
        // 微信
        img: require("@/assets/images/weixin@2x.png"),
      },
      {
        status: 3,
        img: require("@/assets/images/balance.png"),
      },
      // 20220407 公户转帐
      // 提示 等待后台确认
      {
        status: 4,
        img: require("@/assets/images/gonghu.png"),
      },
    ]);
    // 请输入支付密码
    const isbalance = ref(false);
    // 是否是公户

    const balancePay = async () => {
      const res = await Pay({
        order_no: data.value.order_no,
        price: data.value.payment_amount,
        pay_way: pay_way.value,
        pay_pwd: pay_pwd.value,
      });
      if (res.code === 0) {
        Message({ type: "success", message: "支付成功" });
        isbalance.value = false;
        getstatus();
      }
    };
    const submit = async () => {
      if (pay_way.value === 4) {
        setTimeout(() => {
          isshow.value = false;
          isGongHu.value = true;
        }, 500);
        return;
      }
      if (pay_way.value === 3) {
        setTimeout(() => {
          isbalance.value = true;
        }, 500);
        return;
      }
      const res = await Pay({
        order_no: data.value.order_no,
        price: data.value.payment_amount,
        pay_way: pay_way.value,
      });
      console.log("----Pay---", res);
      console.log(pay_way.value);
      if (res.code == 0) {
        if (pay_way.value == 1) {
          // 支付宝
          const div = document.createElement("div");
          console.log("res", res);
          // 	20220409102535907382
          /* 下面的data.content就是后台返回接收到的数据 */
          div.innerHTML = res.code_url;
          console.log(div);
          document.body.appendChild(div);
          document.forms[0].submit();
        } else {
          qrcodeUrl.value = res.code_url;
          console.log(qrcodeUrl.value);
          const details = {
            qrcodeUrl: qrcodeUrl.value,
            order_no: data.value.order_no,
            price: data.value.payment_amount,
          };
          router.push({
            path: "/order/weixinPay",
            query: { data: encodeURIComponent(JSON.stringify(details)) },
          });
        }
      }
    };
    const changePay = (item, index) => {
      activIndex.value = index;
      pay_way.value = item.status;
    };
    const getstatus = async () => {
      // if (!data.value.order_no) {
      //   return Message({ type: "error", message: "没有order_no" });
      // }
      const res = await queryOrderStatus({
        order_no: data.value.order_no,
      });
      if (res.code == 0) {
        if (res.data.status == 2) {
          isshow.value = false;
          data.value = res.data;
          id.value = res.data.id;
        }
      }
    };
    onMounted(() => {
      // http://www.wanmou.top/#/order/submit?order_no=20220409102535907382&charset=utf-8&out_trade_no=20220409102535907382&method=alipay.trade.page.pay.return&total_amount=1.00&sign=WpzlsEHFZa5%2F8hj7ddlcbepbYb1wSnOvOBbp93OQ3O2qjpka%2BsD%2FqgVvuiFMEuU%2FiiBHxy5nKyNQ587bS529iizLjO5EGbqXeh4X8bPBMTsTuWdYhw2skhOCQlc0c0vIkkDN34rV1i%2FB5mR%2FLUMG9tqK%2B0ViFHlD9semXuj2avtly5pCxmmm4X9bgWN3nbiln5NiYX0%2FJpIYE6dZNE9VKKTlgXiNAgSxuWhw8kmAeN4fN9%2Ft2eESG%2BlIheKIOB%2F71vQZWMz3XnDEwjS46EQswvVfXuXrRWq5uiXtHcSVlVOGh%2BX%2B11ZZ98vvHTv6uvzKq9hLyhGik0T71nCDTClACg%3D%3D&trade_no=2022040922001494911430912608&auth_app_id=2021002126678169&version=1.0&app_id=2021002126678169&sign_type=RSA2&seller_id=2088731559899745&timestamp=2022-04-09+10%3A27%3A38
      console.log("---onmoute", route.query);
      if (route.query.isGongHu) {
        isshow.value = false;
        isGongHu.value = true;
        data.value = JSON.parse(decodeURIComponent(route.query.data));
      } else {
        if (route.query.data) {
          data.value = JSON.parse(decodeURIComponent(route.query.data));
          getstatus();
        } else if (route.query.order_no) {
          data.value = route.query;
          data.value.payment_amount = route.query.total_amount;
          isshow.value = false;
          isGongHu.value = false;
          getstatus();
        } else {
          isshow.value = false;
          // data.value = route.query

          console.log(route.query);
          getstatus();
        }
      }
    });
    return {
      isshow,
      isGongHu,
      data,
      submit,
      pay_way,
      payWay,
      activIndex,
      changePay,
      div,
      getstatus,
      id,
      qrcodeUrl,
      isbalance,
      pay_pwd,
      balancePay,
    };
  },
};
</script>
<style scoped lang="less">
.content {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 84px;
  .content_son {
    width: 1000px;
    min-height: 539px;
    margin: 0 auto;
    .content_br1 {
      width: 100%;
      // height: 329px;
      background: #ffffff;
      border-top: 2px solid #296df1;
      padding: 0 56px;
      .content_br1_header {
        height: 169px;
        width: 100%;
        padding-top: 68px;
        border-bottom: 1px solid #f1f1f4;
        display: flex;
        justify-content: space-between;
        color: #808695;
        img {
          width: 60px;
          height: 60px;
          margin-right: 38px;
        }
      }
    }
    .content_br2 {
      width: 100%;
      height: 300px;
      background: #ffffff;
      margin-top: 10px;
      padding: 40px 30px 0 30px;
      font-size: 16px;
      .content_br2_type {
        margin-top: 14px;
        display: flex;
        p {
          width: 144px;
          height: 50px;
          border: 1px solid #eeeeee;
          margin-right: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.active {
            border: 1px solid#296DF1;
          }
        }
        img {
          width: 82px;
          height: 28px;
        }
      }
    }
  }
}
.next {
  display: flex;
  justify-content: space-between;
  margin-top: 84px;
}
</style>
