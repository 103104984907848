<template>
  <div class="invoice">
    <div class="invoice-header">发票抬头管理</div>
    <div class="invoice-content">
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        v-if="formState.type == 2"
      >
        <a-form-item label="发票类型：" name="type">
          <a-radio-group v-model:value="formState.type" @change="radioChange">
            <a-radio :value="0">暂不需要开具发票</a-radio>
            <a-radio :value="1">普通发票</a-radio>
            <a-radio :value="2">增值税发票</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="单位名称：" name="company_name">
          <a-input
            v-model:value="formState.company_name"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="纳税人识别号：" name="tax_no">
          <a-input
            v-model:value="formState.tax_no"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="注册地址：" name="address">
          <a-input
            v-model:value="formState.address"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="注册电话：" name="mobile">
          <a-input
            v-model:value="formState.mobile"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="开户银行：" name="bank">
          <a-input
            v-model:value="formState.bank"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="银行账户：" name="bank_account">
          <a-input
            v-model:value="formState.bank_account"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        v-if="formState.type == 1"
      >
        <a-form-item label="发票类型：" name="type">
          <a-radio-group v-model:value="formState.type" @change="radioChange">
            <a-radio :value="0">暂不需要开具发票</a-radio>
            <a-radio :value="1">普通发票</a-radio>
            <a-radio :value="2">增值税发票</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="发票抬头：" name="head">
          <a-select v-model:value="formState.head" style="width: 282px">
            <a-select-option :value="1">个人</a-select-option>
            <a-select-option :value="2">公司</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="个人姓名：" name="name" v-if="formState.head == 1">
          <a-input
            v-model:value="formState.name"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item
          label="公司名称："
          name="company_name"
          v-if="formState.head == 2"
        >
          <a-input
            v-model:value="formState.company_name"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item
          label="纳税识别号："
          name="tax_no"
          v-if="formState.head == 2"
        >
          <a-input
            v-model:value="formState.tax_no"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="发票内容："> 商品明细 </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        v-if="formState.type == 0"
      >
        <a-form-item label="发票类型：" name="type">
          <a-radio-group v-model:value="formState.type" @change="radioChange">
            <a-radio :value="0">暂不需要开具发票</a-radio>
            <a-radio :value="1">普通发票</a-radio>
            <a-radio :value="2">增值税发票</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRaw, onMounted } from 'vue'
import { saveBill, billInfo } from '@/api/user'
import Message from '@/components/library/Message'
export default {
  setup () {
    const formRef = ref();
    const formState = reactive({
      id: '',
      name: '',
      type: 0,
      head: '',
      company_name: '',
      tax_no: '',
      address: '',
      mobile: '',
      bank: '',
      bank_account: ''
    });
    const rules = {
      head: [
        { required: true, message: '请选择发票抬头' },
      ],
      name: [
        { required: true, message: '请输入个人姓名' },
      ],
      company_name: [
        { required: true, message: '请输入公司名称' }
      ],
      tax_no: [
        { required: true, message: '请输入纳税识别号' }
      ],
      address: [
        { required: true, message: '请输入地址' }
      ],
      mobile: [
        { required: true, message: '请输入手机号' }
      ],
      bank: [
        { required: true, message: '请输入开户银行' }
      ],
      bank_account: [
        { required: true, message: '请输入银行账号' }
      ]
    };
    const radioChange = (e) => {
      Object.keys(formState).forEach(key => {
        if ((key != 'type') && (key != 'id')) {
          formState[key] = ''
        }
      })
      console.log(e)
    }
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          console.log('values', formState, toRaw(formState));
          const res = await saveBill(formState)
          if (res.code == 0) {
            Message({ type: 'success', message: '提交成功' })
            formRef.value.resetFields()
            getbillInfo()
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
    const billInfos = ref({})
    const getbillInfo = async () => {
      const res = await billInfo()
      if (res.data) {
        Object.assign(formState, res.data)
        formState.header = res.data.header
        console.log(formState)
      }
    }
    onMounted(() => {
      getbillInfo()
    })
    return {
      formRef, formState, rules, onSubmit, labelCol: { span: 4 },
      wrapperCol: { span: 14 }, radioChange, getbillInfo, billInfos
    }
  }
}
</script>
<style scoped lang="less">
.invoice {
  width: 940px;
  min-height: 732px;
  .invoice-header {
    width: 100%;
    height: 56px;
    background: #ffffff;
    padding: 16px 40px;
    font-size: 16px;
  }
  .invoice-content {
    width: 100%;
    min-height: 670px;
    background: #ffffff;
    margin-top: 6px;
    padding: 30px 40px;
  }
}
</style>