<template>
  <header class="login-header">
    <div class="container">
      <RouterLink to="/">
        <h1 class="logo">
          <img src="@/assets/images/logo.png" alt="" />
        </h1>
      </RouterLink>
      <h3 class="sub"><slot /></h3>
    </div>
  </header>
</template>

<script>
export default {
  name: 'LoginHeader'
}
</script>

<style scoped lang='less'>
.login-header {
  width: 100%;
  height: 100px;
  padding: 0 458px;
  background: #ffffff;
  // border-bottom: 1px solid #e4e4e4;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    width: 342px;
    height: 36px;
  }
  .sub {
    flex: 1;
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 38px;
    margin-left: 20px;
    color: #666;
  }
  .entry {
    width: 120px;
    margin-bottom: 38px;
    font-size: 16px;
    i {
      font-size: 14px;
      color: @xtxColor;
      letter-spacing: -5px;
    }
  }
}
</style>
