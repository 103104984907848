<template>
  <div class="top-category">
    <div class="container_header">
      <!-- 面包屑 -->
      <!--
        mode="out-in"     旧元素先离开，新元素进入
        :key=""           key值不同，元素就不会复用以前的结构，会销毁旧的再创建新的
      -->
      <div
        style="width: 1198px; height: 100%; margin: 0 auto; position: relative"
      >
        <XtxBread class="xtx-bread">
          <XtxBreadItem to="/" class="xtx-bread-item" norColor="white">
            <!-- <img src="@/assets/images/home@2x.png" alt="" class="xtx-bread_img" /> -->
            首页
          </XtxBreadItem>
          <transition name="fade-right" mode="out-in"> 全部分类 </transition>
        </XtxBread>
      </div>
    </div>
    <div class="container">
      <div class="container_detail">
        <div>
          <a-row
            class="container_detail_type"
            v-for="(item, index) in AllCategory"
            :key="index"
          >
            <a-col :span="2">
              <p style="color: #808695">{{ item.name }}:</p>
            </a-col>
            <a-col :span="20">
              <template v-if="index==0">
                  <div class="container_detail_content">
                    <template v-for="(content, cindex) in item.child"
                    :key="cindex">
                      <span
                      class="normal"
                      @click="choseCategory(item, content)"
                      :class="{ active: content.selected }"
                      v-if="content.selected">{{ content.name }}</span>
                    </template>
                </div>
              </template>
              <template v-else>
                 <div class="container_detail_content">
                  <span
                    class="normal"
                    v-for="(content, cindex) in item.child"
                    :key="cindex"
                    @click="choseCategory(item, content)"
                    :class="{ active: content.selected }"
                    >{{ content.name }}</span
                  >
                </div>
              </template>
              
            </a-col>
          </a-row>
          <!-- <a-row>
            <a-col :span="2" style="color: #808695">万谋豆产品: </a-col>
            <a-col :span="22"> </a-col>
          </a-row> -->
          <a-checkbox
            v-model:checked="isbalance"
            style="color: #808695; margin-top: 10px"
            @change="change"
            >万谋豆产品</a-checkbox
          >
        </div>
      </div>
      <div class="container_list">
        <RouterLink
          :to="`/product/${item.id}`"
          class="container_list_content"
          v-for="(item, index) in goodsList"
          :key="index"
        >
          <img :src="item.slider" alt="" class="container_list_img" />
          <div class="container_lis_title1">
            <p>{{ item.name }}</p>
            <!-- <p style="color: #ff3c31; text-align: right">
              ￥{{ item.price_selling }}-{{ item.max_price_selling }}
            </p> -->
          </div>
          <p style="display:flex;justify-content: space-between;align-item:center;color:#999;padding:0 10px 10px;">
              <span>月销:{{item.month_sales}}</span>
              <span>年销:{{item.year_sales}}</span>
              <span>累计销量:{{item.total_sales}}</span>
          </p>
          <p
            style="
              color: #ff3c31;
              text-align: right;
              font-size: 18px;
              position: absolute;
              bottom: 10px;
              right: 10px;
            "
          >
            ￥{{ item.price_selling }}-{{ item.max_price_selling }}
          </p>
          <!-- <p class="container_lis_title2" v-html="item.remark"></p> -->
        </RouterLink>
      </div>
      <!-- //标签绑定Page -->
      <div class="pagination">
        <XtxPagination
          v-if="total"
          :total="total"
          :current-page="currentPage"
          :page-size="pageSize"
          @current-change="getCurrentPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getAllCategory, getThirdCategory, getGoodsList } from '@/api/FirstPage'
import { useRoute } from 'vue-router'
import { ref, onBeforeUnmount, onMounted, inject, watch } from 'vue'
// import { findBanner } from '@/api/home'
import eventBus from '@/utils/eventBus';
import store from '@/store'
export default {
  name: 'TopCategory',
  props: {
    keywords: {
      type: String,
      default: ''
    },
  },
  components: {
  },
  setup (props) {
    const route = useRoute()
    const AllCategory = ref([])
    const serveId = ref('')
    const ids = ref([])
    const fid = ref([])
    const ishotState = ref(-1)
    const is_balance = ref('')
    const isbalance = ref()
    const keyword = ref('')
    // 评论总条数
    const total = ref()
    // 当前页码
    const currentPage = ref(1)
    //一页几条
    const pageSize = ref(12)
    const goodsList = ref([])
    const getCategory = async () => {
      const newArry = {
        name: '案件类型',
        child: []
      }
      const res = await getAllCategory()
      console.log(res)
      AllCategory.value = res.data
      AllCategory.value.splice(1, 0, newArry)
      console.log(AllCategory.value)
      ThirdCategory()
    }
    const ThirdCategory = async (type) => {
      const res = await getThirdCategory({
        id: route.params.id
      })
      console.log(res)
      AllCategory.value[1].child = res.data
      if (type != 'chose') {
        AllCategory.value.forEach(item => {
          item.child.forEach(content => {
            if (route.params.id == content.id) {
              content.selected = true
            } else {
              content.selected = false
            }
          })
        })
      }
      getgoods()
    }
    const choseCategory = (item, content) => {
      ids.value = []
      fid.value = []
      if (item.name == '服务类型') {
        serveId.value = route.params.id
        ThirdCategory('chose')
      }
      if (!content.selected) {
        // 排他：先清空，再让当前高亮
        item.child.forEach(details => {
          details.selected = false
        })
        content.selected = true
      }
      getgoods()
    }
    const getgoods = async (fdetails, details) => {
      // console.log(fdetails, details)
      ids.value = []
      fid.value = []
      // goodsList.value = []
      total.value = ''
      AllCategory.value.forEach(item => {
        item.child.forEach(content => {
          if (content.selected) {
            ids.value.push(content.id)
            if (content.id == 0) {
              fid.value.push(item.id)
            }
          }
        })
      })
      console.log(ids.value)
      console.log(fid.value)
      console.log(route.params.id)
      if (route.params.id == 'heat') {
        ishotState.value = 1
      } else if (route.params.id == 'common') {
        ishotState.value = 0
      }
      const res = await getGoodsList(
        {
          cid: ids.value,
          fid:fid.value,
          hot_state: ishotState.value,
          is_balance: is_balance.value,
          keyword: keyword.value,
          page: currentPage.value,
          pagesize: pageSize.value
        }
      )
      if (res.code == 0) {
        total.value = res.data.total
        goodsList.value = res.data.list
      }
      console.log(goodsList.value)
    }
    const sonFn = () => {
      console.log('我被父组件里调用了子组件的方法修改了数据')
    };
    const getCurrentPage = val => {
      // console.log('当前子组件的页面为', val)
      currentPage.value = val
      getgoods()
    }
    const change = (e) => {
      console.log(e)
      isbalance.value = e.target.checked
      is_balance.value = e.target.checked ? 1 : ''
      getgoods()
    }
    onMounted(() => {
      eventBus.on('serach', (e) => {
        // inject("reload");
        // console.log(e)
        keyword.value = store.state.user.keywords
        getgoods()
      })
      keyword.value = store.state.user.keywords
      console.log(keyword.value)
      console.log('onMounted')
      getCategory()
    })
    onBeforeUnmount(() => {
      eventBus.off('serach')
    })
    return {
      AllCategory,
      serveId,
      choseCategory,
      ids,
      ishotState,
      sonFn,
      total,
      currentPage,
      pageSize,
      getCurrentPage,
      goodsList,
      is_balance,
      change,
      keyword,
      isbalance,
      fid
    }
  }
}
</script>

<style scoped lang="less">
.top-category {
  width: 100%;
  min-height: 235px;
  .container_header {
    width: 100%;
    height: 235px;
    background: url(~@/assets/images/banner02@2x.png) no-repeat;
    background-size: 100% 100%;
  }
  .xtx-bread {
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    bottom: 12px;
    .xtx-bread_img {
      width: 11px;
      height: 10px;
    }
  }
  .container {
    width: 1228px;
    min-height: 200px;
    margin: 0 auto;
    padding: 52px 0;
    .container_detail {
      width: 100%;
      .container_detail_type {
        display: flex;
        // align-items: center;
        p {
          min-width: 100px;
          margin-top: 4px;
          // flex: 1;
        }
      }
      .container_detail_content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 14px;
        margin-left: 10px;
        .normal {
          color: #17233d;
          margin-right: 32px;
          margin-bottom: 10px;
          &.active {
            padding: 4px 14px;
            background: #296df1;
            border-radius: 50px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.container_list {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  .container_list_content {
    width: 282px;
    min-height: 390px;
    background: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(103, 110, 135, 0.1);
    margin-right: 24px;
    margin-bottom: 24px;
    position: relative;
    .container_list_img {
      width: 100%;
      height: 282px;
    }
    .container_lis_title1 {
      margin: 10px;
      font-size: 14px;
      // font-weight: 700;/
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 36px;
      align-items: flex-start;
    }
    .container_lis_title2 {
      margin: 4px 14px;
      font-size: 13px;
      color: #808695;
    }
  }
}
.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
