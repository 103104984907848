<template>
  <div>
    <!-- 购物车顶部 -->
    <cartHeader title="收银台" />
    <!-- 内容 -->
    <div class="content">
      <div class="content_son">
        <div style="display: flex; justify-content: space-between">
          <p>
            订单提交成功，请尽快付款！<span v-if="order_no"
              >订单号：{{ order_no }}</span
            >
          </p>
          <p>
            <span>应付总额：</span>
            <span style="color: #ff3c31; font-size: 22px">￥{{ price }}</span>
          </p>
        </div>
        <div class="son_details">
          <p style="font-size: 18px; font-weight: 700">微信支付</p>
          <div class="code" v-if="isPay">
            <div
              style="display: flex; flex-direction: column; align-items: center"
            >
              <div class="weixin">
                <div id="qrcode" ref="qrcode"></div>
              </div>
              <div class="tip">
                <img src="@/assets/images/sao.png" alt="" srcset="" />
                <p>使用微信扫码支付</p>
              </div>
            </div>
            <img
              src="@/assets/images/image7@2x.png"
              alt=""
              srcset=""
              style="width: 325px; height: 413px"
            />
          </div>
          <div v-else></div>
        </div>
      </div>
    </div>
    <!-- 公共底部 -->
    <AppFooter />
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import QRCode from 'qrcodejs2'
import cartHeader from './components/cart-header.vue'
import AppFooter from '@/components/app-footer.vue'
import { queryOrderStatus } from '@/api/Orders'
export default {
  components: { cartHeader, AppFooter },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const qrcodeUrl = ref('')
    const order_no = ref('')
    const price = ref('')
    const timer = ref('')
    const isPay = ref(true)
    const qrcodeScan = () => {//生成二维码
      let qrcode = new QRCode('qrcode', {
        width: 268,  // 二维码宽度 
        height: 268, // 二维码高度
        text: qrcodeUrl.value
      })
    }
    const getstatus = async () => {
      const res = await queryOrderStatus({
        order_no: order_no.value
      })
      if (res.code == 0) {
        if (res.data.status == 2) {
          clearInterval(timer.value)
          timer.value = null
          router.go(-1)
        }
        // id.value = res.data.id
      }
    }
    onMounted(() => {
      console.log(JSON.parse(decodeURIComponent(route.query.data)))
      const data = JSON.parse(decodeURIComponent(route.query.data))
      qrcodeUrl.value = data.qrcodeUrl
      order_no.value = data.order_no
      price.value = data.price
      qrcodeScan()
      timer.value = setInterval(() => {
        getstatus()
      }, 2000)
    })
    onBeforeUnmount(() => {
      clearInterval(timer.value)
      timer.value = null
    })
    return {
      qrcodeScan,
      qrcodeUrl,
      price,
      order_no,
      getstatus,
      timer,
      isPay
    }
  }
}
</script>
<style scoped lang="less">
.content {
  width: 100%;
  padding-bottom: 84px;
  .content_son {
    width: 1000px;
    min-height: 539px;
    margin: 0 auto;
    padding-top: 40px;
    .son_details {
      width: 100%;
      height: 670px;
      background: #ffffff;
      margin-top: 20px;
      padding: 40px;
    }
  }
}
.code {
  margin-top: 45px;
  display: flex;
  padding: 0 100px;
  justify-content: space-between;
}
.weixin {
  width: 308px;
  height: 308px;
  border: 1px solid #cccc;
  padding: 20px;
}
.tip {
  width: 300px;
  height: 70px;
  background: #00bc4b;
  margin-top: 9px;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 34px;
    height: 34px;
    margin-right: 20px;
  }
}
</style>