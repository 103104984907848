<template>
  <div class="xtx-checkbox" @click="changeChecked()">
    <i
    class="iconfont "
    :class="modelValue ?  'icon-checked' : 'icon-unchecked'"

    ></i>

    <span v-if="$slots.default"><slot /></span>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'

export default {
  name: 'XtxCheckbox',
  props: {
    // 子组件接受布尔值
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // // 🔔写法1：用 props +  emit 实现
    // const changeChecked = () => {
    //   // 点击后把取反后的布尔值传递给父组件
    //   const temp = !props.modelValue
    //   emit('update:modelValue', temp)
    // }
    // 🔔写法2：用 useVModel 实现 (简写了props +  emit 变成 ref )
    const data = useVModel(props, 'modelValue', emit)
    const changeChecked = () => {
      const temp = !data.value
      data.value = temp
      emit('change', temp)
    }
    return { changeChecked }
  }
}
</script>

<style scoped lang="less">
.xtx-checkbox {
  display: inline-block;
  margin-right: 2px;
  .icon-checked {
    color: @xtxColor;
    ~ span {
      color: @xtxColor;
    }
  }
  i {
    position: relative;
    top: 1px;
  }
  span {
    margin-left: 2px;
  }
}
</style>
