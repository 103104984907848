// createStore 用于创建仓库实例的函数
import { createStore } from 'vuex';
import createPersistedstate from 'vuex-persistedstate';

import user from './modules/user';
import account from './modules/account';
// 创建仓库实例
const store = createStore({
  // 模块
  modules: {
    user,
    account,
  },
  plugins: [
    createPersistedstate({
      key: 'erabbit-client-pc-store-64',
      paths: ['user', 'account'],
    }),
  ],
});

// 导出仓库实例
export default store;
