<template>
  <div class="xtx-pagination">
    <a
      href="javascript:;"
      @click="changeCurrentPage(myCurrentPage - 1)"
      :class="{ disabled: myCurrentPage <= 1 }"
    >
      上一页
    </a>
    <span v-if="pager.start > 1">...</span>
    <a
      v-for="i in pager.btnArr"
      :key="i"
      :class="{ active: i === myCurrentPage }"
      href="javascript:;"
      @click="changeCurrentPage(i)"
    >
      {{ i }}
    </a>
    <span v-if="pager.end < pager.pageCount">...</span>
    <a
      href="javascript:;"
      @click="changeCurrentPage(myCurrentPage + 1)"
      :class="{ disabled: myCurrentPage >= pager.pageCount }"
    >
      下一页
    </a>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
/*
  分页器所需数据
    总条数 props
    当前页码
    页容量
    按钮个数

    分页信息（动态计算）
      总页数
      起始按钮
      结束按钮
      按钮数组（生成哪些编号）
*/
export default {
  name: 'XtxPagination',
  // 如果不是一个根组件的时候，自定义事件需要写到 emits 配置项中
  emits: ['current-change'],
  props: {
    total: {
      type: Number,
      default: 100
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    }
  },
  setup (props, { emit }) {
    // 总条数 - 最终应该是有父组件传递过来
    const myTotal = ref(101) // 测试数据：101  9
    // 页容量
    const myPageSize = ref(10)
    // 当前页码
    const myCurrentPage = ref(1) // 测试数据： 5  1  11
    // 按钮个数
    const btnCount = 5

    // props 是父组件传过来的， my开头都是内部变量，
    watch(
      props,
      () => {
        myTotal.value = props.total
        myPageSize.value = props.pageSize
        myCurrentPage.value = props.currentPage
      },
      { immediate: true }
    )

    // 重点：根据上述数据得到（总页数，起始页码，结束页码，按钮数组）
    const pager = computed(() => {
      // 计算总页数
      const pageCount = Math.ceil(myTotal.value / myPageSize.value)

      // 计算起始按钮 - 要基于<当前页码>计算得到
      let start = myCurrentPage.value - Math.floor(btnCount / 2)
      // 左按钮 边界问题 - 左边最小值为 1
      if (start < 1) start = 1

      // 结束按钮 - 基于<起始按钮>计算得到
      let end = start + btnCount - 1
      // 右按钮 边界问题 - 右边最大值为总页数
      if (end > pageCount) {
        end = pageCount
        // 根据右边界算出来左边界，确保按钮还是 5 个
        start = pageCount - btnCount + 1
        // 同时考虑左按钮计算后的 左按钮 边界问题
        if (start < 1) start = 1
      }

      // 计算出按钮组 - 用于界面渲染
      const btnArr = []
      for (let i = start; i <= end; i++) {
        btnArr.push(i)
      }

      return {
        pageCount,
        start,
        end,
        btnArr
      }
    })

    // 改变页码函数
    const changeCurrentPage = page => {
      // 左右边界越界不处理，如果点击的就是当前页页不处理
      if (
        page < 1 ||
        page > pager.value.pageCount ||
        myCurrentPage.value === page
      ) {
        return
      }
      // 常规情况
      myCurrentPage.value = page
      // 还要把当前页面传递给父组件
      emit('current-change', page)
    }

    return { myCurrentPage, pager, changeCurrentPage }
  }
}
</script>

<style scoped lang="less">
.xtx-pagination {
  display: flex;
  justify-content: center;
  padding: 30px;
  > a {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    margin-right: 10px;
    &:hover {
      color: @xtxColor;
    }
    &.active {
      background: @xtxColor;
      color: #fff;
      border-color: @xtxColor;
      cursor: default;
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
      &:hover {
        color: #333;
      }
    }
  }
  > span {
    margin-right: 10px;
  }
}
</style>
