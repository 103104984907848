<template>
  <div>
    <div class="floatingWindow">
      <div class="popover" v-if="ispopover">
        <div>
          <p id="triangle-right"></p>
          <div>
            <p>服务热线</p>
            <p style="font-weight: 700; font-size: 18px">056-83050108</p>
          </div>
          <div style="margin-top: 10px">
            <p>服务热线</p>
            <p style="font-weight: 700; font-size: 18px">056-83050108</p>
          </div>
        </div>
      </div>
      <!-- <router-link to="/service">
        <div class="floatingWindow_son soild-bottom">
          <img src="@/assets/images/kefu@2x.png" alt="" />
          <p>客服中心</p>
        </div>
      </router-link> -->
      <router-link to="/account/news">
        <div class="floatingWindow_son soild-bottom">
          <img src="@/assets/images/msgicon.png" alt="" />
          <span class="msg-num">{{noReadNum}}</span>
          <p>消息</p>
        </div>
      </router-link>
      <router-link to="/account/my-order">
        <div class="floatingWindow_son soild-bottom">
          <img src="@/assets/images/ordericon.png" alt="" />
          <p>我的订单</p>
        </div>
      </router-link>
      <router-link to="/order/cart">
        <div class="floatingWindow_son soild-bottom">
          <img src="@/assets/images/caricon.png" alt="" />
          <p>购物车</p>
        </div>
      </router-link>
      <router-link to="/cost/agency-fare">
        <div class="floatingWindow_son soild-bottom">
          <img src="@/assets/images/agency.png" alt="" />
          <p>代理费速算</p>
        </div>
      </router-link>
      <a @click="showModal" href="javascript:;">
        <div class="floatingWindow_son soild-bottom">
          <img src="@/assets/images/agency.png" alt="" />
          <p class="navigation_title">计费说明</p>
        </div>
      </a>
      <router-link to="/helpCenter">
        <div class="floatingWindow_son">
          <img src="@/assets/images/help.png" alt="" />
          <p>帮助中心</p>
        </div>
      </router-link>
      <a
        href="https://wpa1.qq.com/HTa3AVfP?_type=wpa&qidian=true"
        target="_blank"
      >
        <!-- <div class="floatingWindow_son soild-bottom">
          <img src="@/assets/images/kefu@2x.png" alt="" />
          <p>点我咨询</p>
        </div> -->
      </a>
    </div>

    <a-modal
      v-model:visible="visible"
      width="800px"
      title="计费说明"
      cancelText="取消"
      okText="知道了"
      @ok="hideModal"
    >
      <div style="color: #808695; font-size: 16px; margin-top: 20px">
        <p style="margin-top: 20px; text-indent: 2em">
          涉案标的额≦10000元时，计费下限值为2500元，计费上限值为10000元；
        </p>
        <p style="margin-top: 20px; text-indent: 2em">
          涉案标的额＞1万元≦10万元，计费比例为6%-7%。涉案标的额减1万元得出计费标的额，计费标的额×6%=计费下限值，计费标的额×7%=计费上限值；
        </p>
        <p style="margin-top: 20px; text-indent: 2em">
          涉案标的额＞10万元≦50万元，计费比例为5%-6%。涉案标的额减10万元得出计费标的额，计费标的额×5%=计费下限值，计费标的额×6%=计费上限值；
        </p>
        <p style="margin-top: 20px; text-indent: 2em">
          涉案标的额＞50万元≦100万元，计费比例为4%-5%，涉案标的额减50万元得出计费标的额，计费标的额×4%=计费下限值，计费标的额×5%=计费上限值；
        </p>
        <p style="margin-top: 20px; text-indent: 2em">
          涉案标的额＞100万元≦500万元，计费比例为3%-4%，涉案标的额减100万元得出计费标的额，计费标的额×3%=计费下限值，计费标的额×4%=计费上限值；
        </p>
        <p style="margin-top: 20px; text-indent: 2em">
          涉案标的额＞500万元≦1000万元，计费比例为2%-3%，涉案标的额减500万元得出计费标的额，计费标的额×2%=计费下限值，计费标的额×3%=计费上限值；
        </p>
        <p style="margin-top: 20px; text-indent: 2em">
          涉案标的额＞1000万元≦1亿万元，计费比例为1%-2%，涉案标的额减1000万元得出计费标的额，计费标的额×1%=计费下限值，计费标的额×2%=计费上限值；
        </p>
        <p style="margin-top: 20px; text-indent: 2em">
          涉案标的额＞1亿万元，计费比例为0.5%-1%，涉案标的额减1亿万元得出计费标的额，计费标的额×0.5%=计费下限值，计费标的额×1%=计费上限值；
        </p>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { noReadMsg } from '@/api/user'
export default {
  components: {},
  setup() {
    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };
    const hideModal = () => {
      visible.value = false;
    };
    const noReadNum=ref(0);
    const getNoReadMsg = async () => {
      const res = await noReadMsg()
      if (res.code == 0) {
        noReadNum.value=res.data.no_read_count
      }
    };
    onMounted(() => {
        getNoReadMsg()
    })
    return {
      visible,
      showModal,
      hideModal,
      noReadNum,
      getNoReadMsg
    };
  },
};
</script>
<style scoped lang="less">
.floatingWindow {
  width: 98px;
  min-height: 214px;
  background: #ffffff;
  position: fixed;
  right: 20px;
  top: 413px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  z-index: 1000;
  box-shadow: 2px 2px 2px 2px #d1cfcf;
  img {
    width: 16px;
    height: 16px;
  }
  p {
    font-size: 12px;
    color: #808695;
    line-height: 24px;
  }
  .floatingWindow_son {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .msg-num{
      width: 16px;
      height: 16px;
      background: #ff3c31;
      border-radius: 50%;
      font-size: 7px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      right: -4px;
      top: -2px;
    }
  }
  .soild-bottom {
    border-bottom: 1px solid #f1f1f4;
  }
  .popover {
    width: 175px;
    height: 155px;
    z-index: 100;
    position: absolute;
    top: 0;
    left: -195px;
    background: #ffffff;
    padding: 15px;
  }
  #triangle-right {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-left: 8px solid #ffffff;
    border-bottom: 4px solid transparent;
    position: absolute;
    right: -8px;
    top: 30px;
  }
}
</style>
