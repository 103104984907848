/* eslint-disable no-undef */
<template>
  <LoginHeader></LoginHeader>
  <div class="container">
    <div class="gaosi-layout-content">
      <XtxBread class="xtx-bread">
        <XtxBreadItem to="/Login" class="xtx-bread-item" norColor="#808695">
          我的位置 ：登录页
        </XtxBreadItem>
        <transition name="fade-right" mode="out-in"> 找回密码 </transition>
      </XtxBread>
      <div class="password-panel">
        <div style="padding-top: 48px">
          <div class="step">
            <a-steps class="steps" :current="currentTab" size="small">
              <a-step title="身份验证" />
              <a-step title="设置新密码" />
              <a-step title="找回成功" />
            </a-steps>
          </div>
          <div class="content">
            <usermsg v-if="currentTab == 0" @nextStep="nextStep" />
            <newpwd
              v-if="currentTab == 1"
              @nextStep="nextStep"
              @prevStep="prevStep"
            />
            <done
              v-if="currentTab == 2"
              @prevStep="prevStep"
              @finish="finish"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter />
</template>

<script>
import { ref, reactive } from 'vue'
import LoginHeader from './components/login-header.vue'
import AppFooter from '@/components/app-footer.vue'
import Usermsg from './components/Usermsg.vue'
import newpwd from './components/Newpwd.vue'
import done from './components/Done.vue'
import { forgetPwd } from '@/api/user'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'PageCallback',
  components: { LoginHeader, AppFooter, Usermsg, newpwd, done },
  setup () {
    const store = useStore()
    const router = useRouter()
    const currentTab = ref(0)
    const form = reactive({
      phone: '',
      code: '',
      new_pwd: '',
      new_repwd: ''
    })
    const nextStep = (data) => {
      console.log(data)
      form.phone = data.phone
      form.code = data.code
      if (currentTab.value < 2) {
        currentTab.value += 1
      }
    }
    const prevStep = async (data) => {
      console.log(data)
      form.new_pwd = data.new_pwd
      form.new_repwd = data.new_repwd
      console.log(currentTab.value)
      const res = await forgetPwd(form)
      console.log(res)
      if (res.code == 0) {
        if (currentTab.value < 2) {
          currentTab.value += 1
        }
      }
    }
    const finish = () => {
      router.push('/Login')
    }
    return { currentTab, nextStep, prevStep, form, finish }
  }
}
</script>

<style scoped lang="less">
.loading {
  margin: 25px auto;
  width: 1240px;
  height: 730px;
  // background: #fff url(~@/assets/images/load.gif) no-repeat center / 100px 100px;
}
.container {
  width: 100%;
  min-height: 600px;
  .gaosi-layout-content {
    width: 1000px;
    margin: 0 auto;
    min-height: 600px;
    padding-top: 10px;
    .xtx-bread {
      color: #808695;
    }
  }
}

.password-panel {
  width: 100%;
  margin-top: 10px;
  min-height: 600px;
  background: #fff;
  position: relative;
  margin-bottom: 232px;
  .title {
    // margin-left: 120px;
    position: absolute;
    left: 0;
    top: -20px;
    font-size: 12px;
    color: #808695;
  }
  .step {
    height: 103px;
    width: 100%;
  }
  .steps {
    width: 460px;
    margin: 0 auto;
    padding-top: 20px;
  }
  .content {
    min-height: 460px;
    width: 400px;
    margin: 0 auto;
    // background: yellow;
  }
}
</style>
