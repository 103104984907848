<template>
  <div class="about">
    <div class="content">
      <!-- 左侧栏 -->
      <div class="content_list">
        <p
          class="content_list_details"
          :class="{ active: activeStatus === 'us' }"
          @click="changePath('us')"
        >
          <span>关于我们</span>
        </p>
        <p
          class="content_list_details"
          :class="{ active: activeStatus === 'platform' }"
          @click="changePath('platform')"
        >
          <span>平台介绍</span>
        </p>
        <p
          class="content_list_details"
          :class="{ active: activeStatus === 'opinion' }"
          @click="changePath('opinion')"
        >
          <span>意见反馈</span>
        </p>
        <p
          class="content_list_details"
          :class="{ active: activeStatus === 'aftersale' }"
          @click="changePath('aftersale')"
        >
          <span>申请售后</span>
        </p>
        <p
          class="content_list_details"
          :class="{ active: activeStatus === 'complaint' }"
          @click="changePath('complaint')"
        >
          <span>投诉通道</span>
        </p>
      </div>
      <!-- 右侧内容 -->
      <div class="content_details">
        <div v-if="activeStatus === 'aftersale'" v-html="aftersale"></div>
        <div v-if="activeStatus === 'us'" v-html="aboutus"></div>
        <div v-if="activeStatus === 'platform'" v-html="platform"></div>
        <div v-if="activeStatus === 'opinion'">
          <p class="content_details_p1">意见反馈</p>
          <p class="content_details_p2">
            您的意见是我们不断进步的动力，请留下您在使用中遇到的问题或提出宝贵的建议。
          </p>
        </div>
        <div v-if="activeStatus === 'complaint'">
          <p class="content_details_p1">投诉通道</p>
          <p class="content_details_p2">
            您的意见是我们不断进步的动力，请留下您在使用中遇到的问题或提出宝贵的建议。
          </p>
        </div>
        <a-row
          class="textarea"
          v-if="activeStatus === 'complaint' || activeStatus === 'opinion'"
        >
          <a-col :span="3">
            <p><span>问题描述：</span><span style="color: #ff3c31">*</span></p>
          </a-col>
          <a-col :span="18">
            <a-textarea
              style="width: 449px; height: 212px"
              v-model:value="content"
              placeholder=""
              :rows="4"
            />
          </a-col>
        </a-row>
        <a-row class="textarea" v-if="activeStatus === 'complaint'">
          <a-col :span="3">
            <p><span>附件上传：</span></p>
          </a-col>
          <a-col :span="18">
            <a-upload
              name="file"
              :customRequest="customRequest"
              :multiple="false"
              :showUploadList="false"
            >
              <a-button type="primary" style="margin-right: 10px"
                >上传文件</a-button
              >
            </a-upload>
            <span>{{file}}</span>
          </a-col>
        </a-row>
        <a-row
          class="inp"
          v-if="activeStatus === 'complaint' || activeStatus === 'opinion'"
        >
          <a-col :span="3">
            <p><span>联系方式：</span><span style="color: #ff3c31">*</span></p>
          </a-col>
          <a-col :span="18">
            <a-input
              style="width: 449px; height: 34px; border: 1px solid #d9d9d9"
              v-model:value="mobile"
            />
          </a-col>
        </a-row>
        <a-row
          class="inp"
          v-if="activeStatus === 'complaint' || activeStatus === 'opinion'"
        >
          <a-col :span="3"> </a-col>
          <a-col :span="18">
            <p style="font-size: 12px; color: #808695; margin-top: 7px">
              *请您留下联系方式，以便我们对您的问题进行回复、追踪。
            </p>
          </a-col>
        </a-row>
        <a-row
          style="margin-top: 33px"
          v-if="activeStatus === 'complaint' || activeStatus === 'opinion'"
        >
          <a-col :span="3"> </a-col>
          <a-col :span="18">
            <a-button type="primary" @click="submit">立即提交</a-button>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { getIntroduce, getAboutUs, getservice, getComplain, getFeedback } from '@/api/FirstPage'
import { uphead } from '@/api/user'
import Message from '@/components/library/Message'
export default {
  setup () {
    const route = useRoute()
    const value = ref('')
    const activeStatus = ref('')
    const platform = ref('')
    const aboutus = ref('')
    const aftersale = ref('')
    const content = ref('')
    const mobile = ref('')
    const attach = ref('')
    const file = ref('')
    const pressEnter = () => {
      console.log(value.value)
    }
    const changePath = (path) => {
      activeStatus.value = path
      content.value = ''
      mobile.value = ''
    }
    watch(() => route.params.son, (son) => {
      console.log(son)
      console.log('监听到变化', route.path)
      if (route.path !== `/about/${son}`) return
      activeStatus.value = son
    },
      { immediate: true })
    const getContent = async () => {
      const res = await getIntroduce()
      platform.value = res.data
    }
    const getUs = async () => {
      const res = await getAboutUs()
      aboutus.value = res.data
    }
    const getService = async () => {
      const res = await getservice()
      aftersale.value = res.data
    }
    const Complain = async () => {
      const res = await getComplain({
        content: content.value,
        mobile: mobile.value,
        attach: attach.value
      })
      if (res.code == 0) {
        Message({ type: 'success', message: '提交成功' })
        content.value = ''
        mobile.value = ''
      }
    }
    const Feedback = async () => {
      const res = await getFeedback({
        content: content.value,
        mobile: mobile.value
      })
      if (res.code == 0) {
        Message({ type: 'success', message: '提交成功' })
        content.value = ''
        mobile.value = ''
      }
    }
    const customRequest = async (data) => {
      const formData = new FormData()
      formData.append('file', data.file)
      file.value = data.file.name
      // formData.append('token', storage.get(ACCESS_TOKEN))
      console.log(data.file.name)
      console.log(formData.get('file'))
      // this.formData = formData
      const res = await uphead(formData)
      attach.value = res.url
    }
    const submit = () => {
      if (activeStatus.value == 'complaint') {
        Complain()
      } else {
        Feedback()
      }
    }
    onMounted(() => {
      getContent()
      getUs()
      getService()
    })
    return {
      value,
      pressEnter,
      activeStatus,
      changePath,
      getContent,
      platform,
      aboutus,
      aftersale,
      content,
      mobile,
      customRequest,
      attach,
      submit,
      Feedback,
      file
    }
  }
}
</script>
<style scoped lang="less">
.about {
  width: 100%;
  padding-top: 30px;
  .content {
    width: 1116px;
    min-height: 847px;
    margin: 0 auto;
    display: flex;
    .content_list {
      width: 248px;
      height: 360px;
      background: #ffffff;
      padding: 40px 12px 0 12px;
      display: flex;
      flex-direction: column;
      .content_list_details {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        color: #17233d;
        span {
          font-size: 18px;
          display: block;
        }
        &.active {
          height: 44px;
          background: #296df1;
          color: #ffffff;
        }
      }
    }
    .content_details {
      margin-left: 20px;
      width: 848px;
      min-height: 800px;
      background: #ffffff;
      padding: 60px;
      .content_details_p1 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 12px;
      }
      .content_details_p2 {
        font-size: 14px;
        color: #808695;
        margin-bottom: 54px;
      }
      .textarea {
        display: flex;
        margin-bottom: 20px;
      }
      .inp {
        display: flex;
      }
    }
  }
}
</style>