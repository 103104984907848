const accountList = [
  {
    icon: require('@/assets/images/Frame@2x(8).png'),
    name: '订单中心',
    child: [
      {
        name: '我的订单',
        path: '/account/my-order',
        tag: 'children',
        actived: false,
      },
      {
        name: '我的评价',
        path: '/account/my-evaluate',
        tag: 'children',
        actived: false,
      },
    ],
  },
  {
    icon: require('@/assets/images/Frame@2x(9).png'),
    name: '资产中心',
    child: [
      {
        name: '我的余额',
        path: '/account/my-wmbeans',
        tag: 'children',
        actived: false,
      },
    ],
  },
  {
    icon: require('@/assets/images/Frame@2x(10).png'),
    name: '收藏夹',
    child: [
      {
        name: '收藏的产品',
        path: '/account/collect',
        tag: 'children',
        actived: false,
      },
    ],
  },
  {
    icon: require('@/assets/images/Frame@2x(11).png'),
    name: '账户管理',
    child: [
      {
        name: '个人信息',
        path: '/account/userinfo',
        tag: 'children',
        actived: false,
      },
      {
        name: '登录密码',
        path: '/account/restpwd',
        tag: 'children',
        actived: false,
      },
      {
        name: '支付密码',
        path: '/account/paypwd',
        tag: 'children',
        actived: false,
      },
      {
        name: '绑定账号',
        path: '/account/bindaccount',
        tag: 'children',
        actived: false,
      },
      {
        name: '收货地址',
        path: '/account/address',
        tag: 'children',
        actived: false,
      },
      {
        name: '发票抬头',
        path: '/account/invoice',
        tag: 'children',
        actived: false,
      },
    ],
  },
  {
    icon: require('@/assets/images/Frame@2x(12).png'),
    name: '消息管理',
    child: [
      {
        name: '系统通知',
        path: '/account/news',
        tag: 'children',
        actived: false,
      },
      {
        name: '投诉处理',
        path: '/account/complaint',
        tag: 'children',
        actived: false,
      },
    ],
  },
];
export default accountList;
