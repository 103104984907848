<template>
  <div style="display: flex">
    <div class="content">
      <div class="content_header">
        <div class="content_header_top">
          <div class="content_header_top_son1">
            <img
              :src="
                users.headimg ? users.headimg : '@/assets/images/avatar.png'
              "
              alt=""
              class="content_header_top_img"
            />
            <div>
              <p>
                <span class="content_header_text1">{{
                  users.nickname ? users.nickname : users.account
                }}</span>
                <span class="content_header_text2">欢迎登录！</span>
              </p>
              <router-link to="/account/userinfo">
                <p class="content_header_text3" style="margin-top: 8px">
                  编辑资料
                </p>
              </router-link>
            </div>
          </div>
          <div class="content_header_top_son2"></div>
          <div class="content_header_top_son3">
            <p class="content_header_text1">我的余额</p>
            <p style="display: flex; align-items: center; margin-top: 8px">
              <span class="content_header_text2"
                >万谋豆：{{ users.balance_rest }}</span
              >
              <router-link to="/account/wmbeans-chong">
                <span class="content_header_text3" style="margin-left: 52px"
                  >立即充值</span
                >
              </router-link>
            </p>
          </div>
        </div>
        <div class="content_header_foot">
          <div
            v-for="(item, index) in navList"
            :key="index"
            class="foot_content"
            @click="gotoList(item)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="content_middle">
        <div class="content_middle_header">近期订单</div>
        <div class="content_middle_details" v-for="(item, index) in orderlist" :key="index">
          <div style="display: flex">
            <img src="" alt="" class="middle_details_img" />
            <p class="middle_details">
              <span>合同纠纷起诉服务</span>
              <span class="middle_details_title2">服务1</span>
            </p>
          </div>
          <p class="middle_details">
            <span>￥599.00</span>
            <span class="middle_details_title2">在线付款</span>
          </p>
          <p class="middle_details">
            <span class="middle_details_title2">2021-09-02</span>
            <span class="middle_details_title2">16:51:30</span>
          </p>
          <p class="middle_details">
            <span style="color: #296df1">等待付款</span>
            <span class="middle_details_title2">剩余20小时15分</span>
          </p>
          <p class="middle_details">
            <span class="middle_details_title1">等待付款</span>
            <span class="middle_details_title2">查看详情</span>
          </p>
        </div>
        <XtxPagination
          v-if="total"
          :total="total"
          :current-page="currentPage"
          :page-size="pageSize"
          @current-change="getCurrentPage"
        />
      </div>
      <div class="content_foot">
        <p>我的购物车</p>
        <div class="content_foot_details">
          <img
            src="@/assets/images/akar-icons_chevron-left@2x.png"
            alt=""
            srcset=""
            @click="curIdx = 0"
            :class="{ disabled: curIdx === 0 }"
          />
          <ul class="content_foot_list" :style="tranX">
            <li :class="{ fade: activeIndex === index }">
              <img src="" alt="" class="" />
              <p
                style="
                  display: flex;
                  flex-direction: column;
                  margin-top: 7px;
                  text-align: center;
                "
              >
                <span>编制商务函件</span>
                <span>￥299.00</span>
              </p>
            </li>
            <li>
              <img src="" alt="" class="" />
              <p
                style="
                  display: flex;
                  flex-direction: column;
                  margin-top: 7px;
                  text-align: center;
                "
              >
                <span>编制商务函件</span>
                <span>￥299.00</span>
              </p>
            </li>
            <li>
              <img src="" alt="" class="" />
              <p
                style="
                  display: flex;
                  flex-direction: column;
                  margin-top: 7px;
                  text-align: center;
                "
              >
                <span>编制商务函件</span>
                <span>￥299.00</span>
              </p>
            </li>
            <li>
              <img src="" alt="" class="" />
              <p
                style="
                  display: flex;
                  flex-direction: column;
                  margin-top: 7px;
                  text-align: center;
                "
              >
                <span>编制商务函件</span>
                <span>￥299.00</span>
              </p>
            </li>
          </ul>
          <img
            src="@/assets/images/akar-icons_chevron-left@2x(1).png"
            alt=""
            srcset=""
            @click="curIdx = 1"
            :class="{ disabled: curIdx === 1 }"
          />
        </div>
      </div>
    </div>
    <div class="content_right">
      <p style="font-size: 16px">我的收藏</p>
      <div class="content_right_list">
        <img src="" alt="" srcset="" />
        <p>
          <span>编制商务函件</span>
          <span style="color: #ff3c31">￥299.00</span>
        </p>
      </div>
      <div class="more">查看全部></div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { orderList } from '@/api/Orders'
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'AppTopnav',
  setup () {
    const router = useRouter()
    const navList = ref([
      {
        status: 1,
        name: '待付款',
        number: ''
      },
      {
        status: 2,
        name: '待发货',
        number: ''
      },
      {
        status: 3,
        name: '已发货',
        number: ''
      },
      {
        status: 4,
        name: '待评价',
        number: ''
      }
    ])
    const users = ref('')
    const orderlist = ref([])
    const store = useStore()
    // 评论总条数
    const total = ref(10)
    // 当前页码
    const currentPage = ref(1)
    //一页几条
    const pageSize = ref(4)
    const getCurrentPage = val => {
      // console.log('当前子组件的页面为', val)
      currentPage.value = val
      // getList()
    }
    const curIdx = ref(0)
    // 为什么想到再去定义一个变量呢,有个禁用,肯定要有个值作比较
    const tranX = computed(() => {
      const t = -1240 * curIdx.value  // 1240是我定义的版心宽度，具体看你版心定义的多少了
      return `transform:translateX(${t}px)`
    })
    const getUserinfo = () => {
      console.log(store.state.user.userinfo)
      users.value = store.state.user.userinfo
    }
    const gotoList = (item) => {
      router.push({ path: '/account/my-order', query: { status: item.status } })
    }
    const getList = async () => {
      const res = await orderList({
        page: currentPage.value,
        pagesize: pageSize.value,
        status: 0
      })
      orderlist.value = res.data.list
    }
    onMounted(() => {
      getUserinfo()
      getList()
    })
    return {
      total,
      currentPage,
      pageSize,
      getCurrentPage,
      curIdx,
      tranX,
      getUserinfo,
      users,
      navList,
      gotoList
    }
  }
}
</script>

<style scoped lang="less">
.content {
  width: 680px;
  min-height: 120px;
  .content_header {
    width: 100%;
    height: 166px;
    background: #ffffff;
    .content_header_top {
      width: 100%;
      height: 120px;
      border-bottom: 1px solid #f1f1f4;
      padding: 28px 34px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .content_header_top_son1 {
        display: flex;
        font-size: 14px;
        align-items: center;
      }
      .content_header_top_img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        margin-right: 28px;
      }
      .content_header_text1 {
        font-weight: 500;
        margin-right: 13px;
      }
      .content_header_text2 {
        color: #808695;
      }
      .content_header_text3 {
        width: 74px;
        height: 24px;
        border: 1px solid #296df1;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #296df1;
      }
      .content_header_top_son2 {
        width: 2px;
        height: 55px;
        border: 1px solid #f1f1f4;
        margin: 0 52px;
      }
    }
  }
  .content_header_foot {
    display: flex;
    justify-content: space-around;
    padding: 11px;
    .foot_content {
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: center;
      border-right: 1px solid #f1f1f4;
    }
    .foot_content:last-child {
      border: none;
    }
    span {
      font-size: 14px;
      color: #17233d;
    }
    // .line {
    //   width: 1px;
    //   height: 16px;
    //   border: 1px solid #f1f1f4;
    // }
  }
}
.content_middle {
  width: 100%;
  min-height: 500px;
  padding: 30px;
  background: #ffffff;
  margin-top: 20px;
  .content_middle_header {
    width: 100%;
    padding-bottom: 16px;
    font-size: 16px;
    border-bottom: 1px solid #f1f1f4;
  }
  .content_middle_details {
    width: 100%;
    height: 84px;
    border-bottom: 1px solid #f1f1f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .middle_details_img {
      width: 60px;
      height: 45px;
      border: 1px solid salmon;
      margin-right: 10px;
    }
    .middle_details {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .middle_details_title1 {
      width: 72px;
      height: 26px;
      background: #ff3c31;
      border-radius: 2px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .middle_details_title2 {
      color: #808695;
    }
  }
}
.content_foot {
  width: 100%;
  height: 240px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 67px;
  padding: 30px 18px;

  .content_foot_details {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  img {
    width: 24px;
    height: 24px;
  }
  .content_foot_list {
    width: 580px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 136px;
      height: 96px;
      border: 1px solid red;
    }
    ul li {
      width: 136px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
.content_right {
  width: 240px;
  height: 604px;
  background: #ffffff;
  margin-left: 20px;
  padding: 30px;
  position: relative;
  .content_right_list {
    display: flex;
    margin-top: 20px;
    font-size: 14px;
    img {
      width: 66px;
      height: 54px;
      border: 1px solid red;
      margin-right: 10px;
    }
    p {
      display: flex;
      flex-direction: column;
      line-height: 24px;
    }
  }
  .more {
    width: 88px;
    height: 24px;
    border: 1px solid #cccccc;
    border-radius: 2px;
    font-size: 12px;
    color: #808695;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 45px;
    left: 76px;
  }
}
</style>
