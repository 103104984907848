<template>
  <div class="content" ref="element">
    <div class="content-header">
      <p
        class="content-header_list"
        v-for="(item, index) in navList"
        :key="index"
        :class="{ active: tabindex == index }"
        @click="changeTab(item, index)"
      >
        {{ item.name }}
        <span style="color: #296df1; margin-left: 5px">{{ item.number }}</span>
      </p>
    </div>
    <a-input-search
      v-model:value="order_no"
      placeholder="请输入订单号查询"
      style="width: 263px; margin: 20px 0"
      @search="onSearch"
    />
    <div class="list_header">
      <div class="list_content1" style="justify-content: center">产品名称</div>
      <div class="list_content2">单价(元)</div>
      <div class="list_content2">数量</div>
      <div class="list_content2">实付金额(元)</div>
      <div class="list_content2">操作</div>
    </div>
    <div class="list_content" v-for="(item, index) in orderlist" :key="index">
      <div class="list_content_header">
        <div>
          <span style="margin-right: 40px">下单时间：{{ item.create_at }}</span>
          <span>订单号：{{ item.order_no }}</span>
        </div>
        <div>
          {{
            item.status == 1
              ? "待付款"
              : item.status == 2
              ? "待发货"
              : item.status == 3
              ? "待确认"
              : item.status == 4
              ? "待评价"
              : item.status == 5
              ? "已完成"
              : "已取消"
          }}
        </div>
      </div>
      <div style="border: 1px solid #f1f1f4; border-top: none; width: 100%">
        <div
          class="list_content_details"
          v-for="(content, cindex) in item.good_list"
          :key="cindex"
        >
          <div class="list_content1">
            <div style="display: flex">
              <img
                :src="content.goods_cover"
                alt=""
                srcset=""
                class="details_img"
              />
              <div>
                <p>{{ content.goods_name }}</p>
                <span>{{ content.goods_spec }}</span>
                <span class="tag" v-if="item.hesitate_hours > 0">
                  <img
                    src="@/assets/images/Frame@2x(13).png"
                    alt=""
                    srcset=""
                  />
                  <text>{{ item.hesitate_hours }}小时犹豫期</text>
                </span>
              </div>
            </div>
          </div>
          <div class="list_content2" style="font-size: 14px">
            {{ content.price_selling }}
          </div>
          <div class="list_content2" style="font-size: 14px">
            {{ content.stock_sales }}
          </div>
          <div class="list_content2" style="font-size: 14px">
            {{ content.actual_price }}
          </div>
          <div class="list_content2">
            <p
              class="btn"
              :class="{
                color2:
                  ((item.status == 2 || item.status == 3) &&
                    content.is_refund == 0) ||
                  ((item.status == 2 || item.status == 3) &&
                    content.is_refund != 0),
                color3: item.status == 4,
              }"
              @click="Refund(item, content)"
            >
              {{
                (item.status == 2 || item.status == 3) && content.is_refund == 0
                  ? "申请退款"
                  : item.status == 4
                  ? "立即评价"
                  : (item.status == 2 || item.status == 3) &&
                    content.is_refund != 0
                  ? "查看退款"
                  : ""
              }}
            </p>

            <!-- <p
              style="margin: 5px"
              v-if="item.status == 3"
              @click="refund(item, content)"
            >
              申请退款
            </p> -->
          </div>
        </div>
      </div>
      <!-- 退款 售后的时候  先不查看订单 -->
      <div class="list_content_footer" v-if="status != 5">
        <p>
          <span>合计：</span
          ><span
            style="font-weight: 700; font-size: 16px; margin-right: 10px"
            >{{ item.payment_amount }}</span
          >
        </p>
        <p class="btn1 color2" @click="cancle(item)" v-if="item.status == 1">
          取消订单
        </p>
        <a-popconfirm
          title="是否有异议"
          ok-text="是"
          cancel-text="否"
          @confirm="confirm"
          @cancel="cancel(item)"
          v-if="item.status == 3 && item.sure_by_plat == 1"
        >
          <p class="btn1 color1">确认收货</p>
        </a-popconfirm>
        <p
          class="btn1 color1"
          v-if="item.status == 3 && item.sure_by_plat == 0"
          @click="cancel(item)"
        >
          确认收货
        </p>
        <p class="btn1 color1" @click="sure(item)" v-if="item.status == 1">
          立即付款
        </p>
        <router-link :to="`/account/order-details/${item.id}`">
          <p class="btn1 color2">查看订单</p>
        </router-link>
      </div>
    </div>
    <XtxPagination
      v-if="total"
      :total="total"
      :current-page="currentPage"
      :page-size="pageSize"
      @current-change="getCurrentPage"
    />
  </div>
  <a-modal
    v-model:visible="visible"
    width="400px"
    title="确认支付"
    cancelText="取消"
    okText="确认"
    @ok="handleOk"
    :maskStyle="{ height: elheight }"
  >
    <a-row>
      <a-col :span="6">支付金额：</a-col>
      <a-col :span="18">{{ price }}</a-col>
    </a-row>
    <a-row style="margin-top: 10px">
      <a-col :span="6">支付方式：</a-col>
      <a-col :span="18">
        <a-radio-group
          name="radioGroup"
          v-model:value="pay_way"
          @change="change"
        >
          <a-radio :value="1">支付宝</a-radio>
          <a-radio :value="2">微信</a-radio>
          <a-radio :value="3">余额</a-radio>
        </a-radio-group>
      </a-col>
    </a-row>
  </a-modal>
  <a-modal
    v-model:visible="isbalance"
    width="250px"
    title="请输入支付密码"
    cancelText="取消"
    okText="确认"
    centered
    @ok="balancePay"
    :maskStyle="{ height: elheight }"
  >
    <a-input
      v-model:value="pay_pwd"
      placeholder="请输入"
      style="width: 200px; height: 35px; border: 1px solid #d9d9d9"
    />
  </a-modal>
</template>
<script>
import {
  refundOrderList,
  orderList,
  cancelOrder,
  orderSure,
  is_hesitate,
  refund,
  Pay,
} from "@/api/Orders";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import Message from "@/components/library/Message";
export default {
  components: {},
  setup() {
    const navList = ref([
      {
        status: 0,
        name: "全部",
        number: "",
      },
      {
        status: 1,
        name: "待付款",
        number: "",
      },
      {
        status: 2,
        name: "待发货",
        number: "",
      },
      {
        status: 3,
        name: "已发货",
        number: "",
      },
      {
        status: 4,
        name: "待评价",
        number: "",
      },
      {
        status: 5,
        name: "退款/售后",
        number: "",
      },
    ]);
    const route = useRoute();
    const router = useRouter();
    const tabindex = ref(0);
    // 评论总条数
    const total = ref(10);
    // 当前页码
    const currentPage = ref(1);
    //一页几条
    const pageSize = ref(4);
    const order_no = ref("");
    const status = ref("");
    const orderlist = ref([]);
    const visible = ref(false);
    const price = ref("");
    const pay_way = ref(1);
    const order_num = ref("");
    const qrcodeUrl = ref("");
    const element = ref(null);
    const elheight = ref("");
    const pay_pwd = ref("");
    const isbalance = ref(false);
    const getCurrentPage = (val) => {
      // console.log('当前子组件的页面为', val)
      currentPage.value = val;
      if (status.value == 5) {
        // 退款
        getReList();
      } else {
        getList();
      }
    };
    const changeTab = (item, index) => {
      tabindex.value = index;
      status.value = item.status;
      if (status.value == 5) {
        // 退款
        getReList();
      } else {
        getList();
      }
    };
    const getReList = async () => {
      const res = await refundOrderList({
        order_no: order_no.value,
        page: currentPage.value,
        pagesize: pageSize.value,
        status: status.value,
      });
      if (!res.data.list?.length) {
        orderlist.value = [];
        return;
      }
      let newArr = res.data.list;
      newArr = res.data.list.map((item) => ({
        ...item,
        good_list: [
          {
            goods_name: item.goods_name,
            goods_spec: item.goods_spec,
            goods_cover: item.goods_cover,
            stock_sales: item.stock_sales,
            actual_price: item.actual_price,
            price_selling: item.actual_price,
          },
        ],
      }));
      orderlist.value = newArr;
      total.value = res.data.total;
      console.log("---getRelist", orderlist.value);

      // navList.value[1].number = res.data.readyToPay;
      // navList.value[2].number = res.data.readyToShip;
      // navList.value[3].number = res.data.finishToShip;
      // navList.value[4].number = res.data.readyToComment;
      // navList.value[5].number = res.data.readyToComment;
    };
    const onSearch = () => {
      if (status.value == 5) {
        // 退款
        getReList();
      } else {
        getList();
      }
    };
    const getList = async () => {
      const res = await orderList({
        page: currentPage.value,
        pagesize: pageSize.value,
        order_no: order_no.value,
        status: status.value,
      });
      orderlist.value = res.data.list;
      total.value = res.data.total;
      navList.value[1].number = res.data.readyToPay;
      navList.value[2].number = res.data.readyToShip;
      navList.value[3].number = res.data.finishToShip;
      navList.value[4].number = res.data.readyToComment;
    };
    const cancle = async (item) => {
      const res = await cancelOrder({
        id: item.id,
      });
      if (res.code == 0) {
        getList();
      }
    };
    const change = (e) => {
      console.log(e);
      pay_way.value = e.target.value;
    };
    const sure = async (item) => {
      if (item.status == 3) {
        // const res = await orderSure({ id: item.id })
        // if (res.code == 0) {
        //   getList()
        // }
      } else {
        //支付
        // window.fullHeight = document.documentElement.clientHeigh
        let number = window
          .getComputedStyle(element.value)
          .height.replace("px", "");
        elheight.value = Number(number) + 700 + "px";
        console.log(elheight.value);
        visible.value = true;
        price.value = item.payment_amount;
        order_num.value = item.order_no;
      }
    };
    const cancel = async (item) => {
      const res = await orderSure({ id: item.id });
      if (res.code == 0) {
        getList();
      }
    };
    // const confirm = ()=>{

    // }
    const Refund = async (item, content) => {
      if (item.status == 2 || item.status == 3) {
        if (content.is_refund == 0) {
          const res = await is_hesitate({
            order_no: item.order_no,
          });
          if (res.code == 0) {
            if (res.is_hesitate == 1) {
              const result = await refund({
                order_no: item.order_no,
                item_id: content.id,
                is_hesitate: 1,
                refund_price: content.actual_price,
              });
              if (result.code == 0) {
                Message({ type: "success", message: "退款成功" });
                getList();
              }
            } else {
              const data = {
                item_id: content.id,
                refund_price: content.actual_price,
                is_refund: content.is_refund,
              };
              router.push({
                path: "/account/order-refund",
                query: { data: encodeURIComponent(JSON.stringify(data)) },
              });
            }
          }
        } else {
          const data = {
            item_id: content.id,
            refund_price: content.actual_price,
            // is_refund: content.is_refund
          };
          router.push({
            path: "/account/order-refund",
            query: { data: encodeURIComponent(JSON.stringify(data)) },
          });
        }
      } else {
        //评价
        console.log(item);
        console.log(content);
        const data = {
          fid: item.id,
          order_no: item.order_no,
          create_at: item.create_at,
          ...content,
        };
        console.log(data);
        router.push({
          path: "/account/order-evaluate",
          query: { data: encodeURIComponent(JSON.stringify(data)) },
        });
      }
    };
    const balancePay = async () => {
      const res = await Pay({
        order_no: order_num.value,
        price: price.value,
        pay_way: pay_way.value,
        pay_pwd: pay_pwd.value,
      });
      if (res.code === 0) {
        Message({ type: "success", message: "支付成功" });
        isbalance.value = false;
        getList();
      }
    };
    const handleOk = async () => {
      if (pay_way.value === 3) {
        visible.value = false;
        setTimeout(() => {
          isbalance.value = true;
        }, 500);
        return;
      }
      const res = await Pay({
        order_no: order_num.value,
        price: price.value,
        pay_way: pay_way.value,
      });
      console.log(res);
      console.log(pay_way.value);
      if (res.code == 0) {
        if (pay_way.value == 1) {
          const div = document.createElement("div");
          console.log(div);
          /* 下面的data.content就是后台返回接收到的数据 */
          div.innerHTML = res.code_url;
          console.log(div);
          document.body.appendChild(div);
          document.forms[0].submit();
        } else if (pay_way.value == 2) {
          qrcodeUrl.value = res.code_url;
          console.log(qrcodeUrl.value);
          const details = {
            qrcodeUrl: qrcodeUrl.value,
            order_no: order_num.value,
            price: price.value,
          };
          router.push({
            path: "/order/weixinPay",
            query: { data: encodeURIComponent(JSON.stringify(details)) },
          });
        }
      }
    };
    onMounted(() => {
      getList();
      if (route.query.status) {
        tabindex.value = route.query.status;
        status.value = route.query.status;
      }
    });
    return {
      navList,
      tabindex,
      total,
      currentPage,
      pageSize,
      getCurrentPage,
      order_no,
      getList,
      getReList,
      status,
      changeTab,
      onSearch,
      orderlist,
      cancle,
      sure,
      // confirm,
      cancel,
      Refund,
      visible,
      price,
      pay_way,
      change,
      handleOk,
      order_num,
      qrcodeUrl,
      element,
      elheight,
      pay_pwd,
      isbalance,
      balancePay,
    };
  },
};
</script>
<style scoped lang="less">
.content {
  width: 940px;
  min-height: 906px;
  background: #ffffff;
  margin-bottom: 101px;
  padding: 40px;
  .content-header {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #f1f1f4;
    display: flex;
    .content-header_list {
      width: 90px;
      height: 38px;
      margin-right: 32px;
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 18px;
      &.active {
        border-bottom: 1px solid #296df1;
        color: #296df1;
      }
    }
  }
}
.list_header {
  width: 100%;
  height: 39px;
  background: #f8f8f9;
  border: 1px solid #f1f1f4;
  color: #515a6e;
  display: flex;
}
.list_content {
  width: 100%;
  min-height: 39px;
  margin-top: 11px;
  color: #515a6e;
  font-size: 12px;
  .list_content_header {
    width: 100%;
    height: 39px;
    background: #f8f8f9;
    border: 1px solid #f1f1f4;
    display: flex;
    align-items: center;
    padding: 0 21px;
    display: flex;
    justify-content: space-between;
  }
  .list_content_details {
    width: 100%;
    min-height: 101px;
    display: flex;
    padding: 10px 0;
    .details_img {
      width: 80px;
      height: 60px;
      // border: 1px solid red;
      margin-left: 21px;
      margin-right: 10px;
    }
    .btn {
      width: 72px;
      height: 26px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.color1 {
        background: #ff3c31;
        color: #ffffff;
      }
      &.color2 {
        background: #eeeeee;
        color: #17233d;
      }
      &.color3 {
        background: #296df1;
        color: #ffffff;
      }
    }
    .tag {
      display: block;
      width: 116px;
      height: 18px;
      background: rgb(230, 249, 237);
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #00bc4b;
      font-size: 10px;
      img {
        width: 9px;
        height: 9px;
      }
    }
  }
}
.list_content1 {
  width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.list_content2 {
  width: 130px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
}
.border-right {
  border-right: 1px solid #f1f1f4;
}
.list_content_footer {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: flex-end;
  // text-align: right;
  .btn1 {
    // width: 86px;
    // height: 26px;
    padding: 4px 10px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-right: 5px;
    &.color1 {
      background: #ff3c31;
      color: #ffffff;
    }
    &.color2 {
      border: 1px solid #cccccc;
      color: #808695;
    }
  }
}
</style>
