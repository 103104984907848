<template>
  <div>
    <div class="service_top"></div>
    <div class="service">
      <div class="service_content">
        <div class="service_header">
          <XtxBread class="xtx-bread">
            <XtxBreadItem to="/" class="xtx-bread-item" norColor="#808695">
              我的位置：首页
            </XtxBreadItem>
            <transition name="fade-right" mode="out-in"> 客服中心 </transition>
          </XtxBread>
        </div>
        <div class="service_son">
          <div class="service_son_content">
            <p>
              <span class="tag"></span>
              <span class="title">售前客服</span>
            </p>
            <div class="service_son_details">
              <div
                class="service_details_son"
                v-for="(item, index) in before_list"
                :key="index"
              >
                <img :src="item.qrcode" class="service_son_image" />
                <p style="margin-top: 12px">{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div class="service_son_content">
            <p>
              <span class="tag"></span>
              <span class="title">售后客服</span>
            </p>
            <div class="service_son_details">
              <div
                class="service_details_son"
                v-for="(item, index) in after_list"
                :key="index"
              >
                <img :src="item.qrcode" class="service_son_image" />
                <p style="margin-top: 12px">{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CustomerList } from '@/api/FirstPage'
import { ref, onMounted } from 'vue'
export default {
  setup () {
    const after_list = ref([])
    const before_list = ref([])
    const getCustomerList = async () => {
      const res = await CustomerList()
      if (res.code == 0) {
        after_list.value = res.data.after_list
        before_list.value = res.data.before_list
      }
    }
    onMounted(() => {
      getCustomerList()
    })
    return {
      getCustomerList,
      after_list,
      before_list
    }
  }
}
</script>
<style scoped lang="less">
.service_top {
  width: 100%;
  height: 235px;
  background: url(~@/assets/images/serviceBananer.png) no-repeat;
  background-size: 100%;
}
.service {
  width: 100%;
  min-height: 610px;
  .service_content {
    width: 1000px;
    margin: 0 auto;
    padding-top: 20px;
    .service_header {
      width: 100%;
      .xtx-bread {
        color: #808695;
      }
    }
    .service_son {
      width: 100%;
      min-height: 610px;
      background: #ffffff;
      margin-top: 14px;
      padding: 49px 40px;
      .service_son_content {
        width: 100%;
        p {
          display: flex;
          align-items: center;
        }
        .tag {
          display: block;
          width: 6px;
          height: 21px;
          background: #296df1;
        }
        .title {
          font-size: 24px;
          margin-left: 16px;
        }
      }
      .service_son_details {
        width: 100%;
        padding: 60px 155px;
        display: flex;
        flex-wrap: wrap;
        .service_son_image {
          width: 100px;
          height: 100px;
        }
        .service_details_son {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 70px;
          margin-bottom: 20px;
        }
        .service_details_son:nth-child(4n) {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>