// 1. 创建一个新的axios实例
// 2. 请求拦截器，如果有token进行头部携带
// 3. 响应拦截器：1. 剥离无效数据  2. 处理token失效
// 4. 导出一个函数，调用当前的axsio实例发请求，返回值promise

// 导入 axios
import axios from 'axios';
// 导入实例库  用于获取token
import store from '@/store';
// 导入 路由实例 ，用于跳转页面
import router from '@/router';
import Message from '@/components/library/Message';
// 导出基准地址，原因：其他地方不是通过axios发请求的地方用上基准地址
// 请求基地址,做一个export导出,引入 request.js 的时候可以通过解构获取到请求基地址
// 🔔 两台在线服务器，如果某一台比较慢可以换成另一台
// 💥 用户登录测试账号，需要切换到测试服务器
// export const baseURL = 'http://pcapi-xiaotuxian-front-devtest.itheima.net/'
//export const baseURL = 'http://law.hbhnt.net/api';
export const baseURL = 'https://admin.wanmou.top/api';

// 创建 axios 实例
const instance = axios.create({
  // axios 的一些配置，baseURL  timeout
  baseURL, // baseURL: bsaeURL
  timeout: 50000, // 请求5秒超时
});

// 请求拦截器

instance.interceptors.request.use(
  (config) => {
    // 拦截业务逻辑
    // 进行请求配置的修改
    // 如果本地又token就在头部携带
    // 1. 获取用户信息对象 获取仓库中的用户数据
    const { token } = store.state.user;
    // 2. 判断是否有token
    if (token != '') {
      console.log('token', store.state.user.token);
      // 3. 设置token 把token 信息添加到请求头中
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = '';
    }
    // 返回加工后的配置，用于发送请求
    return config;
  },
  (err) => {
    // 用于 catch 捕获错误信息
    return Promise.reject(err);
  }
);

// 响应拦截器
// res => res.data  取出data数据，将来调用接口的时候直接拿到的就是后台的数据
instance.interceptors.response.use((response) => {
  // console.log(response);
  const token = store.state.user;
  if (response.data.code == 0) {
    return response.data;
  } else if (
    response.data.code !== 0 &&
    response.data.msg &&
    (response.data.msg.indexOf('请登陆') >= 0 ||
      response.data.msg.indexOf('登录令牌已失效') >= 0 ||
      response.data.msg.indexOf('非法用户！') >= 0)
  ) {
    Message({ type: 'warn', message: response.data.msg });
    if (token) {
      // console.log('-----');
      store.commit('user/setToken', '');
      store.commit('user/setUser', {});
      store.commit('user/setKeyword', '');
      store.commit('user/clearCart', []);
      setTimeout(() => {
        // 当前路由地址
        // 组件里头：`/user?a=10` $route.path === /user  $route.fullPath === /user?a=10
        // js模块中：router.currentRoute.value.fullPath 就是当前路由地址，router.currentRoute 是ref响应式数据
        const fullPath = encodeURIComponent(router.currentRoute.value.fullPath);
        // encodeURIComponent 转换uri编码，防止解析地址出问题
        router.push('/login?redirectUrl=' + fullPath);
      }, 500);
    } else {
      // console.log('--====---');
    }
  } else if (response.data.code !== 0 && response.data.msg) {
    Message({ message: response.data.msg });
    return response.data;
  }
});

// 请求工具函数
const require = (url, method = 'get', submitData) => {
  // 负责发请求：请求地址，请求方式，提交的数据
  return instance({
    url,
    method,
    // 1. 如果是get请求  需要使用params来传递submitData   ?a=10&c=10
    // 2. 如果不是get请求  需要使用data来传递submitData   请求体传参
    // [] 设置一个动态的key, 写js表达式，js表达式的执行结果当作KEY
    // method参数：get,Get,GET  转换成小写再来判断
    // 在对象，['params']:submitData ===== params:submitData 这样理解
    [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData,
  });
};

export default require;
