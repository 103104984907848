<template>
  <div class="content">
    <div class="content_details">
      <XtxBread class="xtx-bread">
        <XtxBreadItem
          to="/account/my-order"
          class="xtx-bread-item"
          norColor="#808695"
        >
          我的订单
        </XtxBreadItem>
        <transition name="fade-right" mode="out-in"> 订单详情 </transition>
      </XtxBread>
      <div class="details_top">
        <div class="details_top_left">
          <p class="p1">订单号：{{ details.order_no }}</p>
          <p class="p2" v-if="details.status == 1">
            <span class="span1">等待付款</span>
            <!-- <span>剩余22小时16分钟</span> -->
          </p>
          <p class="p2" v-if="details.status == 3">
            <span style="font-size: 18px; color: #17233d">已发货</span>
            <span>订单已发货，请注意查收</span>
          </p>
          <!-- <p class="p2" v-if="details.status == 3">
            <span style="font-size: 18px; color: #17233d">已发货</span>
            <span>订单已发货，请注意查收</span>
          </p> -->
          <p class="p2" v-if="details.status == 2">
            <span style="font-size: 18px; color: #17233d">已支付</span>
            <span>订单已支付，请等待发货</span>
          </p>
          <p class="p2" v-if="details.status == 4">
            <span style="font-size: 18px; color: #17233d">已完成</span>
            <span>订单已完成，请评价服务</span>
          </p>
          <p class="btn btn3" v-if="details.status == 1" @click="sure(details)">
            立即付款
          </p>
          <a-popconfirm
            title="是否有异议"
            ok-text="是"
            cancel-text="否"
            @confirm="confirm"
            @cancel="cancel(details)"
            v-if="details.status == 3 && details.sure_by_plat == 1"
          >
            <p class="btn btn3">确认收货</p>
          </a-popconfirm>
          <p
            class="btn btn3"
            v-if="details.status == 3 && details.sure_by_plat == 0"
            @click="cancel(details)"
          >
            确认收货
          </p>
          <p
            class="btn btn2"
            v-if="details.status == 1"
            @click="cancle(details)"
          >
            取消订单
          </p>
          <!-- <p class="btn btn2" v-if="details.status == 2">申请退款</p> -->
          <!-- <p class="btn btn1" v-if="details.status == 4">去评价</p> -->
          <!-- <p>取消订单</p> -->
        </div>
        <div class="details_top_right">
          <p>该订单为您保留24小时，超时未付款，系统将自动取消订单。</p>
          <div class="details_top_right_son">
            <div class="top_right">
              <div
                class="top_right_img"
                :class="{
                  active:
                    details.status == 5 ||
                    details.status == 4 ||
                    details.status == 3 ||
                    details.status == 2 ||
                    details.status == 1,
                }"
              >
                <img src="@/assets/images/Frame@2x(14).png" alt="" srcset="" />
              </div>
              <p>提交订单</p>
              <p>{{ details.create_at }}</p>
            </div>
            <div
              class="line"
              :class="{
                'l-active':
                  details.status == 5 ||
                  details.status == 4 ||
                  details.status == 3 ||
                  details.status == 2,
              }"
            ></div>
            <div class="top_right">
              <div
                class="top_right_img"
                :class="{
                  active:
                    details.status == 5 ||
                    details.status == 4 ||
                    details.status == 3 ||
                    details.status == 2,
                }"
              >
                <img src="@/assets/images/Frame@2x(15).png" alt="" srcset="" />
              </div>
              <p>支付订单</p>
              <p>{{ details.payment_time }}</p>
            </div>
            <div
              class="line"
              :class="{
                'l-active':
                  details.status == 5 ||
                  details.status == 4 ||
                  details.status == 3,
              }"
            ></div>
            <div class="top_right">
              <div
                class="top_right_img"
                :class="{
                  active:
                    details.status == 5 ||
                    details.status == 4 ||
                    details.status == 3,
                }"
              >
                <img src="@/assets/images/Frame@2x(16).png" alt="" srcset="" />
              </div>
              <p>订单发货</p>
              <p>{{ details.express_time }}</p>
            </div>
            <div
              class="line"
              :class="{
                'l-active': details.status == 5 || details.status == 4,
              }"
            ></div>
            <div class="top_right">
              <div
                class="top_right_img"
                :class="{ active: details.status == 5 || details.status == 4 }"
              >
                <img src="@/assets/images/Frame@2x(17).png" alt="" srcset="" />
              </div>
              <p>确认收货</p>
              <p>{{ details.confirm_time }}</p>
            </div>
            <div
              class="line"
              :class="{
                'l-active': details.status == 5 || details.status == 4,
              }"
            ></div>
            <div class="top_right">
              <div
                class="top_right_img"
                :class="{ active: details.status == 5 || details.status == 4 }"
              >
                <img src="@/assets/images/Frame@2x(18).png" alt="" srcset="" />
              </div>
              <p>订单完成</p>
              <p>{{ details.confirm_time }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="details_bottom">
        <div class="bottom_header">
          <div class="bottom_header_son1">
            <p class="header_son_title">收货人信息</p>
            <a-row style="margin-bottom: 10px">
              <a-col :span="5">收货人：</a-col>
              <a-col :span="18">{{ details.receive_name }}</a-col>
            </a-row>
            <a-row style="margin-bottom: 10px">
              <a-col :span="5">地址：</a-col>
              <a-col :span="18">{{ details.receive_address }}</a-col>
            </a-row>
            <a-row style="margin-bottom: 10px">
              <a-col :span="5">手机号码：</a-col>
              <a-col :span="18">{{ details.receive_phone }}</a-col>
            </a-row>
            <a-row style="margin-bottom: 10px">
              <a-col :span="5">电子邮箱：</a-col>
              <a-col :span="18">{{ details.receive_email }}</a-col>
            </a-row>
          </div>
          <div class="bottom_header_son1" style="padding: 0 0 0 50px">
            <p class="header_son_title">支付及配送方式</p>
            <a-row style="margin-bottom: 10px">
              <a-col :span="6">支付方式：</a-col>
              <a-col :span="5">在线支付</a-col>
            </a-row>
            <a-row style="margin-bottom: 10px">
              <a-col :span="6">配送方式：</a-col>
              <!--选择配送方式是电子送达  -->
              <a-col :span="5">{{
                details.truck_way == 1
                  ? "电子送达"
                  : details.truck_way == 2
                  ? "即时性送达"
                  : "快递配送"
              }}</a-col>
            </a-row>
          </div>
          <div class="bottom_header_son1" style="padding: 0 0 0 50px">
            <p class="header_son_title">发票信息</p>
            <!-- 暂不需要开具发票 -->
            <div v-if="details.bill_type != 0">
              <a-row style="margin-bottom: 10px">
                <a-col :span="6">发票类型：</a-col>
                <a-col :span="18">{{
                  details.bill_type == 1 ? "普通发票" : "增值税发票"
                }}</a-col>
              </a-row>
              <a-row style="margin-bottom: 10px">
                <a-col :span="6">发票抬头：</a-col>
                <a-col :span="5">{{
                  details.bill_head == 1 ? "个人" : "公司"
                }}</a-col>
              </a-row>
              <a-row style="margin-bottom: 10px">
                <a-col :span="6">发票内容：</a-col>
                <a-col :span="5">产品明细</a-col>
              </a-row>
            </div>
            <div v-else>暂不需要开具发票</div>
          </div>
        </div>
        <div class="botton_foot">
          <div class="cart">
            <table>
              <thead>
                <tr style="text-align: center">
                  <th width="400">产品名称</th>
                  <th width="100">价格（元）</th>
                  <th width="180">数量</th>
                  <th width="180">小计</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in details.good_list" :key="item.skuId">
                  <td>
                    <div class="goods">
                      <RouterLink :to="`/product/${item.id}`"
                        ><img :src="item.goods_cover" alt=""
                      /></RouterLink>
                      <div>
                        <p class="name">{{ item.goods_name }}</p>
                      </div>
                    </div>
                  </td>
                  <td class="tc">
                    <p>{{ item.price_selling }}</p>
                  </td>
                  <td class="tc">×{{ item.stock_sales }}</td>
                  <td class="tc">
                    <p class="f16">{{ item.total_selling }}</p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <!-- <p>
                      <img
                        src="@/assets/images/icon_16x16px@2x.png"
                        alt=""
                        class="addbeans"
                      />
                      <span style="font-size: 12px; color: #296df1"
                        >使用万谋豆抵扣</span
                      >
                    </p> -->
                  </td>
                  <td colspan="2">
                    <div>
                      <a-row>
                        <a-col :span="6">产品件数:</a-col>
                        <a-col
                          :span="18"
                          style="color: #ff3c31; text-align: right"
                          >{{ details.number_goods }}</a-col
                        >
                      </a-row>
                      <a-row>
                        <a-col :span="6">产品总价:</a-col>
                        <a-col
                          :span="18"
                          style="color: #ff3c31; text-align: right"
                          >{{ details.amount_goods }}</a-col
                        >
                      </a-row>
                      <a-row>
                        <a-col :span="6">万谋豆抵扣:</a-col>
                        <a-col
                          :span="18"
                          style="color: #ff3c31; text-align: right"
                          >{{ details.payment_balance }}</a-col
                        >
                      </a-row>
                      <a-row style="margin-top: 10px">
                        <a-col :span="6">应付总额：</a-col>
                        <a-col
                          :span="18"
                          style="color: #ff3c31; text-align: right"
                        >
                          <span>￥</span>
                          <span style="font-size: 22px; font-weight: 700">{{
                            details.payment_amount
                          }}</span>
                        </a-col>
                      </a-row>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    width="400px"
    title="确认支付"
    cancelText="取消"
    okText="确认"
    @ok="handleOk"
  >
    <a-row>
      <a-col :span="6">支付金额：</a-col>
      <a-col :span="18">{{ price }}</a-col>
    </a-row>
    <a-row style="margin-top: 10px">
      <a-col :span="6">支付方式：</a-col>
      <a-col :span="18">
        <a-radio-group
          name="radioGroup"
          v-model:value="pay_way"
          @change="change"
        >
          <a-radio :value="1">支付宝</a-radio>
          <a-radio :value="2">微信</a-radio>
        </a-radio-group>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { orderDetail, cancelOrder, orderSure, Pay } from "@/api/Orders";
import { useRoute, useRouter } from "vue-router";
export default {
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const id = ref("");
    const details = ref("");
    const visible = ref(false);
    const price = ref("");
    const pay_way = ref(1);
    const order_num = ref("");
    const qrcodeUrl = ref("");
    const getdetails = async () => {
      const res = await orderDetail({ id: id.value });
      details.value = res.data;
      console.log("- details.value-", details.value);
    };
    const cancle = async (item) => {
      const res = await cancelOrder({
        id: item.id,
      });
      if (res.code == 0) {
        getdetails();
      }
    };
    const cancel = async (item) => {
      const res = await orderSure({ id: item.id });
      if (res.code == 0) {
        getdetails()();
      }
    };
    const sure = async (item) => {
      //支付
      visible.value = true;
      price.value = item.payment_amount;
      order_num.value = item.order_no;
    };
    const handleOk = async () => {
      const res = await Pay({
        order_no: order_num.value,
        price: price.value,
        pay_way: pay_way.value,
      });
      console.log(res);
      console.log(pay_way.value);
      if (res.code == 0) {
        if (pay_way.value == 1) {
          const div = document.createElement("div");
          console.log(div);
          /* 下面的data.content就是后台返回接收到的数据 */
          div.innerHTML = res.code_url;
          console.log(div);
          document.body.appendChild(div);
          document.forms[0].submit();
        } else {
          qrcodeUrl.value = res.code_url;
          console.log(qrcodeUrl.value);
          const details = {
            qrcodeUrl: qrcodeUrl.value,
            order_no: order_num.value,
            price: price.value,
          };
          router.push({
            path: "/order/weixinPay",
            query: { data: encodeURIComponent(JSON.stringify(details)) },
          });
        }
      }
    };
    onMounted(() => {
      id.value = route.params.id;
      getdetails();
    });
    return {
      id,
      getdetails,
      details,
      cancle,
      sure,
      handleOk,
      cancel,
      visible,
      price,
      pay_way,
      order_num,
      qrcodeUrl,
    };
  },
};
</script>
<style scoped lang="less">
.content {
  width: 100%;
  min-height: 250px;
  .content_details {
    width: 1200px;
    margin: 0 auto;
    padding: 30px 0;
    .xtx-bread {
      font-size: 12px;
      color: #808695;
    }
    .details_top {
      width: 100%;
      height: 250px;
      background: #ffffff;
      margin-top: 17px;
      border-top: 3px solid #296df1;
      display: flex;
      color: #808695;
      .details_top_left {
        width: 290px;
        height: 100%;
        border-right: 1px solid #f1f1f4;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .p1 {
          margin-bottom: 32px;
        }
        .p2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 16px;
          .span1 {
            font-size: 18px;
            color: #ff3c31;
          }
        }
        .btn {
          width: 90px;
          height: 30px;
          border-radius: 2px;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.btn1 {
            background: #296df1;
            color: #ffffff;
          }
          &.btn2 {
            border: 1px solid #cccccc;
            color: #808695;
          }
          &.btn3 {
            background: #ff3c31;
            color: #ffffff;
          }
        }
      }
      .details_top_right {
        flex: 1;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        .details_top_right_son {
          display: flex;
          margin-top: 34px;
        }
        .top_right {
          display: flex;
          flex-direction: column;
          align-items: center;
          .top_right_img {
            width: 34px;
            height: 34px;
            background: #cccccc;
            border-radius: 50%;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              background: #296df1;
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        .line {
          width: 48px;
          height: 2px;
          border: 1px solid #c4c4c4;
          margin-top: 17px;
          &.l-active {
            border: 1px solid #296df1;
          }
        }
      }
    }
    .details_bottom {
      width: 100%;
      min-height: 606px;
      background: #ffffff;
      margin-top: 20px;
      margin-bottom: 102px;
      padding: 40px;
      .bottom_header {
        width: 100%;
        height: 156px;
        display: flex;
        font-size: 12px;
        color: #17233d;
        .bottom_header_son1 {
          width: 33%;
          height: 100%;
          border-right: 1px solid #f1f1f4;
          display: flex;
          flex-direction: column;
        }
        .bottom_header_son1:last-child {
          border: none;
        }
        .header_son_title {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 18px;
        }
      }
      .botton_foot {
        width: 100%;
        min-height: 164px;
        margin-top: 36px;
        .cart {
          background: #fff;
          color: #515a6e;
          table {
            border-spacing: 0;
            border-collapse: collapse;
            line-height: 24px;
            margin-top: 12px;
            thead {
              width: 100%;
              height: 34px;
              background: #f8f8f9;
            }
            tr {
              border: 1px solid #f1f1f4;
              border-top: none;
            }
            th,
            td {
              padding: 10px;
              border-bottom: 1px solid #f5f5f5;
              text-align: center;
              &:first-child {
                text-align: left;
                padding-left: 30px;
                // color: #999;
              }
            }
            th {
              font-size: 16px;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
</style>
