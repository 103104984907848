// 用户模块
export default {
  namespaced: true,
  state() {
    return {
      // 用户信息
      token: '',
      userinfo: {
        nickname: '',
        base_sex: '',
        base_year: '',
        base_month: '',
        base_day: '',
        email: '',
        phone: '',
        headimg: '',
        balance_rest: '',
      },
      list: [],
      keywords: '',
    };
  },
  mutations: {
    // 修改用户信息，payload就是用户信息对象
    setUser(state, payload) {
      state.userinfo = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setCar(state, payload) {
      state.carLength = payload;
    },
    setKeyword(state, payload) {
      state.keywords = payload;
    },
    // 加入购物车
    insertCart(state, payload) {
      state.list = payload;
    },
    //清空购物车
    clearCart(state, payload) {
      state.list = payload;
    },
  },
  // 相当于计算属性
  getters: {
    validTotal(state) {
      let total = 0
      state.list.forEach(item => {
        total += item.number
      })
      return total
    },
  },
};
