import request from '@/utils/request';
/**
 * 帐号登录
 * @param {*} account - 用户名
 * @param {*} pass - 用户名
 * @returns Promise
 */
export const userAccountLogin = ({ account, pass, type = 1 }) => {
  return request('/User/login', 'post', { account, pass, type });
};
/**
 * QQ登录
 * @param {*} account - 用户名
 * @param {*} pass - 用户名
 * @returns Promise
 */
export const qqLogin = () => {
  return request('/User/qqLogin', 'post', {});
}
export const qqNotify = () => {
  return request('/User/qq_notify', 'post', {});
}
/**
 * 支付宝登录
 * @param {*} account - 用户名
 * @param {*} pass - 用户名
 * @returns Promise
 */
export const aliLogin = () => {
  return request('/User/aliLogin', 'post', {});
};
/**
 * 支付宝登录
 * @param {*} account - 用户名
 * @param {*} pass - 用户名
 * @returns Promise
 */
export const aliLoginCde = ({ auth_code, ali_state }) => {
  return request('/User/getAliInfo', 'post', {
    auth_code, ali_state
  });
};
/**
 * 手机号登录
 * @param {*} account - 用户名
 * @param {*} pass - 用户名
 * @returns Promise
 */
export const userPhoneLogin = ({ phone, code, type = 2 }) => {
  return request('/User/login', 'post', { phone, code, type });
};
/**
 * 发送验证码
 * @param {*} account - 用户名
 * @param {*} pass - 用户名
 * @returns Promise
 */
export const sendCode = (data) => {
  return request('/User/getauth', 'post', data);
};
/**
 * 注册
 * @param {*} data
 * @returns Promise
 */
export const userAccountRegister = (data) => {
  return request('/User/register', 'post', data);
};
/**
 * 忘记密码
 * @param {*} data
 * @returns Promise
 */
export const forgetPwd = (data) => {
  return request('/User/forgetPwd', 'post', data);
};
/**
 * 修改登录密码
 */
export const doEdit = (data) => {
  return request('/User/doEdit', 'post', data);
};
/**
 * 退出登陆
 */
export const logOut = () => {
  return request('/User/logout', 'post');
};
/**
 * 获取用户信息
 */
export const userInfo = () => {
  return request('/User/getUserInfo', 'post');
};
/**
 * 上传文件
 */
export const uphead = (data) => {
  return request('/User/uphead', 'post', data);
};
/**
 * 地址列表
 */
export const addressList = (data) => {
  return request('/User/addressList', 'post', data);
};
/**
 * 新建或者编辑收货地址
 */
export const operateAddress = (data) => {
  return request('/User/operateAddress', 'post', data);
};
/**
 *删除收货地址
 */
export const delAddress = (data) => {
  return request('/User/delAddress', 'post', data);
};
/**
 * 用户通知列表
 */
export const noticeList = (data) => {
  return request('/User/noticeList', 'post', data);
};
/**
 * 用户通知回收站列表
 */
export const delNiceList = (data) => {
  return request('/User/delNiceList', 'post', data);
};
/**
 * 批量删除或者恢复通知
 */
export const delNotice = (data) => {
  return request('/User/delNotice', 'post', data);
};
/**
 * 批量彻底删除通知
 */
export const thoroughDelNotice = (data) => {
  return request('/User/thoroughDelNotice', 'post', data);
};
/**
 * 批量标记通知为已读
 */
export const saveNotice = (data) => {
  return request('/User/saveNotice', 'post', data);
};
/**
 * 投诉处理列表
 */
export const complainList = (data) => {
  return request('/User/complainList', 'post', data);
};
/**
 * 批量删除投诉
 */
export const delComplain = (data) => {
  return request('/User/delComplain', 'post', data);
};
/**
 * 批量修改投诉未读状态
 */
export const updateComplain = (data) => {
  return request('/User/updateComplain', 'post', data);
};
/**
 * 投诉处理详情
 */
export const complainDetail = (id) => {
  return request('/User/complainDetail', 'post', { id });
};
/**
 * 我的评价列表
 */
export const myCommentList = (data) => {
  return request('/User/myCommentList', 'post', data);
};
/**
 * 万谋豆收支明细列表
 */
export const balanceRecordList = (data) => {
  return request('/User/balanceRecordList', 'post', data);
};
/**
 * 获取万谋豆充值优惠列表
 */
export const balanceDiscountList = () => {
  return request('/User/balanceDiscountList', 'post');
};
/**
 * 万谋豆充值列表
 */
export const balanceChargeList = (data) => {
  return request('/User/balanceChargeList', 'post', data);
};
/**
 * 万谋币充值
 */
export const charge = (data) => {
  return request('/User/charge', 'post', data);
};
/**
 * 用户收藏列表
 */
export const collectList = (data) => {
  return request('/User/collectList', 'post', data);
};
/**
 * 收藏或者取消收藏
 */
export const saveCollect = (data) => {
  return request('/User/saveCollect', 'post', data);
};
/**
 * 添加或者更新发票抬头
 */
export const saveBill = (data) => {
  return request('/User/saveBill', 'post', data);
};
/**
 * 用户发票信息
 */
export const billInfo = () => {
  return request('/User/billInfo', 'post');
};
/**
 * 修改用户个人信息
 */
export const editUserInfo = (data) => {
  return request('/User/editUserInfo', 'post', data);
};
/**
 * 上传头像
 */
export const uploadImg = (data) => {
  return request('/User/upload_img', 'post', data);
};
/**
 * 购物车列表
 */
export const getCartList = (data) => {
  return request('/User/getCartList', 'post', data);
};
/**
 * 添加购物车
 */
export const addCart = (data) => {
  return request('/User/addCart', 'post', data);
};
/**
 * 修改购物车单个产品的选中状态
 *
 */
export const editCartChecked = (data) => {
  return request('/User/editCartChecked', 'post', data);
};
/**
 * 修改购物车单个产品的数量
 */
export const editCartNumber = (data) => {
  return request('/User/editCart', 'post', data);
};
/**
 * 购物车删除
 */
export const delCart = (data) => {
  return request('/User/delCart', 'post', data);
};
/**
 * 评价接口
 */
export const comment = (data) => {
  return request('/User/comment', 'post', data);
};
/**
 * 登录页 微信授权登录
 */
export const wxLogin = (data) => {
  return request('/User/wxLogin', 'post', data);
};
/**
 * 微信与账号之间的绑定
 */
export const bindWx = (data) => {
  return request('/User/bindWx', 'post', data);
};
/**
 * 账号与QQ之间的绑定
 */
export const bindQQ = (data) => {
  return request('/User/bindQQ', 'post', data);
};
/**
 * QQ与账号之间的绑定
 */
export const bindQQWidthToken = (data) => {
  return request('/User/bindQQ2', 'post', data);
};
/**
 * 支付宝与账号之间的绑定
 */
export const bindAli = (data) => {
  return request('/User/bindAli', 'post', data);
};
/**
 * 解除第三方账号与手机号之间的绑定
 */
export const unbind = (data) => {
  return request('/User/unbind', 'post', data);
};
/**
 * 第三方账号绑定 微信
 */
export const bindWx2 = (data) => {
  return request('/User/bindWx2', 'post', data);
};
/**
 * 判断是否设置支付密码
 */
export const isSetPwd = (data) => {
  return request('/User/isSetPwd', 'post', data);
};
/**
 * 设置支付密码
 */
export const setPayPwd = (data) => {
  return request('/User/setPayPwd', 'post', data);
};
/**
 * 修改支付密码
 */
export const editPayPwd = (data) => {
  return request('/User/editPayPwd', 'post', data);
};
/**
 * 未读消息
 */
 export const noReadMsg = (data) => {
  return request('/user/getNoReadNoticeCount', 'post', data);
};