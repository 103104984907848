import request from '@/utils/request';
/**
 * 首页
 * @param {*}
 * @returns Promise
 */
export const getFirst = () => {
  return request('/FirstPage/first', 'post');
};
/**
 * 首页一级列表
 * @param {*}
 * @returns Promise
 */
export const getcategory = () => {
  return request('/FirstPage/category', 'post');
};
/**
 * 首页文本内容
 */
export const lawDetail = (data) => {
  return request('/FirstPage/lawDetail', 'post', data);
};
/**
 * 产品详情
 * @param {*}
 * @returns Promise
 */
export const goodDetail = (id) => {
  return request('/FirstPage/goodDetail', 'post', { id });
};
/**
 * 评价列表
 * @param {*}
 * @returns Promise
 */
export const commentList = (data) => {
  return request('/FirstPage/commentList', 'post', data);
};
/**
 * 全部分类列表
 * @param {*}
 * @returns Promise
 */
export const getAllCategory = () => {
  return request('/FirstPage/getAllCategory', 'post');
};
/**
 * 根服务类型获取案件类型
 * @param {*}
 * @returns Promise
 */
export const getThirdCategory = (data) => {
  return request('/FirstPage/getThirdCategory', 'post', data);
};
/**
 * 所有产品列表
 * @param {*}
 * @returns Promise
 */
export const getGoodsList = (data) => {
  return request('/FirstPage/goodsList', 'post', data);
};
/**
 * 平台介绍
 * @param {*}
 * @returns Promise
 */
export const getIntroduce = () => {
  return request('/FirstPage/introduce', 'post');
};
/**
 * 关于我们
 * @param {*}
 * @returns Promise
 */
export const getAboutUs = () => {
  return request('/FirstPage/about_us', 'post');
};
/**
 * 申请售后
 * @param {*}
 * @returns Promise
 */
export const getservice = () => {
  return request('/FirstPage/service', 'post');
};
/**
 * 投诉
 * @param {*}
 * @returns Promise
 */
export const getComplain = (data) => {
  return request('/FirstPage/complain', 'post', data);
};
/**
 * 意见反馈
 */
export const getFeedback = (data) => {
  return request('/FirstPage/feedback', 'post', data);
};
/**
 * 帮助中心
 */
export const gethelp = (data) => {
  return request('/FirstPage/help', 'post', data);
};
/**
 * 平台二维码列表以及网站备案信息
 */
export const platCodeList = (data) => {
  return request('/FirstPage/platCodeList', 'post', data);
};
/**
 * 获取产品规格价格
 */
export const getAttrsPrice = (data) => {
  return request('/FirstPage/getAttrsPrice', 'post', data);
};
/**
 * 诉讼费速算
 */
export const lawsuitCount = (data) => {
  return request('/FirstPage/lawsuitCount', 'post', data);
};
/**
 * 客服中心
 */
export const CustomerList = (data) => {
  return request('/FirstPage/customerList', 'post');
};

/**
 * 获取首充福利提示
 */
export const getAlertContent = (data) => {
  return request('/FirstPage/getAlertContent', 'post');
};