<template>
  <div class="address">
    <div class="address-header">收货地址</div>
    <div class="address-content">
      <p class="address-add" @click="showModal">+ 新增收货地址</p>
      <div
        class="address-list"
        v-for="(item, index) in List"
        :key="index"
        @click.stop="changeNewAds(item)"
      >
        <p class="address-list-header">
          <span style="font-size: 16px; color: #17233d"
            >{{ item.name }} 收</span
          >
          <span style="font-size: 28px" @click="Delete(item.id)">×</span>
        </p>
        <a-row>
          <a-col :span="2">收件人:</a-col>
          <a-col :span="12">{{ item.name }}</a-col>
        </a-row>
        <a-row>
          <a-col :span="2">手机号:</a-col>
          <a-col :span="12">{{ item.phone }}</a-col>
        </a-row>
        <a-row>
          <a-col :span="2">地址:</a-col>
          <a-col :span="12">{{ item.provinces }} {{ item.address }}</a-col>
        </a-row>
        <a-row>
          <a-col :span="2">电子邮箱:</a-col>
          <a-col :span="22">
            <a-row>
              <a-col :span="18">{{ item.email }}</a-col>
              <a-col :span="6" style="color: #296df1; display: flex">
                <p style="width: 16px; height: 16px">
                  <!-- <img
                    src="@/assets/images/shezhi@2x.png"
                    alt=""
                    srcset=""
                    style="width: 16px; height: 16px"
                  />
                  <span>默认地址</span> -->
                </p>
                <p @click="redact(item)">
                  <img
                    src="@/assets/images/edit@2x.png"
                    alt=""
                    srcset=""
                    style="width: 16px; height: 16px; margin-left: 26px"
                  />
                  <span>编辑地址</span>
                </p>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </div>
    <a-modal
      v-model:visible="visible"
      title="新增收货地址"
      :footer="null"
      width="508px"
    >
      <a-row style="margin-bottom: 14px">
        <a-col :span="5">
          <span style="color: red">*</span>
          所在地区：
        </a-col>
        <a-col :span="19">
          <XtxCity
            :fullLocation="form.provinces"
            @change="getAddressCode"
            placeholder="请选择所在地区"
          />
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 14px">
        <a-col :span="5">
          <span style="color: red">*</span>
          详细地址：
        </a-col>
        <a-col :span="19">
          <a-input v-model:value="form.address" class="inp" />
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 14px">
        <a-col :span="5">
          <span style="color: red">*</span>
          收件人：
        </a-col>
        <a-col :span="19">
          <a-input v-model:value="form.name" class="inp" />
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 14px">
        <a-col :span="5">
          <span style="color: red">*</span>
          手机号：
        </a-col>
        <a-col :span="19">
          <a-input v-model:value="form.phone" class="inp" />
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 14px">
        <a-col :span="5">电子邮箱：</a-col>
        <a-col :span="19">
          <a-input v-model:value="form.email" class="inp" />
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 14px">
        <a-col :span="4"></a-col>
        <a-col :span="20">
          <a-col :span="20">
            <a-radio @change="change" v-model:checked="checked"
              >设为默认地址</a-radio
            >
          </a-col>
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 14px">
        <a-col :span="4"></a-col>
        <a-col :span="20">
          <a-col :span="20">
            <a-button type="primary" @click="save">保存</a-button>
          </a-col>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from 'vue'
import { addressList, operateAddress, delAddress } from '@/api/user'
import eventBus from '@/utils/eventBus';
import Message from '@/components/library/Message'
import { useRouter, useRoute } from 'vue-router'
export default {
  setup () {
    const visible = ref(false);
    const router = useRouter()
    const route = useRoute()
    const type = ref('')
    const showModal = () => {
      visible.value = true;
      Object.keys(form).forEach(key => {
        form[key] = ''
      })
      checked.value = false
    };
    const checked = ref(false);
    const handleOk = (e) => {
      console.log(e);
      visible.value = false;
    };
    // 后端所需的表单数据
    const form = reactive({
      provinces: '',
      address: '',
      name: '',
      phone: '',
      email: '',
      type: ''
    })
    //判断是否是下订单页跳转过来的
    const isOrderCome=ref(false);
    const List = ref([])
    // 城市选择组件
    const getAddressCode = (address) => {
      console.log(address)
      form.provinces = address.fullLocation
    }
    const GetList = async () => {
      const res = await addressList()
      if (res.code == 0) {
        List.value = res.data
      }
    }
    const save = async () => {
      console.log(form)
      if (!form.provinces) {
        Message({ message: '请选择地区' })
        return
      }
      if (!form.address) {
        Message({ message: '请输入详细地址' })
        return
      }
      if (!form.name) {
        Message({ message: '请输入收件人名称' })
        return
      }
      if (!form.phone) {
        Message({ message: '请输入手机号' })
        return
      }
      const res = await operateAddress(form)
      if (res.code == 0) {
        visible.value = false;
        if(isOrderCome.value==true){
          //返回下单页面
           router.go(-1)
        }else{
          GetList()
        }
        
      }
    }
    const change = (e) => {
      console.log(e)
      form.type = e.target.checked ? 1 : 0
    }
    const Delete = async (id) => {
      const res = await delAddress({
        id
      })
      if (res.code == 0) {
        Message({ type: 'success', message: '删除成功' })
        GetList()
      }
    }
    const redact = (content) => {
      visible.value = true
      Object.assign(form, content);
      console.log(Object.assign(form, content))
      checked.value = form.type == 1 ? true : false
    }
    const changeNewAds = (item) => {
      // router.go(-1)
      // if (type.value == 'choseaddres') {
      //   router.go(-1)
      //   eventBus.emit('getnewAddress',item)
      // } else {
      //   return
      // }
    }
    onMounted(() => {
      GetList()
      console.log('aaa',router.currentRoute.value.query)
      type.value = route.query.type
      let query=router.currentRoute.value.query;
      if(JSON.stringify(query)!="{}"){
          isOrderCome.value=true
      }
    })
    return {
      visible,
      showModal,
      handleOk,
      form,
      getAddressCode,
      GetList,
      save,
      change,
      List,
      Delete,
      redact,
      checked,
      changeNewAds,
      type
    }
  }
}
</script>
<style scoped lang="less">
.address {
  width: 940px;
  min-height: 732px;
  .address-header {
    width: 100%;
    height: 56px;
    background: #ffffff;
    padding: 16px 40px;
    font-size: 16px;
  }
  .address-content {
    width: 100%;
    min-height: 670px;
    background: #ffffff;
    margin-top: 6px;
    padding: 40px;
  }
  .address-add {
    width: 156px;
    height: 32px;
    background: #f8f8f9;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    font-size: 14px;
    color: #808695;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 28px;
  }
  .address-list {
    width: 860px;
    height: 168px;
    border: 1px solid #f1f1f4;
    margin-bottom: 14px;
    padding: 24px;
    color: #515a6e;
    .address-list-header {
      display: flex;
      justify-content: space-between;
    }
  }
}
.inp {
  width: 350px;
  height: 34px;
  border: 1px solid #eeeeee;
  padding-left: 5px;
}
// .xtx-city .select {
//   width: 350px;
// }
</style>