// createRouter           用于创建路由实例
// createWebHashHistory,createWebHistory   用于创建使用hash方式实现路由
import { createRouter,  createWebHashHistory} from 'vue-router';
import store from '@/store';
// 导入一级+二级目录
import CategoryIndex from '@/views/category/index.vue';
import GoodsDetail from '@/views/goods/index.vue';
//购物车
import Cart from '@/views/order/cart.vue';
//订单
import orderDetails from '@/views/order/order-details.vue';
// 提交订单
import successSubmit from '@/views/order/success-submit.vue';
//微信支付页
import WeixinPay from '@/views/order/weixinPay.vue';
//个人中心
import Account from '@/views/account/index.vue';
import orderCenter from '@/views/account/order-center.vue';
import myOrder from '@/views/account/my-order.vue';
import myOrderDetails from '@/views/account/order-details.vue';
import orderRefund from '@/views/account/order-refund.vue';
import orderEvaluate from '@/views/account/order-evaluate.vue';
import myInvoice from '@/views/account/my-invoice.vue';
import myEvaluate from '@/views/account/my-evaluate.vue';
import myWmbeans from '@/views/account/my-wmbeans.vue';
import wmbeansChong from '@/views/account/wmbeans-chong.vue';
import wmbeansRecord from '@/views/account/wmbeans-record.vue';
import myCollect from '@/views/account/my-collect.vue';
import Userinfo from '@/views/account/userinfo.vue';
import Restpwd from '@/views/account/restpwd.vue';
import Paytpwd from '@/views/account/paypwd.vue';
import Bindaccount from '@/views/account/bindaccount.vue';
import Address from '@/views/account/address.vue';
import Invoice from '@/views/account/invoice.vue';
import News from '@/views/account/news.vue';
import newsRecycle from '@/views/account/news-recycle.vue';
import Complaint from '@/views/account/complaint.vue';
import complaintDetails from '@/views/account/complaint-details.vue';
// 导入登录页
import Login from '@/views/login/index.vue';
import LoginCallBack from '@/views/login/callback.vue';
//帮助中心
import HelpCenter from '@/views/helpCenter/index.vue';
import About from '@/views/about/index.vue';
import allFare from '@/views/cost/allfare.vue';
//文本内容
import Content from '@/views/home/content';
//客服
import Service from '@/views/home/service';
const Layout = () => import('@/views/Layout');
const Home = () => import('@/views/home/index');
const routes = [
  { path: '/Login', component: Login },
  { path: '/login/callback', component: LoginCallBack },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '',
        component: Home,
      }, // 首页组件
      { path: '/service', component: Service },
      {
        path: 'category/:id',
        component: CategoryIndex,
      }, // 一级分类页 产品列表
      { path: '/product/:id', component: GoodsDetail }, //产品详情
      { path: '/helpCenter', component: HelpCenter }, //帮助中心
      { path: '/about/:son', component: About }, //关于我们
      { path: '/cost/agency-fare', component: allFare }, //关于我们
      { path: '/account/order-details/:id', component: myOrderDetails }, //订单详情
      { path: '/account/order-refund', component: orderRefund }, //订单退款
      { path: '/account/order-evaluate', component: orderEvaluate }, //订单评价
    ],
  },
  {
    path: '/account/my-order',
    component: Account,
    // redirect: '/account/order-center',
    children: [
      {
        path: '/account/order-center',
        component: orderCenter,
      },
      {
        path: '/account/my-order',
        component: myOrder,
      },
      {
        path: '/account/my-invoice',
        component: myInvoice,
      },
      {
        path: '/account/my-evaluate',
        component: myEvaluate,
      },
      { path: '/account/my-wmbeans', component: myWmbeans },
      { path: '/account/wmbeans-chong', component: wmbeansChong },
      { path: '/account/wmbeans-record', component: wmbeansRecord },
      { path: '/account/collect', component: myCollect },
      { path: '/account/userinfo', component: Userinfo },
      { path: '/account/restpwd', component: Restpwd },
      { path: '/account/paypwd', component: Paytpwd },
      { path: '/account/bindaccount', component: Bindaccount },
      { path: '/account/address', component: Address },
      { path: '/account/invoice', component: Invoice },
      { path: '/account/news', component: News },
      { path: '/account/news-recycle', component: newsRecycle },
      { path: '/account/complaint', component: Complaint },
      { path: '/account/complaint-details/:id', component: complaintDetails },
    ],
  },
  { path: '/order/cart', component: Cart },
  { path: '/order/details', component: orderDetails },
  { path: '/order/submit', component: successSubmit },
  { path: '/order/weixinPay', component: WeixinPay },
  { path: '/content/details/:id/:type', component: Content },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // linkExactActiveClass:'router-link-active'
});
router.afterEach((to, from, next) => {
  // NProgress.done();
  try {
    //设置标题
    if (to.meta.name) {
      document.title = to.meta.name;
    }
  } catch (err) {
    console.log(err);
  }
  let routerList = to.matched;
  console.log(routerList);
  // //目前左边导航选中的active
  // store.commit("SET_CURRENT_MENU", to.name);
});
//导出路由对象
export default router;
