// import { userAccountCheck } from '@/api/user'

// 定义校验规则提供给vee-validate组件使用
export default {
  // 校验account
  async accountApi(value) {
    // 1. 必填
    if (!value) return '请输入用户名';
    // 2. 是否合法
    if (!/^[a-zA-Z]\w{5,19}$/.test(value)) return '字母开头且6-20个字符';
    // 3. 用户名是否已经被注册
    // const { result: { valid } } = await userAccountCheck(value)
    // // console.log(result)
    // if (valid === true) return '用户名已被注册'
    // return true
  },
  // 校验account
  account(value) {
    // value是将来使用该规则的表单元素的值
    // 1. 必填
    if (!value) return '请输入用户名';
    if (!/^[a-zA-Z]\w{5,19}$/.test(value)) return '字母开头且6-20个字符';
    return true;
  },
  password(value) {
    if (!value) return '请输入密码';
    if (!/^\w{6,24}$/.test(value)) return '密码是6-24个字符';
    return true;
  },
  rePassword(value, { form }) {
    if (!value) return '请输入密码';
    if (!/^\w{6,24}$/.test(value)) return '密码是6-24个字符';
    // 🔔 检验两个密码是否一致
    if (value !== form.password) return '两次输入的密码不一致';
    return true;
  },
  mobile(value) {
    if (!value) return '请输入手机号';
    if (!/^1[3-9]\d{9}$/.test(value)) return '手机号格式错误';
    return true;
  },
  email(value) {
    if (!value) return '请输入邮箱';
    if (!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/.test(value))
      return '邮箱格式错误';
    return true;
  },
  code(value) {
    if (!value) return '请输入验证码';
    if (!/^\d{6}$/.test(value)) return '验证码是6个数字';
    return true;
  },
  isAgree(value) {
    if (!value) return '请勾选同意用户协议';
    return true;
  },
};
