<template>
  <!-- 根组件保留一个路由坑位 -->
  <RouterView v-if="isRouterAlive" />
</template>

<script>
import { ref, nextTick, provide } from 'vue'
export default {
  name: 'App',
  setup () {
    const isRouterAlive = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    provide("reload", reload);
    return {
      isRouterAlive,
      reload
    }
  }
}
</script>

