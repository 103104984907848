<template>
  <div class="xtx-carousel" @mouseenter="stop" @mouseleave="start">
    <!-- 轮播图主体 -->
    <ul class="carousel-body">
      <li
        class="carousel-item"
        v-for="(item, index) in sliders"
        :key="item.id"
        :class="{ fade: activeIndex === index }"
      >
        <!-- 情况1：普通轮播图 -->
        <RouterLink v-if="item.imgUrl" :to="item.hrefUrl">
          <img :src="item.imgUrl" style="width: 136px; height: 96px" />
        </RouterLink>
        <!-- 情况2：同类推荐和猜你喜欢 -->
        <div v-else class="slider">
          <RouterLink
            v-for="goods in item"
            :key="goods.id"
            :to="`/product/${goods.id}`"
          >
            <img :src="goods.picture" alt="" />
            <p class="name ellipsis">{{ goods.name }}</p>
            <p class="price">&yen;{{ goods.price }}</p>
          </RouterLink>
        </div>
      </li>
    </ul>
    <!-- 左右箭头 -->
    <a @click="toggle(-1)" href="javascript:;" class="carousel-btn prev">
      <i class="iconfont icon-angle-left"></i>
    </a>
    <a @click="toggle(1)" href="javascript:;" class="carousel-btn next">
      <i class="iconfont icon-angle-right"></i>
    </a>
    <!-- 底部控制器 -->
    <div class="carousel-indicator">
      <span
        v-for="(item, index) in sliders"
        :key="item.id"
        @click="activeIndex = index"
        :class="{ active: activeIndex === index }"
      ></span>
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref, watch } from 'vue'
export default {
  name: 'XtxCarousel',
  props: {
    sliders: {
      type: Array,
      default: () => []
    },
    // 是否自动轮播
    autoPlay: {
      type: Boolean,
      default: false
    },
    // 定时切换时间
    duration: {
      type: Number,
      default: 3000
    }
  },
  setup (props) {
    // 下标，用于控制指示器高亮和显示那张轮播图的类名绑定
    const activeIndex = ref(0)

    // 左右箭头换图函数
    const toggle = step => {
      // 准备一个临时变量
      const temp = activeIndex.value + step
      // 处理左右边界问题
      if (temp < 0) {
        // 左边是否小于 0
        activeIndex.value = props.sliders.length - 1
      } else if (temp > props.sliders.length - 1) {
        // 右边是否超过最大的下标
        activeIndex.value = 0
      } else {
        // 普通情况
        activeIndex.value = temp
      }
    }
    // 存放定时器的变量
    let timerId = null
    // 自动播放函数
    const autoPlayFn = () => {
      // 清理旧的定时器，防止定时器叠加
      clearInterval(timerId)
      // 启动定时器
      timerId = setInterval(() => {
        // console.log('定时器在运行中，组件卸载需要停止....')
        // 下标加一实现切换
        toggle(1)
      }, props.duration)
    }

    watch(
      () => props.sliders,
      val => {
        // 超过一张图片 并且 传递了 autoPlay 开启自动播放，才调用自动播放函数
        if (val.length > 1 && props.autoPlay) {
          autoPlayFn()
        }
      },
      { immediate: true }
    )
    // 组件卸载，需要清理定时器
    onUnmounted(() => {
      clearInterval(timerId)
    })
    // 鼠标移入停止播放
    const stop = () => {
      clearInterval(timerId)
    }

    // 鼠标移出继续播放
    const start = () => {
      if (props.sliders.length > 1 && props.autoPlay) {
        autoPlayFn()
      }
    }

    return { activeIndex, toggle, stop, start }
  }
}
</script>

<style scoped lang="less">
.xtx-carousel {
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 150px;
  position: relative;
  .carousel {
    &-body {
      width: 100%;
      height: 100%;
    }
    &-item {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: opacity 0.5s linear;
      &.fade {
        opacity: 1;
        z-index: 1;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-indicator {
      position: absolute;
      left: 0;
      bottom: 20px;
      z-index: 2;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        cursor: pointer;
        ~ span {
          margin-left: 12px;
        }
        &.active {
          background: #fff;
        }
      }
    }
    &-btn {
      width: 44px;
      height: 44px;
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 228px;
      z-index: 2;
      text-align: center;
      line-height: 44px;
      opacity: 0;
      transition: all 0.5s;
      &.prev {
        left: 20px;
      }
      &.next {
        right: 20px;
      }
    }
  }
  &:hover {
    .carousel-btn {
      opacity: 1;
    }
  }
  // 轮播产品
  .slider {
    display: flex;
    justify-content: space-around;
    padding: 0 40px;
    > a {
      width: 240px;
      text-align: center;
      img {
        padding: 20px;
        width: 230px !important;
        height: 230px !important;
      }
      .name {
        font-size: 16px;
        color: #666;
        padding: 0 40px;
      }
      .price {
        font-size: 16px;
        color: @priceColor;
        margin-top: 15px;
      }
    }
  }
}
</style>
