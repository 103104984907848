/* // 扩展vue原有的功能：全局组件，自定义指令，挂载原型方法，注意点：Vue3没有全局过滤器。
import XtxSkeleton from './xtx-skeleton'
// 全局注册轮播图
import XtxCarousel from './xtx-carousel.vue'
// 面板封装
import XtxMore from './xtx-more.vue'
// 封装面包屑
import XtxBread from './xtx-bread.vue'
import XtxBreadItem from './xtx-bread-item.vue' */

import { useIntersectionObserver } from '@vueuse/core'
// import defaultImg from '@/assets/images/200.png'
import Message from './Message'
import Confirm from './Confirm'

/*
  知识点：require.context() 自动导入和注册组件
    参数：
      1. 目录  2. 是否加载子目录  3. 加载的正则匹配
    返回值：
      返回一个导入函数
        .keys() 获取读取到的所有文件列表
*/
const importFn = require.context('./', false, /\.vue$/)
// console.log('查看匹配到的文件名数组', importFn.keys()) // 匹配到的文件名数组

// Vue2 全局注册组件
// Vue.component('组件名', 组件)

// Vue3 全局注册组件
// app.component('组件名', 组件)

// 指令的封装放到这个函数内
const defineDirective = (app) => {
  // 🔔 调用指令通过 v-src="xxx" 调用
  app.directive('src', {
    mounted (target, { value }) {
      const { stop } = useIntersectionObserver(
        target,
        ([{ isIntersecting }]) => {
          if (isIntersecting) {
            // console.log('图片可见设置图片src', isIntersecting, target, value)
            // 图片分区进入可视区，设置图片的 src
            target.src = value
            // 如果图片加载失败，设置默认图
            target.onerror = () => {
              // target.src = defaultImg
            }
            stop()
          }
        },
        // 🔔优化： 0 元素刚进入可视区触发，1 表示元素完整进入可视区才触发
        { threshold: [0] }
      )
    }
  })
}

// 导出一个配置，用于 app.use() 安装组件库使用
export default {
  install (app) {
    // Vue3 全局注册组件
    /*  app.component(XtxSkeleton.name, XtxSkeleton)
    app.component(XtxCarousel.name, XtxCarousel)
    app.component(XtxMore.name, XtxMore)
    app.component(XtxBread.name, XtxBread)
    app.component(XtxBreadItem.name, XtxBreadItem) */
    // 遍历文件名数组
    importFn.keys().forEach(item => {
      // 导入函数根据文件名，导入文件内容
      const component = importFn(item).default
      // console.log(component)
      // 根据导入的组件文件，实现自动全局注册
      app.component(component.name, component)
    })
    // 注册指令
    defineDirective(app)

    // Vue2 添加全局方法：Vue.prototype.$message = xxx
    // Vue3 添加全局方法：app.config.globalProperties.$message = xxx
    app.config.globalProperties.$message = Message
    app.config.globalProperties.$confirm = Confirm
  }
}

// 安装组件结构
// app.use({
//   install (app) {
//     安装组件业务代码
//   }
// })
