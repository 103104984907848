<template>
  <!-- 
qq登录
未绑定   带有openid
http://www.wanmou.top/#/Login?openid=0E48FDB71E465E3B9666D6EF9DE9B69E&state=qq&is_bind=2
已绑定的    带有token
http://www.wanmou.top/#/Login?token=0E48FDB71E465E3B9666D6EF9DE9B69E&state=qq&is_bind=1
 -->
  <div class="account-box" v-if="activeName == 'register'">
    <!-- 登录 -->
    <div class="form">
      <div class="form-item">
        <div class="input">
          <a-input placeholder="请输入手机号" v-model:value="regForm.account" />
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <a-input v-model:value="regForm.code" placeholder="请输入验证码" />
          <span class="code" @click="sendCodeBtn(1, regForm.account)">
            {{ number === 0 ? "发送验证码" : `${number}秒后发送` }}
          </span>
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <a-input placeholder="请输入密码" v-model:value="regForm.password" />
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <a-input placeholder="请再次输入密码" v-model:value="regForm.repassword" />
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <a-input placeholder="请输入图形验证码" v-model:value="regForm.picCode" />
          <span class="code" @click="refreshCode">
            <security :identifyCode="identifyCode"></security>
          </span>
        </div>
      </div>
      <a-button type="primary" style="width: 100%" @click="goRegister">
        注册
      </a-button>
      <p style="font-size: 14px; margin-top: 15px; text-align: center">
        <span>已有账号，</span>
        <a style="color: #296df1" @click="goLogin">去登录</a>
      </p>
    </div>
  </div>
  <div class="account-box" v-else>
    <template v-if="activeName === 'accountLogin'">
      <div class="form-item">
        <div class="input">
          <a-input v-model:value="form.account" placeholder="请输入您的手机号" />
        </div>
      </div>
      <div class="form-item">
        <div class="input" style="display: flex; align-items: center">
          <input type="text" class="form-control" v-model="form.pass" v-if="showPassword" placeholder="请输入密码" />
          <input type="password" class="form-control" v-model="form.pass" v-else placeholder="请输入密码" />
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <a-input placeholder="请输入图形验证码" v-model:value="form.picCode" />
          <span class="code" @click="refreshCode">
            <security :identifyCode="identifyCode"></security>
          </span>
        </div>
      </div>
    </template>
    <!-- 使用短信登录的表单 -->
    <template v-if="activeName === 'otherLogin'">
      <div class="form-item">
        <div class="input">
          <a-input v-model:value="form.phone" placeholder="请输入您的手机号" />
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <a-input placeholder="请输入图形验证码" v-model="form.picCode" />
          <span class="code" @click="refreshCode">
            <security :identifyCode="identifyCode"></security>
          </span>
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <a-input v-model:value="form.code" placeholder="请输入验证码" />
          <span class="code" @click="sendCodeBtn(3, form.phone)">
            {{ number === 0 ? "发送验证码" : `${number}秒后发送` }}
          </span>
        </div>
      </div>
    </template>
    <a-button type="primary" style="width: 100%" @click="loginBtn">
      登录
    </a-button>
    <div class="action">
      <div class="url">
        <router-link to="/login/callback">忘记密码</router-link>
        <a style="color: #296df1" @click="register">立即注册</a>
      </div>
    </div>
    <div class="otherLogin">
      <div class="header">
        <span class="left-line"></span>
        <span>其他方式登录</span>
        <span class="left-line"></span>
      </div>
      <div class="otherLogin_son">
        <div>
          <!-- <img src="@/assets/images/03_qq_symbol.png" alt="" srcset="" class="otherLogin_img" @click="toQQ" /> -->
          <img src="@/assets/images/wchat@2x.png" alt="" class="otherLogin_img" @click="weixinLogin" />
          <!-- TODO 暂时隐藏 -->
          <!-- <img src="@/assets/images/alipay@2x.png" alt="" class="otherLogin_img" @click="aliPayLogin" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, watch } from "vue";
import schema from "@/utils/vee-validate-schema";
import {
  userAccountRegister,
  userAccountLogin,
  userPhoneLogin,
  aliLogin,
  userInfo,
  sendCode,
  qqLogin,
  qqNotify,
} from "@/api/user";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Message from "@/components/library/Message";
import security from "./identify.vue";
import { useIntervalFn } from "@vueuse/shared";
export default {
  name: "LoginForm",
  props: {
    activeName: {
      type: String,
      default: "",
    },
  },
  components: { security },
  setup(props, { emit }) {
    console.log(props.activeName);
    // 用于手机表单数据的响应式对象
    const form = reactive({
      account: null,
      pass: null,
      phone: null,
      code: null,
      picCode: null,
    });
    const regForm = reactive({
      account: null,
      password: null,
      repassword: null,
      code: null,
      picCode: null,
    });
    const hidePass = ref("");
    const Timer = ref(null);

    const showPassword = ref(false);
    const identifyCode = ref("");
    const identifyCodes = ref("1234567890abcdef");
    const formCom = ref(null);
    const store = useStore();
    // router 路由器（管理路由的-老大）
    const router = useRouter();
    // route 路由 (小弟)
    const route = useRoute();
    const appid = ref("wxc24c8f1359a8eb35");
    const redirectUri = ref("http%3A%2F%2Fwww.wanmou.top%2F%23%2FLogin");
    const loginBtn = async () => {
      try {
        let res;
        if (props.activeName === "accountLogin") {
          // 情况1：账号密码登录
          res = await userAccountLogin(form);
        } else {
          // 情况2：手机号码+验证码登录
          res = await userPhoneLogin(form);
        }
        console.log(res.data);
        if (res.code == 0) {
          // 1. 存储 token 信息
          store.commit("user/setToken", res.data.token);
          const userinfo = await userInfo();
          store.commit("user/setUser", userinfo.data);
          // 2. 页面跳转（两种情况：1.回跳到某个页面 2.跳转首页）
          // router.currentRoute.value 获取当前路由
          console.log('router', router.currentRoute.value)
          const redirectUrl = router.currentRoute.value.query.redirectUrl;
          console.log('redirectUrl', redirectUrl);
          //router.push(redirectUrl || "/");
          router.push("/");
          // 成功提示
          Message({ type: "success", message: "登录成功" });
        }
      } catch (err) {
        // 失败提示
        Message({ type: "error", message: err.response.data.message });
      }
    };

    const number = ref(0);
    const { resume, pause } = useIntervalFn(
      () => {
        number.value--;
        if (number.value <= 0) {
          pause();
        }
      },
      1000,
      { immediate: false }
    );

    const sendCodeBtn = async (type, phone) => {
      // 校验手机号码 form.mobile
      let mobileChecked = "";
      if (type == 1) {
        mobileChecked = schema.mobile(regForm.account);
      } else {
        mobileChecked = schema.mobile(form.phone);
      }
      // console.log(res)
      if (mobileChecked === true) {
        // 🔔上一个倒计时没有结束，直接退出
        if (number.value !== 0) return;
        try {
          const res = await sendCode({
            phone: phone,
            type: type,
          });
          if (res.code == 0) {
            Message({ type: "success", message: "验证码发送成功" });
            // 倒计时秒数重置
            number.value = 60;
            // 调用倒计时
            resume();
            // 发送验证码
          }
        } catch (err) {
          console.log(err.response);
          Message({ type: "error", message: "验证码已发送，稍后再试" });
        }
      } else {
        Message({ type: "error", message: mobileChecked });
      }
    };
    const register = () => {
      console.log("ppppp");
      emit("changeStatus", "register");
    };
    /**
     * 注册
     */
    const goRegister = async () => {
      if (!regForm.account) {
        Message({ message: "请输入手机号" });
        return;
      }
      console.log(schema.mobile(regForm.account));
      if (schema.mobile(regForm.account) != true) {
        Message({ type: "error", message: "请输入正确手机号" });
        return;
      }
      if (!regForm.password) {
        Message({ message: "请输入密码" });
        return;
      }
      if (!regForm.repassword) {
        Message({ message: "请再次输入密码" });
        return;
      }
      if (regForm.repassword != regForm.password) {
        Message({ type: "error", message: "两次密码输入不一致" });
        return;
      }
      if (!regForm.picCode) {
        Message({ message: "请输入图形验证码" });
        return;
      }
      if (regForm.picCode != identifyCode.value) {
        Message({ type: "error", message: "图形验证码输入有误" });
        return;
      }
      const res = await userAccountRegister(regForm);
      console.log(res);
      if (res.code == 0) {
        Message({ type: "success", message: "注册成功,请登录" });
        emit("goLogin", "");
      }
    };
    onMounted(() => {
      identifyCode.value = "";
      makeCode(identifyCodes.value, 4);
      // console.log("onMounted", router.currentRoute.value.query);
      // if (Timer.value) {
      //   clearInterval(Timer.value);
      //   Timer.value = null;
      // } else {
      //   Timer.value = setInterval(() => {
      //     checkQQState();
      //   }, 3000);
      //   console.log("Timer.value", Timer.value);
      // }
    });
    // 验证码处理
    const randomNum = (min, max) => {
      return Math.floor(Math.random() * (max - min) + min);
    };
    // 刷新验证码
    const refreshCode = () => {
      identifyCode.value = "";
      makeCode(identifyCodes.value, 4);
    };
    const makeCode = (o, l) => {
      for (let i = 0; i < l; i++) {
        identifyCode.value +=
          identifyCodes.value[randomNum(0, identifyCodes.value.length)];
      }
      console.log(identifyCode.value);
    };
    const goLogin = () => {
      emit("goLogin", "");
    };
    const aliPayLogin = async () => {
      // 如果有ali返回的code
      if (
        router.currentRoute.value.query &&
        router.currentRoute.value.query.auth_code &&
        router.currentRoute.value.query.ali_state
      ) {
        return;
      }
      const res = await aliLogin();
      console.log("----res---", res);
      if (res.code == 0) {
        const url = res.url;
        window.open(url, "_blank");
      }
    };

    const toQQ = async () => {
      // //以下为按钮点击事件的逻辑。注意这里要重新打开窗口
      // //否则后面跳转到QQ登录，授权页面时会直接缩小当前浏览器的窗口，而不是打开新窗口
      window.open("http://www.admin.wanmou.top/api/User/qqAuth", "_self");
    };

    const checkQQState = async () => {
      const res = await qqLogin();
      console.log("--res", res);
      if (res.code == 0) {
        // 1 一直请求,非1 clear
        if (res.is_continue && res.is_continue == 1) {
          console.log(12);
        } else {
          clearInterval(Timer.value);
          Timer.value = null;
        }
      } else {
        Message({ type: "error", message: "请求失败" });
      }
    };
    const weixinLogin = () => {
      const url = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid.value}&redirect_uri=${redirectUri.value}&response_type=code&scope=snsapi_login&state=weixin#wechat_redirect`;
      window.open(url, "_blank");
    };
    return {
      aliPayLogin,
      Timer,
      schema,
      form,
      regForm,
      formCom,
      loginBtn,
      sendCodeBtn,
      number,
      register,
      status,
      identifyCode,
      identifyCodes,
      randomNum,
      refreshCode,
      makeCode,
      goLogin,
      goRegister,
      hidePass,
      showPassword,
      weixinLogin,
      appid,
      redirectUri,
      toQQ,
      checkQQState,
    };
  },
};
</script>

<style lang="less" scoped>
// 账号容器
.account-box {
  width: 100%;
  padding: 24px 50px;

  .toggle {
    text-align: right;

    a {
      color: @xtxColor;

      i {
        font-size: 14px;
      }
    }
  }

  .form {
    width: 100%;

    &-item {
      width: 100%;
      margin-bottom: 16px;

      .input {
        width: 100%;
        position: relative;
        height: 38px;

        >i {
          width: 34px;
          height: 34px;
          background: #cfcdcd;
          color: #fff;
          position: absolute;
          left: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 18px;
        }

        input {
          padding-left: 10px;
          border: 1px solid #cfcdcd;
          height: 36px;
          line-height: 36px;
          width: 100%;

          &.error {
            border-color: @priceColor;
          }

          &.active,
          &:focus {
            border-color: @xtxColor;
          }
        }

        .code {
          position: absolute;
          right: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 14px;
          background: #f5f5f5;
          color: #666;
          width: 90px;
          height: 34px;
          cursor: pointer;
        }
      }

      >.error {
        position: absolute;
        font-size: 12px;
        line-height: 28px;
        color: @priceColor;

        i {
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }

    .agree {
      a {
        color: #069;
      }
    }

    .btn {
      display: block;
      width: 100%;
      height: 40px;
      color: #fff;
      text-align: center;
      line-height: 40px;
      background: @xtxColor;

      &.disabled {
        background: #cfcdcd;
      }
    }
  }

  .action {
    width: 100%;
    padding: 13px 0;
    margin: 0;

    .url {
      width: 100%;
      display: flex;
      justify-content: space-between;

      a {
        color: #999;
      }
    }
  }
}

.otherLogin {
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 12px;
      color: #808695;
    }

    .left-line {
      display: block;
      width: 105px;
      height: 1px;
      background: #eeeeee;
    }
  }

  .otherLogin_son {
    margin-top: 14px;
    display: flex;
    justify-content: center;

    // justify-content: space-between;
    .otherLogin_img {
      // width: 20px;
      // height: 20px;
      width: 20px;
      // height: 40px;
      margin-right: 29px;
    }

    .otherLogin_img:last-child {
      margin: 0;
    }
  }
}
</style>
