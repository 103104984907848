<template>
  <div class="invoice">
    <div class="invoice_header">我的发票</div>
    <div class="invoice_content">
      <div class="list_header">
        <div class="list_content1" style="justify-content: center">
          订单详情
        </div>
        <div class="list_content2">发票类型</div>
        <div class="list_content2">状态</div>
        <div class="list_content2">操作</div>
      </div>
      <div class="list_content">
        <div class="list_content_header">
          <span style="margin-right: 40px">下单时间：2021-09-02 16:51:30</span>
          <span>订单号：4540527485410</span>
        </div>
        <div class="list_content_details">
          <div class="list_content1 border-right">
            <div style="display: flex">
              <img src="" alt="" srcset="" class="details_img" />
              <div>
                <p>
                  <span
                    style="font-weight: 500; color: #17233d; margin-right: 50px"
                    >合同纠纷起诉服务</span
                  ><span>￥599.00 x1</span>
                </p>
                <span>服务1</span>
              </div>
            </div>
          </div>
          <div class="list_content2 border-right" style="font-size: 14px">
            普通发票
          </div>
          <div class="list_content2 border-right" style="color: #ff3c31">
            正在处理
          </div>
          <div class="list_content2">
            <p class="btn">查看详情</p>
            <!-- <p class="btn">查看发票</p> -->
          </div>
        </div>
      </div>
      <XtxPagination
        v-if="total"
        :total="total"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="getCurrentPage"
      />
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { billInfo } from '@/api/user'
export default {
  components: {},
  setup () {
    // 评论总条数
    const total = ref(10)
    // 当前页码
    const currentPage = ref(1)
    //一页几条
    const pageSize = ref(4)
    const getCurrentPage = val => {
      console.log('当前子组件的页面为', val)
      currentPage.value = val
      // getList()
    }
    const billList = async () => {
      const res = await billInfo()
    }
    onMounted(() => {
      billList()
    })
    return { total, currentPage, pageSize, getCurrentPage, billList }
  }
}
</script>
<style scoped lang="less">
.invoice {
  width: 940px;
  min-height: 642px;
  .invoice_header {
    width: 100%;
    height: 56px;
    background: #ffffff;
    padding: 16px 40px;
    font-size: 16px;
  }
  .invoice_content {
    width: 100%;
    min-height: 642px;
    background: #ffffff;
    margin-top: 6px;
    padding: 30px 40px;
  }
}
.list_header {
  width: 100%;
  height: 39px;
  background: #f8f8f9;
  border: 1px solid #f1f1f4;
  color: #515a6e;
  display: flex;
}
.list_content {
  width: 100%;
  min-height: 39px;
  margin-top: 11px;
  color: #515a6e;
  font-size: 12px;
  .list_content_header {
    width: 100%;
    height: 39px;
    background: #f8f8f9;
    border: 1px solid #f1f1f4;
    display: flex;
    align-items: center;
    padding: 0 21px;
  }
  .list_content_details {
    width: 100%;
    height: 101px;
    border: 1px solid #f1f1f4;
    border-top: none;
    display: flex;
    .details_img {
      width: 80px;
      height: 60px;
      border: 1px solid red;
      margin-left: 21px;
      margin-right: 10px;
    }
    .btn {
      width: 72px;
      height: 26px;
      background: #f8f8f9;
      border: 1px solid #f1f1f4;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tag {
      display: block;
      width: 116px;
      height: 18px;
      background: rgb(230, 249, 237);
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #00bc4b;
      font-size: 10px;
      img {
        width: 9px;
        height: 9px;
      }
    }
  }
}
.list_content1 {
  width: 453px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.list_content2 {
  width: 130px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
}
.border-right {
  border-right: 1px solid #f1f1f4;
}
</style>