<template>
  <div class="userinfo">
    <div class="userinfo-header">个人信息</div>
    <div class="userinfo-content">
      <a-form
        style="width: 80%"
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item ref="name" label="昵称：" name="nickname">
          <a-input
            v-model:value="formState.nickname"
            style="
              border: 1px solid #d9d9d9;
              height: 30px;
              width: 282px;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="性别：" name="base_sex">
          <a-radio-group
            v-model:value="formState.base_sex"
            @change="radioChange"
          >
            <a-radio :value="1">男</a-radio>
            <a-radio :value="2">女</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="生日：">
          <a-date-picker
            :locale="locale"
            v-model:value="formState.birthday"
            style="width: 282px"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 2 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
      <div>
        <a-upload
          name="avatar"
          listType="picture-card"
          cstyle="width: 104px; height: 104px"
          :showUploadList="false"
          :customRequest="selfUpload"
          ref="upload"
        >
          <img
            v-if="formState.headimg"
            :src="formState.headimg"
            alt="avatar"
            style="width: 104px; height: 104px"
            @click="showImgShade"
          />
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">修改头像</div>
          </div>
          <!-- <span class="delete-img" @click="deleteImg" v-if="mainImg">x</span> -->
        </a-upload>
        <!-- <img
          src="@/assets/images/Frame341@2x.png"
          alt=""
          srcset=""
          style="width: 104px; height: 104px"
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { reactive, ref, toRaw, onMounted } from 'vue'
import { editUserInfo, uploadImg, userInfo } from '@/api/user'
import Message from '@/components/library/Message'
import { useStore } from 'vuex'
export default {
  components: {},
  setup () {
    const formRef = ref();
    const store = useStore()
    const formState = reactive({
      nickname: '',
      base_sex: '',
      headimg: '',
      birthday: ''
    });
    const value1 = ref('')
    const rules = {
      nickname: [
        { required: true, message: '请输入昵称' },
      ],
      base_sex: [{ required: true, message: '请选择性别' }]
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          console.log('values', formState, toRaw(formState));
          const res = await editUserInfo(formState)
          if (res.code == 0) {
            Message({ type: 'success', message: '提交成功' })
            formRef.value.resetFields()
            getUserinfo()
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    const selfUpload = ({ action, file, onSuccess, onError, onProgress }) => {
      const base64 = new Promise(resolve => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result)
          // console.log(fileReader.result)
          upload(fileReader.result)
        }
      })
    }
    const upload = async (img) => {
      console.log(img)
      const res = await uploadImg({
        headimg: img
      })
      formState.headimg = res.data.headimage
    }
    const getUserinfo = async () => {
      const res = await userInfo()
      store.commit('user/setUser', res.data)
      Object.assign(formState, res.data)
      formState.base_sex = parseInt(formState.base_sex)
      console.log(formState)
    }
    const radioChange = (e) => {
      console.log(e)
      formState.base_sex = e.target.value
    }
    onMounted(() => {
      getUserinfo()
    })
    return {
      formRef,
      formState,
      rules,
      onSubmit,
      labelCol: { span: 2 },
      wrapperCol: { span: 14 },
      selfUpload,
      upload,
      value1,
      locale,
      radioChange
    }
  }
}
</script>
<style scoped lang="less">
.userinfo {
  width: 940px;
  min-height: 732px;
  .userinfo-header {
    width: 100%;
    height: 56px;
    background: #ffffff;
    padding: 16px 40px;
    font-size: 16px;
  }
  .userinfo-content {
    width: 100%;
    min-height: 670px;
    background: #ffffff;
    margin-top: 6px;
    padding: 43px 87px 0 54px;
    display: flex;
    justify-content: space-between;
  }
}
.amendAvatar {
  width: 112px;
  height: 30px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
</style>