<template>
  <div class="xtx-city" ref="target">
    <div class="select" @click="toggle">
      <span class="value" v-if="fullLocation">{{fullLocation}}</span>
      <span class="placeholder" v-else>请选择配送地址</span>
      <i class="iconfont icon-angle-down"></i>
    </div>
    <div class="option" v-show="isShow">
      <div class="loading" v-if="!currList.length"></div>
      <template v-else>
        <span
        class="ellipsis"
        v-for="item in currList"
        :key="item.code"
        @click="changeItem(item)"
        >
          {{ item.name }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { onClickOutside } from '@vueuse/core'
import { computed, reactive, ref } from 'vue'
import axios from 'axios'

// 获取城市信息
const getCityData = () => {
  // Promise 对象的 resolve 可以把值传出去
  return new Promise((resolve) => {
    // 情况1:如果有内存缓存，返回缓存的数据即可
    if (window.cityData) {
      // 传递数据出去
      resolve(window.cityData)
    } else {
      // 没有缓存，获取城市列表信息
      const url = 'https://yjy-oss-files.oss-cn-zhangjiakou.aliyuncs.com/tuxian/area.json'
      // 发送请求获取城市信息
      axios.get(url).then(({ data }) => {
        // 存一份到内存中缓存起来
        window.cityData = data
        // 传递数据出去
        resolve(window.cityData)
      })
    }
  })
}

export default {
  name: 'XtxCity',
  // 接收用户默认收货地址
  props: {
    // 中文收货地址，包含省市区(县)
    fullLocation: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const target = ref(null)
    const isShow = ref(false)
    // const isLoading = ref(false)
    // onClickOutside(目标元素，事件处理函数)

    const cityList = ref([])
    const show = async () => {
      isShow.value = true
      // 后面还有其他业务
      // 盒子显示的时候获取城市信息，展示到页面中
      //   isLoading.value = true
      const data = await getCityData()
      //   isLoading.value = false
      // console.log('打开的时候获取到Promise对象里面的数据', data)
      cityList.value = data
      // 每次打开的时候都重新收集数据
      for (const key in address) {
        address[key] = ''
      }
    }
    //   用于在页面中渲染出来的列表
    const currList = computed(() => {
      // 默认情况下，是展示省份列表
      let list = cityList.value
      // 如果用户已选定了省份编码，计算出需要展示的城市列表
      if (address.provinceCode) {
        list = cityList.value.find(item => item.code === address.provinceCode).areaList
      }
      // 如果用户选定了城市编码，计算出区(县)的列表
      if (address.cityCode) {
        list = list.find(item => item.code === address.cityCode).areaList
      }

      return list
    })

    const hide = () => {
      isShow.value = false
      // 后面还有其他业务
      //   盒子显示的时候获取城市的信息，展示到页面中
      getCityData().then(res => {
        // console.log('打开的时候获取到Promis 对象里面的数据', res)
      })
    }

    const toggle = () => {
      isShow.value ? hide() : show()
    }
    // 点击目标元素之外
    onClickOutside(target, () => {
      // console.log('你点击了元素外')
      hide()
    })

    const address = reactive({
      fullLocation: '',
      provinceCode: '', // 省编码(后台需要)
      provinceName: '', // 省名称(显示需要)
      cityCode: '',
      cityName: '',
      countyCode: '',
      countyName: ''
    })
    // 点击城市列表的时候，根据不同的 level 收集不同的地址信息
    const changeItem = (theItem) => {
      // console.log(theItem)
      if (theItem.level === 0) {
        // 收集省数据
        address.provinceCode = theItem.code
        address.provinceName = theItem.name
      } else if (theItem.level === 1) {
        // 收集市数据
        address.cityCode = theItem.code
        address.cityName = theItem.name
      } else if (theItem.level === 2) {
        // 收集区(县)数据
        address.countyCode = theItem.code
        address.countyName = theItem.name
        // 收集完区(县)的数据后，面板就可以隐藏起来
        hide()
        // 拼接地址信息
        address.fullLocation = `${address.provinceName} ${address.cityName} ${address.countyName}`
        // 父组件可以通过自定义事件获取到地址信息
        emit('change', address)
      }
    }

    return { target, isShow, toggle, currList, address, changeItem }
  }
}
</script>

<style scoped lang="less">
.xtx-city {
  display: inline-block;
  position: relative;
  z-index: 400;
  .select {
    width: 350px;
    border: 1px solid #e4e4e4;
    height: 30px;
    padding: 0 5px;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &.active {
      background: #fff;
    }
    .placeholder {
      color: #999;
    }
    .value {
      color: #666;
      font-size: 12px;
    }
    i {
      font-size: 12px;
      margin-left: 5px;
    }
  }
  .option {
    width: 542px;
    border: 1px solid #e4e4e4;
    position: absolute;
    left: 0;
    top: 29px;
    background: #fff;
    min-height: 30px;
    line-height: 30px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
     .loading {
      height: 290px;
      width: 100%;
      // background: url(~@/assets/images/loading.gif) no-repeat center;
    }
    > span {
      width: 130px;
      text-align: center;
      cursor: pointer;
      border-radius: 4px;
      padding: 0 3px;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
}
</style>
