// 预期需求：
// Confirm({ title: '标题', text: '您确定从购物车删除选中的产品吗？' }).then(() => {
//   store.dispatch('cart/batchDeleteCart')
// }).catch(e => {})
import { createVNode, render } from 'vue'
import XtxConfirm from './xtx-confirm.vue'
// console.log(XtxConfirm)

// 准备一个用于放自定义组件的容器
const vNodeDiv = createVNode('div', { class: 'xtx-confirm-container' })
render(vNodeDiv, document.body)
// 获取 DOM 节点，用于挂载组件或卸载组件
const container = vNodeDiv.el

const Confirm = ({ title, text }) => {
  // 返回 Promise 对象
  return new Promise((resolve, reject) => {
    const confirmCallback = () => {
      resolve('点击确认')
      render(null, container)
    }

    const cancelCallBack = () => {
      reject(new Error('点击取消'))
      render(null, container)
    }
    // 动态创建组件
    const vNode = createVNode(XtxConfirm, { title, text, confirmCallback, cancelCallBack })
    render(vNode, container)
  })
}

export default Confirm
