<template>
  <div>
    <!-- 购物车顶部 -->
    <cartHeader title="购物车" />
    <div style="width: 100%; min-height: 500px">
      <div class="carInfo">
        <div class="carList">
          <div class="cart">
            <table>
              <thead>
                <tr style="text-align: center">
                  <th width="140">
                    <a-checkbox v-model:checked="checkall" @change="changeall"
                      >全选</a-checkbox
                    >
                  </th>
                  <th width="250">产品名称</th>
                  <th width="150">单价(元)</th>
                  <th width="200">数量</th>
                  <th width="180">小计</th>
                  <th width="140">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in carList" :key="item.skuId">
                  <td>
                    <a-checkbox
                      v-model:checked="item.is_checked"
                      @change="change($event, item)"
                    ></a-checkbox>
                  </td>
                  <td>
                    <div class="goods">
                      <!-- <RouterLink :to="`/product/${item.id}`"> -->
                      <img :src="item.slider" alt="" />
                      <!-- </RouterLink> -->
                      <div>
                        <p class="name">{{ item.goods_name }}</p>
                        <p class="name">{{ item.goods_spec }}</p>
                      </div>
                    </div>
                  </td>
                  <td class="tc">
                    <p>{{ item.price }}</p>
                  </td>
                  <td class="tc">
                    <XtxNumbox
                      isreadonly="true"
                      :modelValue="item.number"
                      @minus="minusNum($event, item)"
                      @add="addNumer($event, item)"
                    />
                  </td>
                  <td class="tc">
                    <p class="f16">
                      {{ (item.price * item.number).toFixed(2) }}
                    </p>
                  </td>
                  <td class="tc">
                    <p>
                      <a
                        href="javascript:;"
                        @click="deleteCart(item.id)"
                        style="color: #808695"
                        >删除</a
                      >
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- 操作栏 -->
      <div style="width: 100%">
        <div class="action">
          <div class="batch">共 {{ carList.length }}件产品</div>
          <div class="total">
            合计：
            <span class="red">¥ {{ totalPrice }} </span>
            <XtxButton type="primary" @click="goCheckout">立即结算</XtxButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal />
  <AppFooter />
</template>
<script>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import cartHeader from './components/cart-header.vue'
import AppFooter from '@/components/app-footer.vue'
import Modal from '@/components/modal.vue'
import { getCartList, editCartNumber, editCartChecked, delCart } from '@/api/user'
import Message from '@/components/library/Message'
import { useStore } from 'vuex'
export default {
  components: { cartHeader, AppFooter, Modal },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const carList = ref([])
    const checkall = ref(false)
    const cart_list = ref([])
    const totalPrice = ref('')
    const checkly = ref(0)
    const goCheckout = () => {
      // const data = {
      //   id: goodsId.value,
      //   skuId: skuId.value,
      //   count: count.value,
      //   type: 'car'
      // }
      const selectedTotal = []
      const data = []
      carList.value.forEach(element => {
        if (element.is_checked == 1) {
          selectedTotal.push(element)
          data.push({
            id: element.goods_id,
            cartid: element.id,
            skuId: element.sku_id,
            count: element.number,
          })
        }
      });
      if (selectedTotal.length == 0) return Message({ message: '至少选中一件产品' })
      router.push({
        path: '/order/details', query: { data: encodeURIComponent(JSON.stringify(data)) }
      })
    }
    const addNum = (num1, num2) => {
      let sq1, sq2, multiple;
      try {
        sq1 = num1.toString().split(".")[1].length;
      } catch (e) {
        sq1 = 0;
      }
      try {
        sq2 = num2.toString().split(".")[1].length;
      } catch (e) {
        sq2 = 0;
      }
      multiple = Math.pow(10, Math.max(sq1, sq2) + 1);
      return (num1 * multiple + num2 * multiple) / multiple;
    }
    const getList = async () => {
      totalPrice.value = 0
      const res = await getCartList()
      if (res.code == 0) {
        store.commit('user/insertCart', res.data)
        carList.value = res.data
        carList.value.forEach(element => {
          if (element.is_checked == 1) {
            checkall.value = 1
            totalPrice.value = addNum(totalPrice.value, element.price * element.number).toFixed(2);
          } else {
            checkall.value = 0
          }
        });
        if (!carList.value.every(item => !(item.is_checked == 0 in item))) {
          checkall.value = 0
        }
      }
    }
    const changeall = async (e) => {
      cart_list.value = []
      console.log(e)
      if (e.target.checked) {
        carList.value.forEach(element => {
          element.is_checked = 1
          cart_list.value.push({
            cart_id: element.id,
            is_checked: 1
          })
        });
      } else {
        carList.value.forEach(element => {
          element.is_checked = 0
          cart_list.value.push({
            cart_id: element.id,
            is_checked: 0
          })
        });
      }
      const res = await editCartChecked({
        cart_list: cart_list.value
      })
      if (res.code == 0) {
        getList()
      }
    }
    const change = async (e, item) => {
      cart_list.value = []
      console.log(e)
      cart_list.value.push({
        cart_id: item.id,
        is_checked: e.target.checked ? 1 : 0
      })
      const res = await editCartChecked({
        cart_list: cart_list.value
      })
      if (res.code == 0) {
        getList()
      }
    }
    const minusNum = (number, item) => {
      editnum(number, item)
    }
    const addNumer = (number, item) => {
      editnum(number, item)
    }
    const editnum = async (number, item) => {
      console.log(number)
      const res = await editCartNumber({
        cart_id: item.id,
        number: number
      })
      if (res.code == 0) {
        getList()
      }
    }
    const deleteCart = async (id) => {
      const res = await delCart({
        cart_id: id
      })
      if (res.code == 0) {
        getList()
      }
    }
    onMounted(() => {
      getList()
    })
    return {
      carList,
      goCheckout,
      checkall,
      changeall,
      cart_list,
      change,
      editnum,
      deleteCart,
      addNum,
      totalPrice,
      checkly,
      minusNum,
      addNumer
    }
  }
}
</script>
<style lang="less">
#app {
  // background: red;
}
.carInfo {
  width: 100%;
  padding-top: 30px;
  .carList {
    width: 1000px;
    min-height: 56px;
    margin: 0 auto;
    background: #ffffff;
    .cart {
      background: #fff;
      color: #515a6e;
      table {
        border-spacing: 0;
        border-collapse: collapse;
        line-height: 24px;
        th,
        td {
          padding: 10px;
          border-bottom: 1px solid #f5f5f5;
          &:first-child {
            text-align: left;
            padding-left: 30px;
            // color: #999;
          }
        }
        th {
          font-size: 16px;
          font-weight: normal;
          line-height: 50px;
        }
      }
    }
  }
}
.goods {
  display: flex;
  align-items: center;
  img {
    width: 94px;
    height: 66px;
  }
  > div {
    width: 280px;
    font-size: 14px;
    padding-left: 10px;
    .attr {
      font-size: 14px;
      color: #515a6e;
    }
  }
}
.tc {
  text-align: center;
  .xtx-numbox {
    margin: 0 auto;
    width: 120px;
  }
}
.red {
  color: @priceColor;
}
.green {
  color: @xtxColor;
}
.f16 {
  font-size: 16px;
}
.action {
  width: 1000px;
  min-height: 56px;
  margin: 0 auto;
  display: flex;
  background: #fff;
  margin-top: 20px;
  height: 80px;
  align-items: center;
  font-size: 16px;
  justify-content: space-between;
  padding: 0 30px;
  color: #515a6e;
  .batch {
    a {
      margin-left: 20px;
    }
  }
  .red {
    font-size: 18px;
    margin-right: 20px;
    font-weight: bold;
  }
}
// .footer {
//   width: 100%;
//   position: fixed;
//   bottom: 0;
//   left: 0;
// }
</style>