<template>
  <div class="goods-sku">
    <a-row v-for="item in goods" :key="item.name" style="color: #808695">
      <!-- style="text-align:right;margin-right:10px" -->
      <a-col :span="4" >{{ item.name }}:</a-col>
      <a-col :span="20" class="sku">
        <template v-for="val in item.list" :key="val.name">
          <span
            @click="clickSpecs(item, val)"
            class="sku_son"
            :class="{ selected: val.selected }"
          >
            {{ val.name }}
          </span>
        </template>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  name: 'GoodsSku',
  props: {
    goods: {
      type: Object,
      default: () => ({})
    },
    // 购物车页面需要根据 skuId 实现默认规格选择
    skuId: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    // 点击规格实现选中高亮效果
    const clickSpecs = (theItem, theVal) => {
      // 选中效果
      if (!theVal.selected) {
        // 排他：先清空，再让当前高亮
        theItem.list.forEach(item => {
          item.selected = false
        })
        theVal.selected = true
        console.log(props.goods)
        emit('change', props.goods, theVal)
      } else {
        // 取消选中
        theVal.selected = false
        emit('change', props.goods, theVal)
      }
    }
    return { clickSpecs }
  }
}
</script>
<style scoped lang="less">
.sku-state-mixin () {
  border: 1px solid #e4e4e4;
  margin-right: 10px;
  cursor: pointer;
  // &.selected {
  //   border-color: @xtxColor;
  // }
  &.disabled {
    opacity: 0.6;
    border-style: dashed;
    cursor: not-allowed;
  }
}
.goods-sku {
  // padding-left: 10px;
  padding-top: 20px;
  dl {
    display: flex;
    padding-bottom: 20px;
    align-items: center;
    dt {
      width: 100px;
      color: #999;
    }
    dd {
      flex: 1;
      color: #666;
      > img {
        width: 50px;
        height: 50px;
        .sku-state-mixin ();
      }
      > span {
        display: inline-block;
        height: 30px;
        line-height: 28px;
        padding: 0 20px;
        .sku-state-mixin ();
      }
    }
  }
}
.sku {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  align-items: center;
  color: #808695;
  .sku_son {
    padding: 3px 16px;
    border: 1px solid #cccccc;
    margin-right: 6px;
    margin-bottom: 10px;
    &.selected {
      border-color: @xtxColor;
      background: #ecf1fb;
    }
  }
}
</style>
