<template>
  <div class="collect-record">
    <div class="collect-header">收藏的产品</div>
    <div class="collect-content">
      <div style="width: 100%; height: 100%; display: flex; flex-wrap: wrap">
        <div
          style="collect-content-son"
          v-for="(item, index) in List"
          :key="index"
        >
          <div class="collect-list">
            <img :src="item.slider" alt="" srcset="" />
            <p>{{ item.name }}</p>
            <p style="color: #ff3c31">
              ￥{{ item.price_selling }}-{{ item.max_price_selling }}
            </p>
            <p class="btn" @click="cancle(item)">取消收藏</p>
          </div>
        </div>
      </div>
      <XtxPagination
        v-if="total"
        :total="total"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="getCurrentPage"
      />
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { collectList, saveCollect } from '@/api/user'
export default {
  components: {},
  setup () {
    // 评论总条数
    const total = ref()
    // 当前页码
    const currentPage = ref(1)
    //一页几条
    const pageSize = ref(15)
    const List = ref([])
    const getCurrentPage = val => {
      console.log('当前子组件的页面为', val)
      currentPage.value = val
      getList()
    }
    const getList = async () => {
      const res = await collectList({
        page: currentPage.value,
        pagesize: pageSize.value
      })
      List.value = res.data.list
      total.value = res.data.total
    }
    const cancle = async (item) => {
      const res = await saveCollect({
        goods_id: item.goods_id,
        state: 2
      })
      if (res.code == 0) {
        getList()
      }
    }
    onMounted(() => {
      getList()
    })
    return {
      total, currentPage, pageSize, getCurrentPage, getList, List, cancle
    }
  }
}
</script>
<style scoped lang="less">
.collect-record {
  width: 940px;
  min-height: 732px;
  // padding: 30px 40px;
  .collect-header {
    width: 100%;
    height: 56px;
    background: #ffffff;
    padding: 16px 40px;
    font-size: 16px;
  }
  .collect-content {
    width: 100%;
    min-height: 670px;
    background: #ffffff;
    margin-top: 6px;
    padding: 30px 20px;
    .collect-content-son {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .collect-list {
    width: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    img {
      width: 190px;
      height: 162px;
    }
    .btn {
      width: 86px;
      height: 24px;
      border: 1px solid #cccccc;
      border-radius: 2px;
      font-size: 12px;
      color: #808695;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .collect-list:nth-child(4n) {
    margin: 0;
  }
}
</style>