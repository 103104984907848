<template>
  <div class="wmbeans-record">
    <XtxBread class="xtx-bread">
      <XtxBreadItem
        to="/account/my-wmbeans"
        class="xtx-bread-item"
        norColor="#17233D"
      >
        我的万谋豆
      </XtxBreadItem>
      <transition name="fade-right" mode="out-in">
        <XtxBreadItem
          to="/account/wmbeans-chong"
          class="xtx-bread-item"
          norColor="#17233D"
        >
          充值
        </XtxBreadItem>
      </transition>
      <transition name="fade-right" mode="out-in"> 充值记录 </transition>
    </XtxBread>
    <div style="color: #515a6e">
      <div class="record_header">
        <div class="header_son1">充值单号</div>
        <div class="header_son2">创建时间</div>
        <div class="header_son1">支付方式</div>
        <div class="header_son2">充值金额(元)</div>
      </div>
      <div class="record_list" v-for="(item, index) in list" :key="index">
        <div class="header_son1">{{ item.order_no }}</div>
        <div class="header_son2">{{ item.pay_time }}</div>
        <div class="header_son1">
          {{ item.pay_way == 1 ? "支付宝" : item.pay_way == 2?'微信':'公户转账' }}支付
        </div>
        <div class="header_son2" style="color: #ff3c31">{{ item.price }}</div>
      </div>
    </div>
    <XtxPagination
      v-if="total"
      :total="total"
      :current-page="currentPage"
      :page-size="pageSize"
      @current-change="getCurrentPage"
    />
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { balanceChargeList } from '@/api/user'
export default {
  components: {},
  setup () {
    // 评论总条数
    const total = ref()
    // 当前页码
    const currentPage = ref(1)
    //一页几条
    const pageSize = ref(15)
    const list = ref([])
    const getCurrentPage = val => {
      console.log('当前子组件的页面为', val)
      currentPage.value = val
      getList()
    }
    const getList = async () => {
      const res = await balanceChargeList({
        page: currentPage.value,
        pagesize: pageSize.value
      })
      list.value = res.data.list
      total.value = res.data.total
    }
    onMounted(() => {
      getList()
    })
    return { total, currentPage, pageSize, getCurrentPage, list, getList }
  }
}
</script>
<style scoped lang="less">
.wmbeans-record {
  width: 940px;
  height: 732px;
  background: #ffffff;
  padding: 30px 40px;
  .record_header {
    width: 100%;
    height: 38px;
    background: #f8f8f9;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .header_son1 {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header_son2 {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .record_list {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #f1f1f4;
    display: flex;
    align-items: center;
  }
}
</style>