<template>
  <div class="allfare">
    <div class="allfare_content">
      <XtxBread class="xtx-bread">
        <XtxBreadItem to="/" class="xtx-bread-item" norColor="#808695">
          我的位置：首页
        </XtxBreadItem>
        <transition name="fade-right" mode="out-in"> 代理费速算 </transition>
      </XtxBread>

      <div class="allfare_content_son">
        <!-- <div class="allfare_left">
          <p
            class="allfare_left_details"
            :class="{ active: activeStatus === 'agency-fare' }"
            @click="changePath('agency-fare')"
          >
            <span>代理费速算</span>
          </p>
          <p
            class="allfare_left_details"
            :class="{ active: activeStatus === 'legal-fare' }"
            @click="changePath('legal-fare')"
          >
            <span>诉讼费速算</span>
          </p>
        </div> -->
        <div class="allfare_right">
          <div class="allfare_right_son">
            <p style="display: flex; align-items: center">
              <span class="tag"></span
              ><span style="font-size: 18px">代理费速算</span>
            </p>
            <div style="margin-top: 47px">
              <a-row>
                <a-col :span="3">案件类型：</a-col>
                <a-col :span="21">
                  <a-select
                    ref="select"
                    v-model:value="value1"
                    style="width: 400px"
                    @focus="focus"
                    @change="handleChange"
                  >
                    <a-select-option :value="1"
                      >民商事财产类案件收费区间
                    </a-select-option>
                    <a-select-option :value="2"
                      >民商事不涉及财产案件收费区间
                    </a-select-option>
                    <a-select-option :value="3"
                      >民商事财产类重大、疑难复杂案件收费区间
                    </a-select-option>
                    <a-select-option :value="4"
                      >民商事不涉及财产重大、疑难、复杂案件收费区间
                    </a-select-option>
                    <a-select-option :value="5"
                      >国家赔偿（行政诉讼、行政复议）涉及财产案件收费区间
                    </a-select-option>
                    <a-select-option :value="6"
                      >国家赔偿（行政诉讼、行政复议）不涉及财产案件收费区间
                    </a-select-option>
                    <a-select-option :value="7"
                      >国家赔偿（行政诉讼、行政复议）涉及财产重大、疑难、复杂案件收费区间
                    </a-select-option>
                    <a-select-option :value="8"
                      >国家赔偿（行政诉讼、行政复议）不涉及财产重大、疑难、复杂案件收费区间
                    </a-select-option>
                    <!-- 小时 -->
                    <a-select-option :value="9">计时收费 </a-select-option>
                    <!-- 阶段 -->
                    <a-select-option :value="10"
                      >刑事案件收费区间
                    </a-select-option>
                    <a-select-option :value="11"
                      >刑事重大、疑难、复杂案件收费区间
                    </a-select-option>
                  </a-select>
                </a-col>
              </a-row>
              <a-row style="margin-top: 12px" v-if="type == 10 || type == 11">
                <a-col :span="3">阶段：</a-col>
                <a-col :span="21">
                  <a-checkbox-group v-model:value="value" @change="change">
                    <a-checkbox value="1">侦查阶段</a-checkbox>
                    <a-checkbox value="2">审查起诉阶段</a-checkbox>
                    <a-checkbox value="3">一审阶段</a-checkbox>
                  </a-checkbox-group>
                </a-col>
              </a-row>
              <a-row style="margin-top: 12px" v-else-if="type == 9">
                <a-col :span="3">小时数：</a-col>
                <a-col :span="21">
                  <a-input
                    suffix="小时"
                    style="width: 345px"
                    v-model:value="hours"
                  />
                </a-col>
              </a-row>
              <a-row style="margin-top: 12px" v-else>
                <a-col :span="3">诉讼标的额：</a-col>
                <a-col :span="21">
                  <a-input
                    ref="input"
                    suffix="元"
                    style="width: 345px"
                    v-model:value="price"
                  />
                </a-col>
              </a-row>
              <a-row style="margin-top: 20px">
                <a-col :span="3"></a-col>
                <a-col :span="21">
                  <a-button
                    type="primary"
                    style="margin-right: 10px"
                    @click="calculate"
                    >计算</a-button
                  >
                  <a-button @click="rest">重置</a-button>
                </a-col>
              </a-row>
            </div>
            <p class="line"></p>
            <p style="display: flex; align-items: center">
              <span class="tag"></span
              ><span style="font-size: 18px">计算结果</span>
            </p>
            <div class="result">
              <span style="color: red">{{ result }}</span
              ><span>元</span>
            </div>
            <p style="color: #808695; margin-bottom: 54px">
              注：预估结果仅供参考，实际费用以当地实际情况为准！计算单位：人民币。
            </p>
            <p style="display: flex; align-items: center">
              <span class="tag"></span
              ><span style="font-size: 18px">计算公式</span>
            </p>
            <p style="color: #808695; margin-top: 20px" v-if="max_formula">
              {{ max_formula }}
            </p>
            <p style="color: #808695; margin-top: 20px" v-if="min_formula">
              {{ min_formula }}
            </p>
            <p
              style="color: #808695; margin-top: 20px"
              v-if="!min_formula || !max_formula"
            >
              无
            </p>
            <!-- <div style="color: #808695; font-size: 16px; margin-top: 20px">
              <p v-if="level == 1" style="margin-top: 20px; text-indent: 2em">
                涉案标的额≦10000元时，计费下限值为2500元，计费上限值为10000元；
              </p>
              <p v-if="level == 2" style="margin-top: 20px; text-indent: 2em">
                涉案标的额＞1万元≦10万元，计费比例为6%-7%。涉案标的额减1万元得出计费标的额，计费标的额×6%=计费下限值，计费标的额×7%=计费上限值；
              </p>
              <p v-if="level == 3" style="margin-top: 20px; text-indent: 2em">
                涉案标的额＞10万元≦50万元，计费比例为5%-6%。涉案标的额减10万元得出计费标的额，计费标的额×5%=计费下限值，计费标的额×6%=计费上限值；
              </p>
              <p v-if="level == 4" style="margin-top: 20px; text-indent: 2em">
                涉案标的额＞50万元≦100万元，计费比例为4%-5%，涉案标的额减50万元得出计费标的额，计费标的额×4%=计费下限值，计费标的额×5%=计费上限值；
              </p>
              <p v-if="level == 5" style="margin-top: 20px; text-indent: 2em">
                涉案标的额＞100万元≦500万元，计费比例为3%-4%，涉案标的额减100万元得出计费标的额，计费标的额×3%=计费下限值，计费标的额×4%=计费上限值；
              </p>
              <p v-if="level == 6" style="margin-top: 20px; text-indent: 2em">
                涉案标的额＞500万元≦1000万元，计费比例为2%-3%，涉案标的额减500万元得出计费标的额，计费标的额×2%=计费下限值，计费标的额×3%=计费上限值；
              </p>
              <p v-if="level == 7" style="margin-top: 20px; text-indent: 2em">
                涉案标的额＞1000万元≦1亿万元，计费比例为1%-2%，涉案标的额减1000万元得出计费标的额，计费标的额×1%=计费下限值，计费标的额×2%=计费上限值；
              </p>
              <p v-if="level == 8" style="margin-top: 20px; text-indent: 2em">
                涉案标的额＞1亿万元，计费比例为0.5%-1%，涉案标的额减1亿万元得出计费标的额，计费标的额×0.5%=计费下限值，计费标的额×1%=计费上限值；
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { lawsuitCount } from "@/api/FirstPage";
export default {
  setup() {
    const route = useRoute();
    const value1 = ref("");
    const value = ref([]);
    const activeStatus = ref("legal-fare");
    const type = ref("");
    const price = ref("");
    const hours = ref("");
    const number = ref("");
    const result = ref("");
    const max_formula = ref("");
    const min_formula = ref("");
    const level = ref(0);
    const handleChange = (value) => {
      console.log(`selected ${value}`);
      type.value = value;
    };
    const change = () => {
      console.log(value.value);
      number.value = value.value.length;
    };
    const calculate = async () => {
      const res = await lawsuitCount({
        type: type.value,
        hours: hours.value,
        price: price.value,
        number: number.value,
      });
      if (res.code == 0) {
        result.value = res.data.fee;
        max_formula.value = res.data.max_formula;
        min_formula.value = res.data.min_formula;

        console.log("price", price.value);
        if (price.value <= 10000) {
          level.value = 1;
        } else if (price.value > 10000 && price.value <= 100000) {
          level.value = 2;
        } else if (price.value > 100000 && price.value <= 500000) {
          level.value = 3;
        } else if (price.value > 500000 && price.value <= 1000000) {
          level.value = 4;
        } else if (price.value > 1000000 && price.value <= 5000000) {
          level.value = 5;
        } else if (price.value > 5000000 && price.value <= 10000000) {
          level.value = 6;
        } else if (price.value > 10000000 && price.value <= 1000000000000) {
          level.value = 7;
        } else if (price.value > 1000000000000) {
          level.value = 8;
        }
      }
    };
    const rest = () => {
      hours.value = "";
      price.value = "";
      number.value = "";
      type.value = "";
      value1.value = "";
    };
    return {
      activeStatus,
      handleChange,
      level,
      value1,
      value,
      change,
      type,
      price,
      hours,
      calculate,
      rest,
      result,
      max_formula,
      min_formula,
    };
  },
};
</script>
<style scoped lang="less">
.allfare {
  width: 100%;
  min-height: 800px;
  .allfare_content {
    width: 1116px;
    margin: 0 auto;
    padding-top: 20px;
    .xtx-bread {
      color: #808695;
    }
    .allfare_content_son {
      width: 100%;
      // display: flex;
      margin-top: 12px;
      // justify-content: space-between;
      // .allfare_left {
      //   width: 248px;
      //   height: 160px;
      //   background: #ffffff;
      //   padding: 28px 12px;
      //   display: flex;
      //   flex-direction: column;
      // }
      .allfare_left_details {
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-bottom: 10px;
        &.active {
          background: #296df1;
          color: #f8f8f9;
        }
      }
      .allfare_right {
        width: 848px;
        min-height: 800px;
        background: #ffffff;
        margin-bottom: 108px;
        padding: 46px;
        .allfare_right_son {
          width: 100%;
          height: 100%;
          .tag {
            display: block;
            width: 5px;
            height: 16px;
            background: #296df1;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.line {
  width: 100%;
  height: 1px;
  border: 1px solid #f1f1f4;
  margin-top: 53px;
  margin-bottom: 44px;
}
.result {
  width: 345px;
  height: 42px;
  border: 1px solid #f1f1f4;
  margin-top: 20px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
</style>
