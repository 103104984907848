<template>
  <div>
    <!-- 头部 -->
    <commonHeader />
    <!-- 内容 -->
    <div class="content">
      <div class="content_son">
        <div class="content_son_left">
          <div
            v-for="(item, index) in list"
            :key="index"
            style="display: flex; margin-bottom: 12px; flex-direction: column"
          >
            <div style="display: flex; align-items: center">
              <img :src="item.icon" alt="" srcset="" v-if="item.icon" />
              <p class="content_son_left_ftitle">
                {{ item.name }}
              </p>
            </div>
            <router-link
              v-for="(content, cindex) in item.child"
              :key="cindex"
              :to="content.path"
            >
              <p
                class="content_son_left_stitle"
                :class="{ active: content.actived }"
                @click="chosenavBar(content, index, cindex)"
              >
                {{ content.name }}
              </p>
            </router-link>
          </div>
        </div>
        <div class="content_son_center">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <Modal />
    <!-- 底部 -->
    <AppFooter />
  </div>
</template>
<script>
import commonHeader from './components/common-header.vue'
import AppFooter from '@/components/app-footer.vue'
import Modal from '@/components/modal.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { onMounted, getCurrentInstance, ref, watch, reactive } from 'vue'
import accountList from '@/utils/account.js'
export default {
  components: { commonHeader, AppFooter, Modal },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { proxy } = getCurrentInstance();
    const list = store.state.account.list
    console.log(list)
    const chosenavBar = (content, index, cindex) => {
      changeRoute()
      content.actived = true
      list[index].child[cindex] = content
      store.commit('account/setList', list)
    }
    onMounted(() => {
      store.commit('account/setList', list)
      changeRoute()
      console.log(route.path);
      list.forEach((element) => {
        element.child.forEach(ietm => {
          if (ietm.path && ietm.path == route.path) {
            ietm.actived = true
          }
        })
      });
    });
    watch(() => route.path, () => {
      console.log('监听到变化', route.path)
      changeRoute()
      list.forEach((element) => {
        element.child.forEach(ietm => {
          if (ietm.path && ietm.path == route.path) {
            ietm.actived = true
          }
        })
      });
      store.commit('account/setList', list)
    }
    )
    const changeRoute = () => {
      list.forEach((element) => {
        element.child.forEach(ietm => {
          ietm.actived = false;
        })
      });
      store.commit('account/setList', list)
    }
    return {
      list,
      chosenavBar,
      changeRoute
    }
  }
}
</script>
<style scoped lang="less">
.content {
  width: 100%;
  min-height: 737px;
  padding-top: 30px;
  .content_son {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .content_son_left {
      width: 240px;
      height: 670px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      padding: 20px 0;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
      .content_son_left_ftitle {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
      .content_son_left_stitle {
        font-size: 14px;
        color: #808695;
        line-height: 24px;
        margin-left: 26px;
        margin-top: 5px;
      }
      .active {
        color: #296df1;
      }
    }
    .content_son_center {
      min-height: 166px;
      margin: 0 20px;
      margin-bottom: 60px;
    }
  }
  .content_son1 {
    width: 680px;
    height: 240px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 67px;
  }
}
/deep/.ant-menu-vertical > .ant-menu-item {
  height: 30px;
}
.xtx-bread {
  font-size: 14px;
  color: #ffffff;
  position: absolute;
  bottom: 12px;
  .xtx-bread_img {
    width: 11px;
    height: 10px;
  }
}
</style>