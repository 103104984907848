<template>
  <div ref="element">
    <!-- 购物车顶部 -->
    <cartHeader title="确认订单" />
    <!-- 内容 -->
    <div class="order-content">
      <div class="content">
        <p class="content-title"><i class="red">*</i>收货地址<span class="notice">（请先添加收货地址,否则无法下单）</span></p>
        <div class="content-address" v-if="address != null">
          <div class="content-address-son">
            <span style="font-size: 14px; color: #17233d">{{
              address.name
            }}</span>
            <span style="font-size: 12px; color: #808695">{{
              address.phone
            }}</span>
          </div>
          <div class="content-address-son1">
            <p>{{ address.email }}</p>
            <p>{{ address.provinces }}{{ address.address }}</p>
            <!-- <p class="edit">修改</p> -->
          </div>
        </div>
        <div style="display: flex; margin-top: 14px">
          <div class="add" @click="showDialog">
            <!-- <img src="@/assets/images/+@2x.png" alt="" class="add_img" /> -->
            <span>切换地址</span>
          </div>
          <router-link to="/account/address?isOrder=1">
            <div class="add">
              <!-- <img src="@/assets/images/+@2x.png" alt="" class="add_img" /> -->
              <span>添加新地址</span>
            </div>
          </router-link>
        </div>
        <div class="content-list">
          <div class="content-list-son">
            <a-row>
              <a-col :span="3" class="content-title">支付方式</a-col>
              <a-col :span="21">在线支付（支付宝、微信） </a-col>
            </a-row>
          </div>
          <div class="content-list-son">
            <a-row>
              <a-col :span="3" class="content-title">配送方式</a-col>
              <a-col :span="21" style="display: flex; flex-wrap: wrap">
                <!-- 快递配送 -->
                <template v-for="(item, index) in truckWay" :key="index">
                  <span
                    @click="clicktruckWay(truckWay, item)"
                    class="hesitate_content"
                    :class="{ selected: item.selected }"
                  >
                    {{ item.name }}
                  </span>
                </template>
              </a-col>
            </a-row>
          </div>
          <div class="content-list-son">
            <a-row>
              <a-col :span="3" class="content-title"><i class="red">*</i>发票信息</a-col>
              <a-col :span="21">
                <p v-if="billInfos != null">
                  <span style="margin-right: 30px">{{
                    billInfos.type == 1
                      ? "普通发票"
                      : billInfos.type == 1
                      ? "增值税发票"
                      : "暂不需要开具发票"
                  }}</span>
                  <span v-if="billInfos.head != 0" style="margin-right: 30px"
                    >发票抬头：{{
                      billInfos.head == 1
                        ? "个人"
                        : billInfos.head == 2
                        ? "公司"
                        : ""
                    }}</span
                  >
                  <span style="margin-right: 30px" v-if="billInfos.head != 0"
                    >发票内容：产品明细</span
                  >
                  <span style="color: #296df1" @click="edit">[修改]</span>
                </p>
                <div class="add" v-else>
                  <router-link target="_blank" to="/account/invoice">
                    <img
                      src="@/assets/images/+@2x.png"
                      alt=""
                      class="add_img"
                    />
                    <span>添加发票信息</span>
                  </router-link>
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="content-list-son">
            <a-row>
              <a-col :span="3" class="content-title">犹豫期</a-col>
              <a-col :span="21" style="display: flex; flex-wrap: wrap">
                <template v-for="(item, index) in hesitate" :key="index">
                  <span
                    @click="clickHesitate(hesitate, item)"
                    class="hesitate_content"
                    :class="{ selected: item.selected }"
                  >
                    {{ item.hours }}小时犹豫期
                  </span>
                </template>
                <!-- <a-radio-group @change="change" :v-model:value="value1">
                  <a-radio-button
                    :value="item.id"
                    v-for="(item, index) in hesitate"
                    :key="index"
                    >{{ item.hours }}小时犹豫期</a-radio-button
                  >
                </a-radio-group> -->
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="3"></a-col>
              <a-col :span="21" style="margin-top: 10px; color: #ff3c31"
                >选择犹豫期，在犹豫期内律所不会立即提供法律服务，当事人可以在犹豫期内无条件退款
                ；未选择犹豫期，当事人选择退款须经律所审核同意
              </a-col>
            </a-row>
          </div>
          <div class="content-list-son">
            <p class="content-title">产品信息</p>
            <div class="cart">
              <table>
                <thead>
                  <tr style="text-align: center">
                    <th width="400">产品名称</th>
                    <th width="100">价格（元）</th>
                    <th width="180">数量</th>
                    <th width="180">小计</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in carList" :key="item.skuId">
                    <td>
                      <div class="goods">
                        <RouterLink :to="`/product/${item.id}`"
                          ><img :src="item.slider" alt=""
                        /></RouterLink>
                        <div>
                          <p class="name">{{ item.goods_name }}</p>
                        </div>
                      </div>
                    </td>
                    <td class="tc">
                      <p>￥{{ item.price }}</p>
                    </td>
                    <td class="tc">×{{ item.number }}</td>
                    <td class="tc">
                      <p class="f16">￥{{ item.total }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <!-- <p>
                        <img
                          src="@/assets/images/icon_16x16px@2x.png"
                          alt=""
                          class="addbeans"
                        />
                        <span style="font-size: 12px; color: #296df1"
                          >使用万谋豆抵扣</span
                        >
                      </p> -->
                    </td>
                    <td colspan="2">
                      <div>
                        <a-row>
                          <a-col :span="6">产品件数:</a-col>
                          <a-col
                            :span="18"
                            style="color: #ff3c31; text-align: right"
                            >{{ data.number_goods }}</a-col
                          >
                        </a-row>
                        <a-row>
                          <a-col :span="6">产品总价:</a-col>
                          <a-col
                            :span="18"
                            style="color: #ff3c31; text-align: right"
                            >{{ data.amount_goods }}</a-col
                          >
                        </a-row>
                        <a-row>
                          <a-col :span="6">万谋豆抵扣:</a-col>
                          <a-col
                            :span="18"
                            style="color: #ff3c31; text-align: right"
                            >{{ data.payment_balance }}</a-col
                          >
                        </a-row>
                        <a-row style="margin-top: 10px">
                          <a-col :span="6">应付总额:</a-col>
                          <a-col
                            :span="18"
                            style="
                              color: #ff3c31;
                              text-align: right;
                              font-size: 22px;
                              font-weight: 700;
                            "
                            >{{ data.payment_amount }}</a-col
                          >
                        </a-row>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style="
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div></div>
              <XtxButton type="primary" @click="submitOrder"
                >提交订单</XtxButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公共底部 -->
    <AppFooter />
    <!-- 弹框 -->
    <a-modal
      v-model:visible="Invoice"
      :footer="null"
      :bodyStyle="{ padding: '25px 0px' }"
      :maskStyle="{ height: elheight }"
      width="650px"
    >
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        v-if="formState.type == 2"
      >
        <a-form-item label="发票类型：" name="type">
          <a-radio-group v-model:value="formState.type" @change="radioChange">
            <a-radio :value="0">暂不需要开具发票</a-radio>
            <a-radio :value="1">普通发票</a-radio>
            <a-radio :value="2">增值税发票</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="单位名称：" name="company_name">
          <a-input
            v-model:value="formState.company_name"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="纳税人识别号：" name="tax_no">
          <a-input
            v-model:value="formState.tax_no"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="注册地址：" name="address">
          <a-input
            v-model:value="formState.address"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="注册电话：" name="mobile">
          <a-input
            v-model:value="formState.mobile"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="开户银行：" name="bank">
          <a-input
            v-model:value="formState.bank"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="银行账户：" name="bank_account">
          <a-input
            v-model:value="formState.bank_account"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        v-if="formState.type == 1"
      >
        <a-form-item label="发票类型：" name="type">
          <a-radio-group v-model:value="formState.type" @change="radioChange">
            <a-radio :value="0">暂不需要开具发票</a-radio>
            <a-radio :value="1">普通发票</a-radio>
            <a-radio :value="2">增值税发票</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="发票抬头：" name="head">
          <a-select v-model:value="formState.head" style="width: 282px">
            <a-select-option :value="1">个人</a-select-option>
            <a-select-option :value="2">公司</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="个人姓名：" name="name" v-if="formState.head == 1">
          <a-input
            v-model:value="formState.name"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item
          label="公司名称："
          name="company_name"
          v-if="formState.head == 2"
        >
          <a-input
            v-model:value="formState.company_name"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item
          label="纳税识别号："
          name="tax_no"
          v-if="formState.head == 2"
        >
          <a-input
            v-model:value="formState.tax_no"
            style="
              height: 30px;
              width: 282px;
              border: 1px solid #d9d9d9;
              padding-left: 10px;
            "
          />
        </a-form-item>
        <a-form-item label="发票内容："> 产品明细 </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        v-if="formState.type == 0"
      >
        <a-form-item label="发票类型：" name="type">
          <a-radio-group v-model:value="formState.type" @change="radioChange">
            <a-radio :value="0">暂不需要开具发票</a-radio>
            <a-radio :value="1">普通发票</a-radio>
            <a-radio :value="2">增值税发票</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 风险告知书 -->
    <a-modal
      v-model:visible="iSrisk"
      :footer="null"
      :closable="false"
      :maskClosable="false"
      width="800px"
      :bodyStyle="{ padding: '30px 30px' }"
      :maskStyle="{ height: elheight }"
    >
      <p class="second"></p>
      <p class="risk_title">文件签署区</p>
      <div class="risk_content">
        <p
          v-html="item.danger_inform"
          v-for="(item, index) in danger_inform"
          :key="index"
        ></p>
      </div>
      <a-checkbox v-model:checked="checked" @change="check"
        >本人已阅读以上文件的所有内容，对以上文件的所有内容均无异议。本人充分了解文件内容的含义，并已经注意到文件中“加黑”或“红色”的提醒信息</a-checkbox
      >
      <p style="margin-top: 47px; text-align: center">
        <a-button type="primary" :disabled="isDisabled" @click="agree"
          >同意协议 {{ time }}秒</a-button
        >
      </p>
    </a-modal>
    <a-modal
      v-model:visible="dialogVisible"
      :footer="null"
      width="600px"
      :bodyStyle="{ padding: '30px 30px' }"
      :maskStyle="{ height: elheight }"
      class="xtx-dialog"
    >
      <div
        class="text item"
        v-for="item in addresses"
        :key="item.id"
        :class="{ active: item.id === activeId }"
        @click="changeAddress(item.id)"
      >
        <ul>
          <li>
            <span>收<i />货<i />人：</span>{{ item.name }}
          </li>
          <li><span>联系方式：</span>{{ item.phone }}</li>
          <li><span>收货地址：</span>{{ item.provinces }}{{ item.address }}</li>
        </ul>
      </div>
      <div style="text-align: center">
        <XtxButton
          @click="dialogVisible = false"
          type="gray"
          style="margin-right: 20px"
          >取消</XtxButton
        >
        <XtxButton @click="confirmAddress" type="primary">确认</XtxButton>
      </div>
    </a-modal>
    <Modal />
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { reactive, ref, toRaw, onMounted } from "vue";
import cartHeader from "./components/cart-header.vue";
import AppFooter from "@/components/app-footer.vue";
import { useIntervalFn } from "@vueuse/shared";
import Modal from "@/components/modal.vue";
import { saveBill, billInfo, addressList, getCartList } from "@/api/user";
import {
  getDefaultAddress,
  hesitateList,
  getGoodInfo,
  addOrder,
  dangerList,
} from "@/api/Orders";
// import eventBus from '@/utils/eventBus';
import Message from "@/components/library/Message";
import { useStore } from "vuex";
export default {
  components: { cartHeader, AppFooter, Modal },
  setup() {
    const dialogVisible = ref(false);
    const checked = ref(true);
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const value1 = ref();
    const Invoice = ref(false);
    const iSrisk = ref(false);
    const time = ref(15);
    const goods_id = ref("");
    const number = ref("");
    const sku_id = ref("");
    const address = ref({});
    const type = ref("");
    const addresses = ref("");
    const hesitate = ref([]);
    const billInfos = ref({});
    const element = ref(null);
    const elheight = ref("");
    const danger_inform = ref([]);
    const truckWay = ref([
      {
        truck_way: 1,
        selected: false,
        name: "电子送达",
      },
      {
        truck_way: 2,
        selected: false,
        name: "即时性送达",
      },
      {
        truck_way: 3,
        selected: false,
        name: "快递配送",
      },
    ]);
    const change = (e) => {
      value1.value = e.target.value;
      console.log(value1.value);
      hesitate.value.forEach((item) => {
        if (item.id == value1.value) {
          data.hesitate_hours = item.hours;
        }
      });
    };
    // const handleOk = () => {

    // }
    const agree = () => {
      if (!checked.value) {
        Message({ message: "请勾选风险告知书" });
        return;
      } else {
        iSrisk.value = false;
        submitOrder();
      }
    };
    const formRef = ref();
    const formState = reactive({
      id: "",
      name: "",
      // 暂不需要发票
      type: 0,
      head: "",
      company_name: "",
      tax_no: "",
      address: "",
      mobile: "",
      bank: "",
      bank_account: "",
    });
    const data = reactive({
      address_id: "",
      receive_name: "",
      receive_phone: "",
      receive_address: "",
      receive_email: "",
      // 配送 方式
      truck_way: 1,
      hesitate_hours: "", //犹豫期
      bill_id: "", //发票id
      bill_type: "", //发票类型：0暂不需要发票   1普通发票 2增值税发票
      bill_head: "", //发票抬头：1个人 2公司
      bill_tax: "", //纳税识别号
      amount_goods: "", //产品总金额
      number_goods: "", //产品总数量
      // amount_express:'',//运费金额
      payment_balance: "", //万谋豆抵扣金额
      payment_amount: "", //实际需要支付金额
      goods: [],
    });
    const carData = ref([]);
    const rules = {
      head: [{ required: true, message: "请选择发票抬头" }],
      name: [{ required: true, message: "请输入个人姓名" }],
      company_name: [{ required: true, message: "请输入公司名称" }],
      tax_no: [{ required: true, message: "请输入纳税识别号" }],
      address: [{ required: true, message: "请输入地址" }],
      mobile: [{ required: true, message: "请输入手机号" }],
      bank: [{ required: true, message: "请输入开户银行" }],
      bank_account: [{ required: true, message: "请输入银行账号" }],
    };
    const radioChange = (e) => {
      Object.keys(formState).forEach((key) => {
        if (key != "type" && key != "id") {
          formState[key] = "";
        }
      });
      console.log("formState", formState);
    };
    const showDialog = () => {
      elheight.value = window.getComputedStyle(element.value).height;
      if (addresses.value.length > 0) {
        dialogVisible.value = true;
      }
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          console.log("values", formState, toRaw(formState));
          const res = await saveBill(formState);
          if (res.code == 0) {
            Message({ type: "success", message: "提交成功" });
            Invoice.value = false;
            formRef.value.resetFields();
            getbillInfo();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const edit = () => {
      elheight.value = window.getComputedStyle(element.value).height;
      // console.log(elheight.value)
      Object.assign(formState, billInfos.value);
      Invoice.value = true;
    };
    const carList = ref([]);
    const check = (e) => {
      console.log(e);
      checked.value = e.target.checked;
    };
    const isDisabled = ref(true);
    const { resume, pause } = useIntervalFn(
      () => {
        time.value--;
        if (time.value <= 0) {
          pause();
          isDisabled.value = false;
        }
      },
      1000,
      { immediate: false }
    );
    // const submit = () => {
    //   iSrisk.value = true
    //   resume()
    // }
    const submitOrder = async () => {
      if(address.value==null){
        Message({ type: "error", message: "收货地址不能为空" });
        return false;
      }
      elheight.value = window.getComputedStyle(element.value).height;
      data.goods = [];
      data.address_id = address.value.id;
      data.receive_name = address.value.name;
      data.receive_phone = address.value.phone;
      data.receive_address = address.value.address;
      data.receive_email = address.value.email;
      data.bill_id = billInfos.value.id;
      data.bill_type = billInfos.value.type;
      data.bill_head = billInfos.value.head;
      data.bill_tax = billInfos.value.tax_no;
      carList.value.forEach((element) => {
        data.goods.push({
          ...element,
          goods_cover: element.slider,
          price_selling: element.price,
          balance: element.balance,
        });
        if (type.value == "car") {
          carData.value.forEach((item) => {
            data.goods.forEach((content) => {
              if (item.skuId == content.sku_id) {
                content.cart_id = item.cartid;
              }
            });
          });
        }
      });
      console.log("data", data);
      if (time.value != 0) {
        iSrisk.value = true;
        const result = await dangerList({
          goods: data.goods,
        });
        console.log(result);
        if (result.code == 0) {
          danger_inform.value = result.data;
        }
        resume();
        return;
      }

      const res = await addOrder(data);
      if (res.code == 0) {
        getcarList();
        const edtails = {
          ...address.value,
          payment_amount: data.payment_amount,
          order_no: res.order_no,
        };
        router.push({
          path: "/order/submit",
          query: { data: encodeURIComponent(JSON.stringify(edtails)) },
        });
      }
    };
    const getcarList = async () => {
      const res = await getCartList();
      if (res.code == 0) {
        store.commit("user/insertCart", res.data);
      }
    };
    const getbillInfo = async () => {
      const res = await billInfo();
      console.log('bill',billInfos);
      if (res.code == 0) {
        billInfos.value = res.data;
      }
    };
    const activeId = ref("");
    const getAddress = async () => {
      const res = await getDefaultAddress();
      //console.log('res',res);
      if(res.data!=null){
        address.value = res.data;
        activeId.value = address.value.id;
        //console.log(address.value);
      }else{
        address.value=null
      }
      
    };
    const changeNewAds = () => {
      // , query: { type: 'choseaddres' }
      router.push({ path: "/account/address" });
    };
    const GetList = async () => {
      const res = await addressList();
      if (res.code == 0) {
        addresses.value = res.data;
      }
    };
    const changeAddress = (theId) => {
      // console.log(theId)
      activeId.value = theId;
    };
    const confirmAddress = () => {
      address.value = addresses.value.find(
        (item) => item.id === activeId.value
      );
      dialogVisible.value = false;
    };
    const gethesitateList = async () => {
      const goodsIdarry = [];
      if (type.value == "buy") {
        //直接购买
        goodsIdarry.push(goods_id.value);
      } else {
        carData.value.forEach((item) => {
          goodsIdarry.push(item.id);
        });
      }
      const res = await hesitateList({
        goods_id: goodsIdarry,
      });
      hesitate.value = res.data;
      hesitate.value.forEach((element) => {
        element.selected = false;
      });
    };
    const orderinfo = async () => {
      const goods_list = [];
      if (type.value == "buy") {
        goods_list.push({
          goods_id: goods_id.value,
          number: number.value,
          sku_id: sku_id.value,
        });
      } else {
        carData.value.forEach((item) => {
          goods_list.push({
            goods_id: item.id,
            number: item.count,
            sku_id: item.skuId,
          });
        });
      }
      const res = await getGoodInfo({
        goods_list,
      });
      carList.value = res.data;
      data.amount_goods = res.total_price;
      data.number_goods = res.total_number;
      data.payment_balance = res.max_balance;
      data.payment_amount = (res.total_price - res.max_balance).toFixed(2);
    };
    const clickHesitate = (theItem, theVal) => {
      // 选中效果
      if (!theVal.selected) {
        // 排他：先清空，再让当前高亮
        theItem.forEach((item) => {
          item.selected = false;
        });
        theVal.selected = true;
        data.hesitate_hours = theVal.hours;
      } else {
        // 取消选中
        theVal.selected = false;
        data.hesitate_hours = "";
      }
      console.log(data.hesitate_hours);
    };
    const clicktruckWay = (theItem, theVal) => {
      // 选中效果
      if (!theVal.selected) {
        // 排他：先清空，再让当前高亮
        theItem.forEach((item) => {
          item.selected = false;
        });
        theVal.selected = true;
        data.truck_way = theVal.truck_way;
      } else {
        // 取消选中
        theVal.selected = false;
        data.truck_way = "";
      }
      console.log(data.truck_way);
    };
    onMounted(() => {
      getbillInfo();
      getAddress();
      GetList();
      const data = JSON.parse(decodeURIComponent(route.query.data));
      console.log(data);
      if (data.type && data.type == "buy") {
        number.value = data.count;
        sku_id.value = data.skuId;
        goods_id.value = data.id;
        type.value = data.type;
      } else {
        type.value = "car";
        carData.value = JSON.parse(decodeURIComponent(route.query.data));
      }
      gethesitateList();
      orderinfo();
      // let newAddress = ''
      // eventBus.on('getnewAddress', (e) => {
      //   type.value = 'choseaddres'
      //   // address.value = e
      //   Object.assign(address.value, e)
      //   console.log(address.value)
      // })
    });
    return {
      value1,
      change,
      carList,
      Invoice,
      // handleOk,
      rules,
      formRef,
      formState,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      onSubmit,
      edit,
      iSrisk,
      danger_inform,
      checked,
      check,
      time,
      // submit,
      isDisabled,
      agree,
      submitOrder,
      getcarList,
      getbillInfo,
      billInfos,
      getAddress,
      address,
      goods_id,
      number,
      sku_id,
      changeNewAds,
      type,
      dialogVisible,
      GetList,
      addresses,
      changeAddress,
      confirmAddress,
      activeId,
      gethesitateList,
      hesitate,
      orderinfo,
      radioChange,
      data,
      carData,
      showDialog,
      element,
      elheight,
      clickHesitate,
      truckWay,
      clicktruckWay,
    };
  },
};
</script>
<style scoped lang="less">
.order-content {
  width: 100%;
  min-height: 1136px;
  padding-top: 30px;
  .content {
    width: 1000px;
    margin: 0 auto;
    // min-height: 1136px;
    background: #ffffff;
    border-top: 2px solid #296df1;
    padding: 50px 40px;
    margin-bottom: 120px;
    .red,.notice{
      color:#f00
    }
    .content-address {
      margin-top: 10px;
      width: 460px;
      height: 140px;
      border: 1px solid #eaf1fe;
      padding: 18px 14px 11px 14px;
      position: relative;
      .content-address-son {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #eaf1fe;
        display: flex;
        justify-content: space-between;
      }
      .content-address-son1 {
        margin-top: 10px;
        font-size: 12px;
        color: #808695;
        p {
          margin-bottom: 10px;
        }
        .edit {
          color: #296df1;
          position: absolute;
          right: 12px;
          bottom: 11px;
        }
      }
    }
  }
}
.content-title {
  font-size: 14px;
  font-weight: 700;
  color: #17233d;
}
.add {
  width: 157px;
  height: 30px;
  background: #f8f8f9;
  border: 1px solid #f1f1f4;
  font-size: 12px;
  color: #808695;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  .add_img {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
}
.content-list {
  margin-top: 52px;
  .content-list-son {
    width: 100%;
    padding: 23px 0;
    border-top: 1px solid #f1f1f4;
    .cart {
      background: #fff;
      color: #515a6e;
      table {
        border-spacing: 0;
        border-collapse: collapse;
        line-height: 24px;
        margin-top: 12px;
        thead {
          width: 100%;
          height: 34px;
          background: #f8f8f9;
        }
        tr {
          border: 1px solid #f1f1f4;
          border-top: none;
        }
        th,
        td {
          padding: 10px;
          border-bottom: 1px solid #f5f5f5;
          text-align: center;
          &:first-child {
            text-align: left;
            padding-left: 30px;
            // color: #999;
          }
        }
        th {
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }
}
/deep/.ant-radio-button-wrapper {
  margin-right: 20px;
}
.td_footer {
  width: 100%;
  height: 164px;
  display: flex;
  justify-content: space-between;
}
.addbeans {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.border {
  width: 300px;
  height: 32px;
  border: 1px solid #eeeeee;
  margin-left: 9px;
  margin-bottom: 10px;
}
.risk_title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.risk_content {
  widows: 100%;
  min-height: 576px;
  overflow-y: scroll;
  font-size: 14px;
  line-height: 24px;
}
.second {
  position: absolute;
  right: 55px;
  top: 20px;
  font-size: 18px;
}
// .footer {
//   width: 100%;
//   position: fixed;
//   bottom: 0;
//   left: 0;
// }
.xtx-dialog {
  .text {
    flex: 1;
    min-height: 90px;
    display: flex;
    align-items: center;
    &.item {
      border: 1px solid #f5f5f5;
      margin-bottom: 10px;
      cursor: pointer;
      &.active,
      &:hover {
        border-color: @xtxColor;
        background: lighten(@xtxColor, 50%);
      }
      > ul {
        padding: 10px;
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
}
.hesitate_content {
  padding: 7px 55px;
  border: 1px solid #f1f1f4;
  margin-right: 10px;
  margin-bottom: 10px;
  &.selected {
    border: 1px solid #296df1;
  }
}
</style>
