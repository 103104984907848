<template>
  <div class="header" v-if="token">
    <div class="header_content">
      <p>
        您好，欢迎来到万谋商城！<span style="color: red"
          >您在体验网站过程中有任何疑问均可咨询客服哟！</span
        >
      </p>
      <p>
        <router-link to="/account/my-order" target="_blank">
          <span class="header_content_right">{{ account }}</span>
        </router-link>
        <span class="header_content_right" @click="logoutBtn">退出</span>
        <router-link to="/account/my-order">
          <span class="header_content_right">我的订单</span>
        </router-link>
        <router-link to="/account/news">
          <span class="header_content_right">我的消息</span>
        </router-link>
        <router-link to="/">
          <span>商城首页</span>
        </router-link>
      </p>
    </div>
  </div>
  <nav class="app-topnav" v-else>
    <img src="@/assets/images/banner@2x.png" alt="" srcset="" />
  </nav>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { logOut } from "@/api/user";
export default {
  name: "AppTopnav",
  setup() {
    const store = useStore();
    const router = useRouter();
    const token = ref();
    const account = ref();
    token.value = store.state.user.token;
    account.value = store.state.user.userinfo.nickname
      ? store.state.user.userinfo.nickname
      : store.state.user.userinfo.account;
    console.log(store.state.user.userinfo);
    const logoutBtn = async () => {
      const res = await logOut();
      // 1. 清空用户信息
      store.commit("user/setToken", "");
      store.commit("user/setUser", {});
      store.commit("user/setKeyword", "");
      store.commit("user/clearCart", []);
      // 2. 跳转到登录页
      // let routeUrl = router.resolve('/login');
      // window.open(routeUrl.href);
      router.push("/login");
    };
    return {
      logoutBtn,
      account,
      token,
    };
  },
};
</script>

<style scoped lang="less">
.app-topnav {
  width: 100%;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
  }
  // background: #333;
}
.header {
  width: 100%;
  height: 30px;
  background: #f8f8f9;
  .header_content {
    width: 1174px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #808695;
    .header_content_right {
      margin-right: 14px;
    }
  }
}
</style>
