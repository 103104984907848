<template>
  <div class="restpwd">
    <div class="restpwd-header">个人信息</div>
    <div class="restpwd-content">
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="旧密码：" name="old_pwd">
          <a-input
            v-model:value="formState.old_pwd"
            type="password"
            style="border: 1px solid #d9d9d9; height: 30px; width: 282px"
          />
        </a-form-item>
        <a-form-item label="新密码：" name="new_pwd">
          <a-input
            v-model:value="formState.new_pwd"
            type="password"
            style="height: 30px; width: 282px; border: 1px solid #d9d9d9"
          />
        </a-form-item>
        <a-form-item label="重复新密码：" name="new_repwd">
          <a-input
            v-model:value="formState.new_repwd"
            type="password"
            style="height: 30px; width: 282px; border: 1px solid #d9d9d9"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRaw } from 'vue'
import { doEdit, logOut } from '@/api/user'
import Message from '@/components/library/Message'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: {},
  setup () {
    const store = useStore()
    const router = useRouter()
    const formRef = ref();
    const formState = reactive({
      old_pwd: '',
      new_pwd: '',
      new_repwd: ''
    });
    let validatePass2 = async (rule, value) => {
      if (value === '') {
        return Promise.reject('Please input the password again');
      } else if (value !== formState.new_pwd) {
        return Promise.reject("Two inputs don't match!");
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      old_pwd: [
        { required: true, message: '请输入旧密码', trigger: 'blur' },
      ],
      new_pwd: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
      new_repwd: [{ required: true, message: '请再次输入新密码', validator: validatePass2, trigger: 'change' }]
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          console.log('values', formState, toRaw(formState));
          const res = await doEdit(formState)
          if (res.code == 0) {
            Message({ type: 'success', message: '修改成功,重新登录' })
            setTimeout(() => {
              logoutBtn()
            }, 1000)
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    const logoutBtn = () => {
      // const res = await logOut()
      // 1. 清空用户信息
      store.commit('user/setToken', '')
      store.commit('user/setUser', {})
      store.commit('user/setKeyword', '')
      store.commit('user/clearCart', [])
      // 2. 跳转到登录页
      router.push('/login')
    }
    return {
      formRef,
      formState,
      rules,
      onSubmit,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      logoutBtn
    }
  }
}
</script>
<style scoped lang="less">
.restpwd {
  width: 940px;
  min-height: 732px;
  .restpwd-header {
    width: 100%;
    height: 56px;
    background: #ffffff;
    padding: 16px 40px;
    font-size: 16px;
  }
  .restpwd-content {
    width: 100%;
    min-height: 670px;
    background: #ffffff;
    margin-top: 6px;
    padding: 43px 87px 0 54px;
  }
}
</style>