// 个人中心模块
import accountList from '@/utils/account.js';
export default {
  namespaced: true,
  state() {
    const state = {
      foo: null,
      bar: 233,
      baz: 'Hello Vuex',
    };
    return {
      // 用户信息
      list: [],
    };
  },
  mutations: {
    // 修改用户信息，payload就是用户信息对象
    setList(state, payload) {
      state.list = payload;
    }
  },
};
