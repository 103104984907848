<template>
  <div class="recharge">
    <XtxBread class="xtx-bread">
      <XtxBreadItem
        to="/account/my-wmbeans"
        class="xtx-bread-item"
        norColor="#17233D"
      >
        我的万谋豆
      </XtxBreadItem>
      <transition name="fade-right" mode="out-in"> 充值 </transition>
    </XtxBread>
    <div class="recharge_top">
      <div style="display: flex">
        <img :src="userinfo.headimg" alt="" srcset="" />
        <p class="recharge_top_p">
          <span>{{ userinfo.nickname }}</span>
          <span>万谋豆余额:{{ userinfo.balance_rest }}</span>
        </p>
      </div>
      <router-link to="/account/wmbeans-record">
        <p style="margin-top: 30px; color: #296df1">充值记录></p>
      </router-link>
    </div>
    <div>
      <a-row style="margin-top: 39px">
        <a-col :span="3">充值金额：</a-col>
        <a-col :span="21">
          <!-- <a-radio-group v-model:value="value1" @change="change">
            <a-radio-button
              :value="item.id"
              v-for="(item, index) in List"
              :key="index"
              style="position: absolute; left: 10px; top: 0"
            >
              <span>{{ item.price }}万谋豆</span>
              <span class="tag">赠送{{ item.give }}</span>
            </a-radio-button>
          </a-radio-group> -->
          <div class="recharge_son">
            <div
              :value="item.id"
              v-for="(item, index) in List"
              :key="index"
              class="son_content"
              :class="{ active: choseIndex == index }"
              @click="change(item, index)"
            >
              <span>{{ item.price }}元</span>
              <span class="tag" v-if="item.give > 0"
                >赠{{ item.give }}个万谋豆</span
              >
            </div>
          </div>
          <p>
            充值说明：1个万谋豆的价值等同于1元人民币，首次和非首次充值分别送10%和5%的万谋豆
          </p>
        </a-col>
      </a-row>
      <a-row style="margin-top: 39px">
        <a-col :span="3">充值方式：</a-col>
        <a-col :span="15">
          <a-radio-group v-model:value="pay_way" @change="changeWay">
            <a-radio :value="1">
              <img
                src="@/assets/images/zhifubao.png"
                alt=""
                style="width: 82px; height: 29px"
              />
            </a-radio>
            <a-radio :value="2">
              <img
                src="@/assets/images/weixin@2x.png"
                mode=""
                style="width: 97px; height: 24px"
              />
            </a-radio>
            <a-radio :value="3">
              <img
                src="@/assets/images/gonghu.png"
                mode=""
                style="width: 97px; height: 24px"
              />
            </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row style="margin-top: 39px">
        <a-col :span="3">应付金额：</a-col>
        <a-col :span="15" style="color: #ff3c31">￥{{ price }} </a-col>
      </a-row>
      <a-row style="margin-top: 39px">
        <a-col :span="3"></a-col>
        <a-col :span="15">
          <a-button type="primary" @click="recharge">立即充值</a-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { balanceDiscountList, charge, userInfo } from "@/api/user";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Message from "@/components/library/Message";
export default {
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const value1 = ref("a");
    const List = ref();
    const rechargeId = ref();
    const price = ref("");
    const pay_way = ref(1);
    const balance = ref();
    const userinfo = ref({});
    const choseIndex = ref("");
    const qrcodeUrl = ref("");
    const getList = async () => {
      const res = await balanceDiscountList();
      List.value = res.data;
      value1.value = List.value[0].id;
      price.value = List.value[0].price;
      balance.value = List.value[0].give;
      rechargeId.value = List.value[0].id;
    };
    const user = async () => {
      const res = await userInfo();
      if (res.code == 0) {
        store.commit("user/setUser", res.data);
      }
      console.log(store.state.user.userinfo);
      userinfo.value = store.state.user.userinfo;
    };
    const change = (item, index) => {
      choseIndex.value = index;
      List.value.forEach((element) => {
        if (element.id == item.id) {
          price.value = element.price;
          balance.value = element.give;
          rechargeId.value = element.id;
        }
      });
    };
    const changeWay = (e) => {
      console.log(e);
      pay_way.value = e.target.value;
    };
    const recharge = async () => {
      const res = await charge({
        id: rechargeId.value,
        price: price.value,
        balance: balance.value,
        pay_way: pay_way.value,
      });
      if (res.code == 0) {
        if (pay_way.value == 1) {
          const div = document.createElement("div");
          console.log(div);
          /* 下面的data.content就是后台返回接收到的数据 */
          div.innerHTML = res.code_url;
          console.log(div);
          document.body.appendChild(div);
          document.forms[0].submit();
        } else if (pay_way.value == 2) {
          qrcodeUrl.value = res.code_url;
          console.log(qrcodeUrl.value);
          const details = {
            qrcodeUrl: qrcodeUrl.value,
            order_no: res.order_no,
            price: price.value,
          };
          router.push({
            path: "/order/weixinPay",
            query: { data: encodeURIComponent(JSON.stringify(details)) },
          });
        } else {
          // Message({ type: "success", message: "等待后台确认" });
          if (pay_way.value == 3) {
            const details = {
              order_no: res.order_no,
              payment_amount: price.value,
            };
            router.push({
              path: "/order/submit",
              query: {
                isGongHu: true,
                data: encodeURIComponent(JSON.stringify(details)),
              },
            });
            return;
          }
        }
        user();
      }
    };
    onMounted(() => {
      getList();
      user();
    });
    return {
      List,
      getList,
      value1,
      change,
      price,
      pay_way,
      rechargeId,
      balance,
      changeWay,
      recharge,
      userinfo,
      user,
      choseIndex,
      qrcodeUrl,
    };
  },
};
</script>
<style scoped lang="less">
.recharge {
  width: 940px;
  min-height: 732px;
  background: #ffffff;
  padding: 30px 40px;
  .recharge_top {
    width: 522px;
    height: 90px;
    background: #fafbff;
    border: 1px solid #ebf2ff;
    margin-top: 30px;
    padding: 18px 35px;
    display: flex;
    justify-content: space-between;
    img {
      width: 54px;
      height: 54px;
      margin-right: 28px;
      border-radius: 50%;
    }
    .recharge_top_p {
      display: flex;
      flex-direction: column;
    }
  }
}
/deep/.ant-radio-button-wrapper {
  width: 88px;
  height: 56px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  margin-right: 20px;
  margin-bottom: 15px;
  text-align: center;
  line-height: 56px;
  // position: relative;
}
.recharge_son {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .son_content {
    width: 97px;
    height: 62px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    .tag {
      padding: 2px 7px;
      // height: 16px;
      background: #ff3c31;
      border-radius: 4px 4px 4px 0px;
      position: absolute;
      top: -8px;
      right: -16px;
      font-size: 10px;
      color: #ffffff;
    }
    &.active {
      border: 1px solid #296df1;
    }
  }
}
</style>
