<template>
  <div>
    <LoginHeader></LoginHeader>
    <section class="login-section">
      <div class="wrapper" v-if="is_bind == 1">
        <nav v-if="status == 'login'">
          <a
            href="javascript:;"
            @click="activeName = 'accountLogin'"
            :class="{ active: activeName === 'accountLogin' }"
          >
            账户登录
          </a>
          <a
            href="javascript:;"
            @click="activeName = 'otherLogin'"
            :class="{ active: activeName === 'otherLogin' }"
          >
            手机登录
          </a>
        </nav>
        <nav v-else>
          <a
            href="javascript:;"
            @click="activeName = 'register'"
            :class="{ active: activeName === 'register' }"
          >
            注册账号
          </a>
        </nav>
        <!-- 账号登录 -->
        <div v-if="activeName === 'accountLogin'" class="account-box">
          <LoginForm
            :activeName="activeName"
            @changeStatus="changeStatus"
          ></LoginForm>
          <!-- 表单 -->
        </div>
        <!-- 手机登录 -->
        <div v-if="activeName === 'otherLogin'" class="account-box">
          <LoginForm
            :activeName="activeName"
            @changeStatus="changeStatus"
          ></LoginForm>
        </div>
        <!-- 注册 -->
        <div v-if="activeName === 'register'" class="account-box">
          <LoginForm :activeName="activeName" @goLogin="goLogin"></LoginForm>
        </div>
      </div>
      <div class="bindaccount" v-else>
        <p style="font-size: 18px">继续以完成第三方账号绑定</p>
        <p class="bindaccount_p">
          <img
            src="@/assets/images/accreditlogo.png"
            alt=""
            srcset=""
            class="img1"
          />
          <img
            src="@/assets/images/transition.png"
            alt=""
            srcset=""
            style="width: 22px; height: 20px; margin: 0 27px"
          />
          <img
            v-if="state == 'weixin'"
            src="@/assets/images/accreditweixin.png"
            alt=""
            srcset=""
            class="img1"
          />
          <img
            v-else-if="state == 'qq'"
            src="@/assets/images/03_qq_symbol.png"
            alt=""
            srcset=""
            class="img1"
          />
          <img
            v-else-if="state == 'ali'"
            src="@/assets/images/alipay@2x.png"
            alt=""
            srcset=""
            class="img1"
          />
        </p>
        <p style="font-size: 14px; margin-top: 19px; margin-bottom: 32px">
          你已通过
          {{ state == "qq" ? "QQ" : state == "weixin" ? "微信" : "支付宝" }}
          授权，完善以下登录信息即可完成绑定
        </p>
        <div v-if="!hasUser">
          <div class="form-item">
            <div class="input">
              <a-input placeholder="手机号" v-model:value="form.account" />
            </div>
          </div>
          <div class="form-item">
            <div class="input">
              <a-input placeholder="请输入密码" v-model:value="form.password" />
            </div>
          </div>
          <div class="form-item">
            <div class="input">
              <a-input placeholder="请输入验证码" v-model:value="form.auth" />
              <span class="code" @click="sendCodeBtn()">
                {{ number === 0 ? "发送验证码" : `${number}秒后发送` }}
              </span>
            </div>
          </div>
        </div>

        <a-button type="primary" style="width: 360px" @click="bindBtn">
          登录并绑定已有账号
        </a-button>
      </div>
    </section>

    <AppFooter />
  </div>
</template>

<script>
import LoginHeader from "./components/login-header.vue";
import AppFooter from "@/components/app-footer.vue";
import { ref, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import store from "@/store";
import LoginForm from "./components/login-form.vue";
import { useIntervalFn } from "@vueuse/shared";
import Message from "@/components/library/Message";
import schema from "@/utils/vee-validate-schema";
import {
  sendCode,
  wxLogin,
  bindWx,
  bindQQ,
  bindQQWidthToken,
  bindAli,
  userInfo,
  aliLoginCde,
  qqLogin,
} from "@/api/user";
export default {
  name: "Login",
  components: { LoginHeader, AppFooter, LoginForm },
  setup() {
    const status = ref("login");
    const activeName = ref("accountLogin");
    const router = useRouter();
    const number = ref(0);
    const code = ref();
    const state = ref("");
    const token = ref("");
    const hasUser = ref(false);

    const ali_code = ref("");

    const is_bind = ref(1);
    const openid = ref();
    const store = useStore();
    const form = reactive({
      account: "",
      password: "",
      auth: "",
    });
    const changeStatus = (e) => {
      console.log(e);
      status.value = e;
      activeName.value = e;
    };
    const goLogin = () => {
      status.value = "login";
      activeName.value = "accountLogin";
    };
    const { resume, pause } = useIntervalFn(
      () => {
        number.value--;
        if (number.value <= 0) {
          pause();
        }
      },
      1000,
      { immediate: false }
    );
    const sendCodeBtn = async () => {
      // 校验手机号码 form.account
      let mobileChecked = "";
      mobileChecked = schema.mobile(form.account);
      if (mobileChecked === true) {
        // 🔔上一个倒计时没有结束，直接退出
        if (number.value !== 0) return;
        try {
          const res = await sendCode({
            phone: form.account,
            type: 3,
          });
          if (res.code == 0) {
            Message({ type: "success", message: "验证码发送成功" });
            // 倒计时秒数重置
            number.value = 60;
            // 调用倒计时
            resume();
            // 发送验证码
          }
        } catch (err) {
          console.log(err.response);
          Message({ type: "error", message: "验证码已发送，稍后再试" });
        }
      } else {
        Message({ type: "error", message: mobileChecked });
      }
    };
    const weixinLogin = async () => {
      const res = await wxLogin({
        code: code.value,
      });
      console.log(res);
      if (res.code == 0) {
        openid.value = res.data.openid;
        is_bind.value = res.data.is_bind;
        if (is_bind.value == 1) {
          // 1. 存储 token 信息
          store.commit("user/setToken", res.data.token);
          const userinfo = await userInfo();
          store.commit("user/setUser", userinfo.data);
          // 2. 页面跳转（两种情况：1.回跳到某个页面 2.跳转首页）
          // router.currentRoute.value 获取当前路由
          const redirectUrl = router.currentRoute.value.query.redirectUrl;
          router.push(redirectUrl || "/");
        }
      } else {
        Message({ message: "请重新授权登录" });
      }
    };
    const bindBtn = async () => {
      console.log("state.value", state.value);
      if (!hasUser.value) {
        if (!form.account) {
          Message({ message: "请输入手机号" });
          return;
        }
        if (!form.password) {
          Message({ message: "请输入密码" });
          return;
        }
        if (!form.auth) {
          Message({ message: "请输入验证码" });
          return;
        }
      }
      if (state.value == "weixin") {
        const res = await bindWx({
          ...form,
          openid: openid.value,
          code: code.value,
        });
        if (res.code == 0) {
          Message({ type: "success", message: "绑定成功" });
          // 1. 存储 token 信息
          store.commit("user/setToken", res.data.token);
          const userinfo = await userInfo();
          store.commit("user/setUser", userinfo.data);
          // 2. 页面跳转（两种情况：1.回跳到某个页面 2.跳转首页）
          // router.currentRoute.value 获取当前路由
          const redirectUrl = router.currentRoute.value.query.redirectUrl;
          router.push(redirectUrl || "/");
        }
      } else if (state.value == "qq") {
        if (token.value) {
          const res = await bindQQWidthToken({
            ...form,
            openid: openid.value,
          });
          if (res.code == 0) {
            const userinfo = await userInfo();
            store.commit("user/setUser", userinfo.data);
            router.push("/account/bindaccount");
          }
        } else {
          const res = await bindQQ({
            ...form,
            openid: openid.value,
          });
          if (res.code == 0) {
            Message({ type: "success", message: "绑定成功" });
            console.log("---res", res);
            // 1. 存储 token 信息
            store.commit("user/setToken", res.data.token);
            const userinfo = await userInfo();
            store.commit("user/setUser", userinfo.data);
            // 2. 页面跳转（两种情况：1.回跳到某个页面 2.跳转首页）
            // router.currentRoute.value 获取当前路由
            const redirectUrl = router.currentRoute.value.query.redirectUrl;
            router.push(redirectUrl || "/");
          }
        }
      } else if (state.value == "ali") {
        const res = await bindAli({
          ...form,
          openid: openid.value,
        });
        if (res.code == 0) {
          Message({ type: "success", message: "绑定成功" });
          console.log("---res", res);
          // 1. 存储 token 信息
          store.commit("user/setToken", res.data.token);
          const userinfo = await userInfo();
          store.commit("user/setUser", userinfo.data);
          // 2. 页面跳转（两种情况：1.回跳到某个页面 2.跳转首页）
          // router.currentRoute.value 获取当前路由
          const redirectUrl = router.currentRoute.value.query.redirectUrl;
          router.push(redirectUrl || "/");
        }
      }
    };
    onMounted(async () => {
      console.log(
        "router.currentRoute.value.query",
        router.currentRoute.value.query
      );
      token.value = store.state.user.token;
      console.log("[--token", token);

      // 如果已绑定qq 2 就返回了token
      if (
        router.currentRoute.value.query &&
        router.currentRoute.value.query.token &&
        router.currentRoute.value.query.is_bind
      ) {
        token.value = router.currentRoute.value.query.token;
        is_bind.value = router.currentRoute.value.query.is_bind;
        if (router.currentRoute.value.query.is_bind == 1) {
          // 1. 存储 token 信息
          store.commit("user/setToken", router.currentRoute.value.query.token);
          const userinfo = await userInfo();
          store.commit("user/setUser", userinfo.data);
          // 2. 页面跳转（两种情况：1.回跳到某个页面 2.跳转首页）
          const redirectUrl = router.currentRoute.value.query.redirectUrl;
          router.push(redirectUrl || "/");
        }
      }

      // qq登录1
      if (
        router.currentRoute.value.query &&
        router.currentRoute.value.query.openid &&
        router.currentRoute.value.query.is_bind
      ) {
        openid.value = router.currentRoute.value.query.openid;
        is_bind.value = router.currentRoute.value.query.is_bind;
        state.value = router.currentRoute.value.query.state;
        if (
          store.state.user.token &&
          router.currentRoute.value.query.openid &&
          router.currentRoute.value.query.is_bind == 2
        ) {
          hasUser.value = true;
        }
      }

      //如果已绑定ali 就返回了token
      if (
        router.currentRoute.value.query &&
        router.currentRoute.value.query.auth_code &&
        router.currentRoute.value.query.ali_state
      ) {
        ali_code.value = router.currentRoute.value.query.auth_code;
        const aliRes = await aliLoginCde({
          auth_code: router.currentRoute.value.query.auth_code,
          ali_state: router.currentRoute.value.query.ali_state,
        });
        console.log("aliRes.data", aliRes);
        if (aliRes.code == 0) {
          // 从用户中心去的
          if (aliRes.data == 1) {
            const userinfo = await userInfo();
            store.commit("user/setUser", userinfo.data);
            router.push("/account/bindaccount");
          } else {
            is_bind.value = aliRes.data.is_bind;
            openid.value = aliRes.data.openid;
            token.value = aliRes.data.token;
            state.value = "ali";
            if (aliRes.data.token) {
              store.commit("user/setToken", aliRes.data.token);
              const userinfo = await userInfo();
              store.commit("user/setUser", userinfo.data);
              router.push("/");
            }
          }
        }
        console.log("--aliRes", aliRes);
      }

      //  wx登录
      if (
        router.currentRoute.value.query &&
        router.currentRoute.value.query.code
      ) {
        code.value = router.currentRoute.value.query.code;
        state.value = router.currentRoute.value.query.state;
        weixinLogin();
      }
    });
    return {
      activeName,
      status,
      changeStatus,
      goLogin,
      sendCodeBtn,
      number,
      code,
      state,
      weixinLogin,
      is_bind,
      openid,
      form,
      bindBtn,
      ali_code,
      hasUser,
    };
  },
};
</script>

<style scoped lang="less">
.login-section {
  width: 100%;
  background: url(~@/assets/images/login-bg.png) no-repeat center / cover;
  height: 872px;
  position: relative;
  padding-top: 151px;
  .wrapper {
    width: 390px;
    background: #fff;
    min-height: 480px;
    margin: 0 auto;
    // position: absolute;
    // left: 50%;
    // margin-left: -390px;
    // top: 120px;
    transform: translate3d(100px, 0, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    nav {
      height: 55px;
      border-bottom: 1px solid #f5f5f5;
      display: flex;
      justify-content: space-between;
      padding: 40px 50px 24px 50px;
      // text-align: right;
      align-items: center;
      a {
        width: 380px;
        height: 55px;
        display: block;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        // position: relative;
        // &:first-child {
        //   border-right: 1px solid #f5f5f5;
        //   text-align: left;
        // }
        &.active {
          color: @xtxColor;
          font-weight: bold;
          border-bottom: 2px solid @xtxColor;
        }
      }
    }
    // 二维码容器
    .qrcode-box {
      text-align: center;
      padding-top: 40px;
      p {
        margin-top: 20px;
        a {
          // color: @xtxColor;
          font-size: 16px;
        }
      }
    }
  }
}
.bindaccount {
  width: 990px;
  height: 560px;
  background: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -495px;
  margin-top: -290px;
  z-index: 100;
  padding-top: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .bindaccount_p {
    display: flex;
    align-items: center;
    margin-top: 23px;
    .img1 {
      width: 50px;
      height: 50px;
    }
  }
}
.form {
  width: 359px;
  &-item {
    width: 359px;
    margin-bottom: 16px;
    .input {
      width: 100%;
      position: relative;
      height: 40px;
      > i {
        width: 34px;
        height: 34px;
        background: #cfcdcd;
        color: #fff;
        position: absolute;
        left: 1px;
        top: 1px;
        text-align: center;
        line-height: 34px;
        font-size: 18px;
      }
      input {
        padding-left: 10px;
        border: 1px solid #cfcdcd;
        height: 36px;
        line-height: 36px;
        width: 100%;
        &.error {
          border-color: @priceColor;
        }
        &.active,
        &:focus {
          border-color: @xtxColor;
        }
      }
      .code {
        position: absolute;
        right: 1px;
        top: 1px;
        text-align: center;
        line-height: 34px;
        font-size: 14px;
        background: #f5f5f5;
        color: #666;
        width: 90px;
        height: 34px;
        cursor: pointer;
      }
    }
  }
}
</style>
