<template>
  <div class="evaluate—details">
    <div class="details">
      <div class="details_top">
        <p style="font-size: 18px; font-weight: 700">评价订单</p>
        <p style="color: #808695; margin-top: 20px">
          <span>订单号：{{ data.order_no }}</span>
          <span style="margin-left: 26px">{{ data.create_at }}</span>
        </p>
      </div>
      <div class="details_content">
        <div class="content_left">
          <img :src="data.goods_cover" alt="" srcset="" />
          <p style="font-size: 20px">{{ data.goods_name }}</p>
          <p style="margin-top: 4px; color: #808695">
            {{ data.goods_spec }}
          </p>
        </div>
        <div class="content_right">
          <a-row>
            <a-col :span="3">满意度：</a-col>
            <a-col
              :span="12"
              style="display: flex; justify-content: space-between"
            >
              <p
                v-for="(item, index) in mark"
                :key="index"
                @click="chosemark(item, index)"
              >
                <img
                  :src="choseIndex == index ? item.activeIcon : item.natureIcon"
                  style="width: 24px; height: 24px"
                />
                <span>{{ item.text }}</span>
              </p>
            </a-col>
          </a-row>
          <a-row style="margin-top: 33px">
            <a-col :span="3">评价内容：</a-col>
            <a-col :span="15">
              <a-textarea
                v-model:value="content"
                placeholder=""
                style="height: 170px"
              />
            </a-col>
          </a-row>
          <a-row style="margin-top: 23px">
            <a-col :span="3"></a-col>
            <a-col
              :span="12"
              style="display: flex; justify-content: space-between"
            >
              <a-checkbox v-model:checked="is_anonymous" @change="change"
                >匿名评价</a-checkbox
              >
              <a-button type="primary" @click="submit">立即提交</a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { comment } from '@/api/user'
import { useRoute } from 'vue-router'
import Message from '@/components/library/Message'
export default ({
  setup () {
    const route = useRoute()
    const data = ref({})
    const type = ref('1')
    const is_anonymous = ref('1')
    const content = ref('')
    const choseIndex = ref('')
    const mark = ref([{
      natureIcon: require('@/assets/images/evaluate1.png'),
      activeIcon: require('@/assets/images/evaluate1.1.png'),
      text: '好评',
      status: 1
    }, {
      natureIcon: require('@/assets/images/evaluate2.png'),
      activeIcon: require('@/assets/images/evaluate2.1.png'),
      text: '中评',
      status: 2
    },
    {
      natureIcon: require('@/assets/images/evaluate3.png'),
      activeIcon: require('@/assets/images/evaluate3.1.png'),
      text: '差评',
      status: 3
    }
    ])
    const chosemark = (item, index) => {
      choseIndex.value = index
      type.value = item.status
    }
    const change = (e) => {
      console.log(e)
    }
    const submit = async () => {
      const res = await comment({
        id: data.value.fid,
        item_id: data.value.id,
        type: type.value,
        is_anonymous: is_anonymous.value,
        content: content.value
      })
      if (res.code == 0) {
        Message({ type: 'success', message: '提交成功' })
        content.value = 0
      }
    }
    onMounted(() => {
      console.log(JSON.parse(decodeURIComponent(route.query.data)))
      data.value = JSON.parse(decodeURIComponent(route.query.data))
    })
    return {
      mark,
      data,
      type,
      is_anonymous,
      content,
      choseIndex,
      chosemark,
      change,
      submit
    }
  },
})
</script>

<style scoped lang="less">
.evaluate—details {
  width: 100%;
  padding-top: 70px;
  .details {
    width: 1000px;
    min-height: 400px;
    margin: 0 auto;
    .details_top {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .details_content {
      width: 100%;
      min-height: 400px;
      background: #ffffff;
      margin-top: 21px;
      display: flex;
      .content_left {
        width: 342px;
        padding: 60px 76px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 190px;
          height: 160px;
          // border: 1px solid saddlebrown;
          margin-bottom: 20px;
        }
      }
      .content_right {
        flex: 1;
        margin-left: 11px;
        padding: 60px 0;
      }
    }
  }
}
</style>