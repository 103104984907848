<template >
  <div class="details">
    <div class="details-header">
      <router-link to="/account/complaint"> 返回 </router-link>
    </div>
    <div class="deatils-content">
      <p>
        <span>投诉处理结果</span>
        <span style="color: #515a6e; margin-left: 24px"></span>
      </p>
      <p style="margin-top: 11px; color: #515a6e; text-indent: 2em">
        {{ details.content }}
      </p>
      <div class="details-bottom">
        <a-row>
          <a-col :span="3">问题描述：</a-col>
          <a-col :span="21" style="color: #808695">{{ details.content }}</a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :span="3">附件：</a-col>
          <a-col :span="21" style="color: #808695">
            <a :href="details.attach">
              <img
                src="@/assets/images/icondoc@2x.png"
                alt=""
                style="width: 22px; height: 22px; margin-right: 10px"
              />
            </a>
            <!-- 文件名称 -->
          </a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :span="3">联系方式：</a-col>
          <a-col :span="21" style="color: #808695">{{ details.mobile }}</a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :span="3">提交时间：</a-col>
          <a-col :span="21" style="color: #808695">{{
            details.create_at
          }}</a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'
import { complainDetail } from '@/api/user'
export default {
  setup () {
    const route = useRoute()
    const details = ref({})
    watch(
      () => route.params.id,
      async id => {
        if (route.path !== `/account/complaint-details/${id}`) return
        const result = await complainDetail(id)
        console.log(result)
        if (result.code == 0) {
          details.value = result.data
        }
      },
      { immediate: true },
    )
    return {
      details
    }
  }
}
</script>
<style scoped lang="less">
.details {
  width: 940px;
  min-height: 860px;
  background: #ffffff;
  padding: 40px;
  .details-header {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #f1f1f4;
    font-size: 16px;
  }
  .deatils-content {
    width: 100%;
    padding: 40px;
  }
  .details-bottom {
    width: 100%;
    height: 276px;
    background: #f8f8f9;
    margin-top: 30px;
    padding: 40px;
  }
}
</style>