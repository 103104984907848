import { createApp } from 'vue';
import App from './App.vue';
// 导入VueRouter路由实例
import router from './router';
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})
// 导入VueX仓库实例
import store from './store';
// 导入自己封装的组件库
import XtxUI from '@/components/library';
//导入Antd
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// 导入重置的包，全局引入
import 'normalize.css';
// 引入重置样式，项目公用样式
import '@/assets/styles/common.less';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
// 插件的使用，在main.js使用app.use(插件)
// 创建 Vue 应用实例
const app = createApp(App);
// 安装自己的组件库
// app.use(XtxUI)
// 使用仓库实例
app.use(store);
// 使用路由实例
app.use(router);
// 安装自己的组件库
app.use(XtxUI);
//使用ElementUI
app.use(Antd);
// 把Vue应用实例挂载到 #app 中
app.mount('#app');
