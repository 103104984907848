<template>
  <div class="xtx-goods-page">
    <div class="container">
      <!-- 面包屑 -->
      <XtxBread>
        <XtxBreadItem to="/" norColor="#808695">
          <img src="@/assets/images/home@2x.png" alt="" class="xtx-goods_img" />
          首页
        </XtxBreadItem>
        <XtxBreadItem>法律协助服务</XtxBreadItem>
        <!-- <XtxBreadItem>{{ goods.name }}</XtxBreadItem> -->
      </XtxBread>
      <!-- 产品信息 -->
      <div class="goods-info">
        <!-- 产品信息左边 -->
        <div class="goods-info-left">
          <GoodsImage :images="images"></GoodsImage>
        </div>
        <!-- 产品信息右边 -->
        <div class="goods-info-right">
          <p class="goods-info-p">
            <span class="title">{{ goods.name }}</span>
            <span>
              <img
                src="@/assets/images/mark_1@2x.png"
                alt=""
                v-if="goods.is_collect == 1"
                @click="Collect('2')"
              />
              <img
                v-else
                src="@/assets/images/mark@2x.png"
                alt=""
                srcset=""
                @click="Collect('1')"
              />
              <text>收藏产品</text>
            </span>
          </p>
          <p style="font-size: 14px; color: #808695; margin-top: 4px">
            产品编码：{{ goods.code }}
          </p>
          <div class="goods-info-price">
            <p>
              <span>市 场 价：</span
              ><span style="text-decoration: line-through"
                >￥{{ goods.price_market }}-{{ goods.max_price_market }}</span
              >
            </p>
            <p style="margin-top: 10px">
              <span>价 格：</span>
              <span
                v-if="price"
                style="font-size: 26px; font-weight: 700; color: #ff3c31"
                >{{ price }}</span
              >
              <span
                v-else
                style="font-size: 26px; font-weight: 700; color: #ff3c31"
                >￥{{ goods.price_selling }}-{{ goods.max_price_selling }}</span
              >
            </p>
          </div>
          <!-- SKU 产品规格选择组件 -->
          <GoodsSku
            :key="goods.id"
            :goods="goodsSku"
            @change="changeSku"
          ></GoodsSku>
          <XtxNumbox
            label="购买数量"
            :min="1"
            @changeblur="changeNum"
            @minus="minusNum"
            @add="addNum"
          />
          <!-- 假如购物车 购买 -->
          <div class="goods-info-btn">
            <p
              class="goods-info-btn1 btn-color1"
              @click="buy"
              :class="{ 'btn-color3': status == 0 }"
            >
              立即购买
            </p>
            <p
              class="goods-info-btn1 btn-color2"
              @click="addToCartBtn"
              :class="{ 'btn-color4': status == 0 }"
            >
              <img
                src="@/assets/images/cart@2x(1).png"
                alt=""
                v-if="status != 0"
              />
              加入购物车
            </p>
          </div>
        </div>
      </div>
      <div>
        <div class="card-container">
          <a-tabs v-model:activeKey="activeKey" type="card" @change="change">
            <a-tab-pane key="1" tab="产品介绍">
              <p v-html="goods.remark"></p>
            </a-tab-pane>
            <a-tab-pane key="2" tab="产品说明">
              <p v-html="goods.content"></p>
            </a-tab-pane>
            <a-tab-pane key="3" tab="用户评价">
              <div style="width: 100%" v-if="evaluateList.length > 0">
                <div class="header">
                  <p class="content_right">用户</p>
                  <p class="content_right">评价类型</p>
                  <p class="content_left">评价内容</p>
                </div>
                <div
                  class="evaluate_content"
                  v-for="(item, index) in evaluateList"
                  :key="index"
                >
                  <div class="evaluate_content_son">
                    <p class="content_right">{{ item.phone }}</p>
                    <p class="content_right">
                      {{
                        item.type == 1
                          ? "好评"
                          : item.type == 2
                          ? "中评"
                          : "差评"
                      }}
                    </p>
                    <p class="content_left" style="flex-direction: column">
                      <span>{{ item.content }}</span>
                      <span>{{ item.create_at }}</span>
                    </p>
                  </div>
                  <div class="evaluate_reply" v-if="item.reply_list.length > 0">
                    <div
                      style="margin-bottom: 10px"
                      v-for="(content, cindex) in item.reply_list"
                      :key="cindex"
                    >
                      <p>平台回复：</p>
                      <p>
                        {{ content.reply }}
                      </p>
                      <p>
                        {{ content.create_at }}
                      </p>
                    </div>
                    <p id="triangle-top"></p>
                  </div>
                </div>
                <XtxPagination
                  v-if="total"
                  :total="total"
                  :current-page="currentPage"
                  :page-size="pageSize"
                  @current-change="getCurrentPage"
                />
              </div>
            </a-tab-pane>
            <a-tab-pane key="4" tab="常见问题">
              <p v-html="goods.problem"></p>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 💥注意这里的接口导入文件
import { goodDetail, commentList, getAttrsPrice } from '@/api/FirstPage'
import { saveCollect, addCart, getCartList } from '@/api/user'
import { useRoute, useRouter } from 'vue-router'
import { ref, watch, onMounted } from 'vue'
import GoodsImage from './components/goods-image.vue'
import GoodsSku from './components/goods-sku.vue'
import Message from '@/components/library/Message'
import { useStore } from 'vuex'
export default {
  name: 'XtxGoodsPage',
  components: { GoodsImage, GoodsSku },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const count = ref(1)
    const goods = ref({})
    const images = ref([])
    const goodsSku = ref()
    const goodsId = ref()
    const price = ref()
    const skuId = ref()
    const status = ref()
    // 注意单页面应用路由变化，需要重新获取产品详情
    watch(
      () => route.params.id,
      id => {
        goodsId.value = id
        // 如果不是产品详情页不处理
        if (route.path !== `/product/${id}`) return
      },
      { immediate: true },
    )
    // 评论总条数
    const total = ref()
    // 当前页码
    const currentPage = ref(1)
    //一页几条
    const pageSize = ref(15)
    const evaluateList = ref([])
    const goods_spec = ref('')
    const changeNum = (newValue) => {
      console.log(newValue)
      count.value = newValue
    }
    const minusNum = (newValue) => {
      console.log(newValue)
      count.value = newValue
    }
    const addNum = (newValue) => {
      console.log(newValue)
      count.value = newValue
    }
    const getCurrentPage = val => {
      console.log('当前子组件的页面为', val)
      currentPage.value = val
      getCommentList()
    }
    const details = async (id) => {
      images.value = []
      const result = await goodDetail(id)
      console.log(result)
      if (result.code == 0) {
        goods.value = result.data
        goodsSku.value = JSON.parse(result.data.data_specs)
        goodsSku.value.forEach(element => {
          element.selected = false
        });
        console.log(goodsSku)
        console.log(result.data.slider)
        if (result.data.slider.indexOf("|") != -1) {
          images.value = result.data.slider.split('|')
          console.log(images)
        } else {
          images.value.push(result.data.slider)
          console.log(images)
        }
      }
    }
    const getCommentList = async () => {
      const res = await commentList({
        id: goodsId.value,
        page: currentPage.value,
        pagesize: pageSize.value
      })
      console.log(res)
      evaluateList.value = res.data.list
      total.value = res.data.total
    }
    const Collect = async (type) => {
      const res = await saveCollect({
        goods_id: goodsId.value,
        state: type
      })
      if (res.code == 0) {
        details(goodsId.value)
      }
    }
    const changeSku = (theItem, val) => {
      let arry = []
      // console.log(theItem)
      // console.log(val)
      theItem.forEach(item => {
        item.list.forEach(content => {
          if (content.selected) {
            arry.push(
              `${item.name}::${content.name};;`
            )
          }
        })
      })
      console.log(arry.join(''))
      goods_spec.value = arry.join('')
      if (goods_spec.value != '') {
        getPrice()
      }
    }
    const getPrice = async () => {
      const res = await getAttrsPrice({
        goods_id: goodsId.value,
        goods_code: goods.value.code,
        goods_spec: goods_spec.value
      })
      if (res.code == 0) {
        if (res.data) {
          price.value = res.data.price_selling
          skuId.value = res.data.id
          status.value = res.data.status
        }
      }
    }
    const buy = () => {
      if (status.value == 0) return
      if (!skuId.value) return Message({ type: 'warn', message: '请选择完整规格' })
      const data = {
        id: goodsId.value,
        skuId: skuId.value,
        count: count.value,
        type: 'buy'
      }
      router.push({
        path: '/order/details', query: { data: encodeURIComponent(JSON.stringify(data)) }
      })
    }
    const addToCartBtn = async () => {
      console.log(count.value)
      console.log(skuId.value)
      if (status.value == 0) return
      if (!skuId.value) return Message({ type: 'warn', message: '请选择完整规格' })
      const res = await addCart({
        goods_id: goodsId.value,
        goods_code: goods.value.code,
        price: price.value,
        number: count.value,
        goods_spec: goods_spec.value
      })
      if (res.code == 0) {
        Message({ type: 'success', message: '添加成功' })
        getList()
      }
    }
    const getList = async () => {
      const res = await getCartList()
      if (res.code == 0) {
        store.commit('user/insertCart', res.data)
      }
    }
    const activeKey = ref('1')
    const change = (val) => {
      console.log(activeKey.value);
    };
    onMounted(() => {
      getCommentList()
      details(goodsId.value)
    })
    return {
      goods,
      count,
      changeSku,
      addToCartBtn,
      activeKey,
      change,
      images,
      goodsSku,
      getCommentList,
      total,
      currentPage,
      pageSize,
      goodsId,
      getCurrentPage,
      evaluateList,
      Collect,
      details,
      goods_spec,
      getPrice,
      price,
      skuId,
      buy,
      getList,
      status,
      changeNum,
      minusNum,
      addNum
    }
  }
}
</script>

<style scoped lang="less">
.xtx-goods-page {
  width: 100%;
  min-height: 235px;
  padding: 15px 0;
  background: #ffffff;
  .container {
    width: 1100px;
    margin: 0 auto;
    // margin-top: 15px;
    .xtx-bread {
      font-size: 14px;
      color: #808695;
    }
    .xtx-goods_img {
      width: 11px;
      height: 10px;
    }
    .goods-info {
      width: 100%;
      min-height: 600px;
      display: flex;
      margin-top: 16px;
      .goods-info-left {
        width: 440px;
        height: 440px;
      }
      .goods-info-right {
        width: 630px;
        min-height: 530px;
        margin-left: 32px;
        .goods-info-p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
          }
          span {
            display: flex;
            align-items: center;
          }
          text {
            font-size: 16px;
            color: #808695;
            margin-left: 4px;
          }
        }
        .goods-info-price {
          width: 100%;
          height: 102px;
          background: #f8f8f9;
          margin-top: 16px;
          padding: 19px 17px;
          font-size: 14px;
          color: #808695;
        }
        .title {
          font-size: 32px;
          font-weight: 700;
        }
        .goods-info-btn {
          display: flex;
          margin-left: 100px;
          margin-top: 20px;
          .goods-info-btn1 {
            width: 162px;
            height: 40px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            img {
              width: 16px;
              height: 16px;
            }
            &.btn-color1 {
              background: #296df1;
              color: #ffffff;
            }
            &.btn-color2 {
              border: 1px solid #296df1;
              color: #296df1;
              margin-left: 12px;
            }
            &.btn-color3 {
              background: #e4e4e4;
            }
            &.btn-color4 {
              border: 1px solid #f8f8f8;
              background: #e4e4e4;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
.card-container {
  overflow: hidden;
  padding: 24px;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}
/deep/.card-container > .ant-tabs-card > .ant-tabs-bar {
  background-color: #f0f0f0;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
  margin-right: 0px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
.header {
  width: 100%;
  height: 51px;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}
.evaluate_content {
  width: 100%;
  min-height: 102px;
  border-bottom: 1px solid #f4f4f4;
  .evaluate_content_son {
    width: 100%;
    min-height: 102px;
    display: flex;
    align-items: center;
  }
  .evaluate_reply {
    width: 100%;
    min-height: 89px;
    background: #f8f8f9;
    border-radius: 4px;
    padding: 19px 21px;
    margin-bottom: 20px;
    margin-left: 20px;
    position: relative;
  }
  #triangle-top {
    width: 0;
    height: 0;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 12px solid #f8f8f9;
    position: absolute;
    left: 48px;
    top: -12px;
  }
}
.content_left {
  width: 50%;
  display: flex;
  // align-items: center;
  // flex-direction: column;
}
.content_right {
  width: 25%;
  display: flex;
  // align-items: center;
  // flex-direction: column;
}
</style>
