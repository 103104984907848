<template>
  <div class="xtx-numbox">
    <a-row>
      <a-col :span="4" v-if="label" class="label"> {{ label }}： </a-col>
      <a-col :span="20">
        <div class="numbox">
          <a @click="minus" href="javascript:;">-</a>
          <input
            type="text"
            :readonly="isreadonly"
            v-model="inputValue"
            @blur="changeblur"
          />
          <a @click="add" href="javascript:;">+</a>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'XtxNumbox',
  props: {
    modelValue: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 10000
    },
    min: {
      type: Number,
      default: 1
    },
    label: {
      type: String,
      default: ''
    },
    isreadonly: {
      type: Boolean
    }
  },
  setup (props, { emit }) {
    const inputValue = ref(1)
    const minusNumber = ref(0)
    const addNumber = ref(0)
    watch(() => inputValue.value, (newValue, oldValue) => {
      console.log(newValue)
      inputValue.value = newValue
    })
    watch(
      props,
      () => {
        console.log(props.modelValue)
        inputValue.value = props.modelValue
      },
      { immediate: true }
    )
    // const btn = (number) => {
    //   const temp = inputValue.value + number
    //   if (temp > props.max || temp < props.min) return
    //   console.log(inputValue.value,number)
    //   // inputValue.value = temp
    //   emit('update:modelValue', temp)
    //   emit('editnum', number)
    // }
    const changeblur = () => {
      emit('changeblur', inputValue.value)
    }
    const minus = () => {
      minusNumber.value--
      if (parseInt(inputValue.value) - 1 < props.min) {
        return;
      }
      inputValue.value = parseInt(inputValue.value) - 1;
      console.log(inputValue.value)
      emit('minus', inputValue.value)
    }
    const add = () => {
      addNumber.value++
      if (parseInt(inputValue.value) + 1 > props.max) {
        return;
      }
      inputValue.value = parseInt(inputValue.value) + 1;
      emit('add', inputValue.value)
    }
    // const change = (e) => {
    //   console.log(props.modelValue)
    // }
    return { minus, add, inputValue, minusNumber, addNumber,changeblur }
  }
}
</script>

<style scoped lang="less">
.xtx-numbox {
  width: 100%;
  // display: flex;
  // align-items: center;
  .label {
    color: #999;
    // text-align:right;
    // margin-right:10px
    // padding-left: 10px;
  }
  .numbox {
    width: 120px;
    height: 30px;
    border: 1px solid #e4e4e4;
    display: flex;
    > a {
      width: 29px;
      line-height: 28px;
      text-align: center;
      background: #f8f8f8;
      font-size: 16px;
      color: #666;
      &:first-of-type {
        border-right: 1px solid #e4e4e4;
      }
      &:last-of-type {
        border-left: 1px solid #e4e4e4;
      }
    }
    > input {
      width: 60px;
      padding: 0 5px;
      text-align: center;
      color: #666;
    }
  }
}
</style>
