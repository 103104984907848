<template>
  <div>
    <div class="account-box">
      <!-- <Form
        ref="formCom"
        class="form"
        :validation-schema="schema"
      > -->
      <div class="form">
        <div class="form-item">
          <span>设置新密码：</span>
          <div class="input">
            <a-input placeholder="设置新密码：" v-model:value="form.new_pwd" />
          </div>
        </div>
        <div class="form-item">
          <span>重复新密码：</span>
          <div class="input">
            <a-input
              placeholder="重复新密码："
              v-model:value="form.new_repwd"
            />
          </div>
        </div>
        <div class="btn-f">
          <a-button type="primary" class="btn" @click="prevStep">
            下一步
          </a-button>
        </div>
      </div>
      <!-- </Form> -->
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import Message from '@/components/library/Message'
export default {
  name: 'LoginForm',
  props: {
    activeName: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    // 用于手机表单数据的响应式对象
    const form = reactive({
      new_pwd: null,
      new_repwd: null
    })
    const prevStep = () => {
      if (!form.new_pwd) {
        Message({ message: '请输入新密码' })
        return
      }
      if (!form.new_repwd) {
        Message({ message: '请再次输入新密码' })
        return
      }
      if (form.new_pwd != form.new_repwd) {
        Message({ type: 'error', message: '两次密码不一致' })
        return
      }
      emit('prevStep', form)
    }
    return { form, prevStep }
  }
}
</script>

<style lang="less" scoped>
.account-box {
  // padding: 24px 0;
  .toggle {
    text-align: right;
    a {
      color: @xtxColor;
      i {
        font-size: 14px;
      }
    }
  }
  .form {
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      .input {
        flex: 1;
        position: relative;
        height: 38px;
        > i {
          width: 34px;
          height: 34px;
          background: #cfcdcd;
          color: #fff;
          position: absolute;
          left: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 18px;
        }
        input {
          padding-left: 10px;
          border: 1px solid #cfcdcd;
          height: 36px;
          line-height: 36px;
          width: 100%;
          &.error {
            border-color: @priceColor;
          }
          &.active,
          &:focus {
            border-color: @xtxColor;
          }
        }
        .code {
          position: absolute;
          right: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 14px;
          background: #f5f5f5;
          color: #666;
          width: 90px;
          height: 34px;
          cursor: pointer;
        }
      }
      > .error {
        position: absolute;
        font-size: 12px;
        line-height: 28px;
        color: @priceColor;
        i {
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }
    .agree {
      a {
        color: #069;
      }
    }
    .btn-f {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn {
      display: block;
      width: 125px;
      height: 32px;
      color: #fff;
      text-align: center;
      background: @xtxColor;
      &.disabled {
        background: #cfcdcd;
      }
    }
  }
}
</style>
