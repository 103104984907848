<template>
  <div>
    <div class="banner">
      <div class="banner_search">
        <img
          src="@/assets/images/search-24x24@2x.png"
          alt=""
          class="banner_search_img"
        />
        <a-input
          v-model:value="keyword"
          placeholder="请输入问题关键字，如订单、支付等"
          style="flex: 1"
          @pressEnter="pressEnter"
        />
      </div>
    </div>
    <div style="width: 100%">
      <div class="content">
        <!-- 左侧栏 -->
        <div class="content_list">
          <p
            class="content_list_details"
            v-for="(item, index) in helpList"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <text
              style="margin-bottom: 12px"
              v-for="(content, cindex) in item.child"
              :key="cindex"
              @click="choseQue(content)"
              >{{ content.name }}</text
            >
          </p>
        </div>
        <!-- 右侧内容 -->
        <div class="content_details" v-html="details"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { gethelp } from '@/api/FirstPage'
export default {
  setup () {
    const value = ref('')
    const helpList = ref([])
    const details = ref('')
    const keyword = ref('')
    const pressEnter = () => {
      help()
    }
    const help = async () => {
      details.value = ''
      const res = await gethelp({
        keyword: keyword.value
      })
      helpList.value = res.data
      if (helpList.value.length > 0) {
        details.value = helpList.value[0].child[0].remark
      }
    }
    const choseQue = (content, index) => {
      details.value = content.remark
    }
    onMounted(() => {
      help()
    })
    return {
      value, pressEnter, help, helpList, details, choseQue, keyword
    }
  }
}
</script>
<style scoped lang="less">
.banner {
  width: 100%;
  height: 300px;
  background: url(~@/assets/images/banner03@2x.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  .banner_search {
    width: 550px;
    height: 64px;
    background: #ffffff;
    padding: 20px 33px;
    display: flex;
    .banner_search_img {
      width: 24px;
      height: 24px;
      margin-right: 28px;
    }
  }
}
.content {
  width: 980px;
  padding: 87px 0;
  margin: 0 auto;
  display: flex;
  .content_list {
    width: 180px;
    height: 789px;
    border-right: 1px solid #eeeeee;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content_list_details {
      width: 100%;
      display: flex;
      flex-direction: column;
      span {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
      }
      text {
        font-size: 18px;
        color: #808695;
      }
    }
  }
  .content_details {
    flex:1;
    margin-left: 99px;
  }
}
</style>