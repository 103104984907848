<template>
  <div class="done">
    <div>
      <p class="text-m">找回密码</p>
      <p class="text-content">您已成功设置密码，请试用新密码登录</p>
      <a-button size="large" @click="submit" class="login-button">马上登录</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Done',
  data () {
    return {
      loading: false
    }
  },
  methods: {
    submit () {
      this.$emit('finish')
    }
  }
}
</script>
<style lang="less" scoped>
.done {
  width: 350px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}
button.login-button {
  padding: 0 15px;
  font-size: 16px;
  height: 54px;
  width: 350px;
  color: #fff;
  background:@xtxColor;
  border-radius: 23px;
  margin-top: 84px;
}
</style>
