<template>
  <footer class="app-footer">
    <div class="footer-content">
      <div class="footer-top">
        <img
          src="@/assets/images/logo02@2x.png"
          alt=""
          style="width: 164px; height: 73px"
        />
        <div style="margin-left: 120px">
          <p style="display: flex; flex-direction: column">
            <span style="color: #40a9ff">服务热线</span>
            <span style="font-size: 26px; color: #ffffff; margin-top: 5px">{{
              details.service_online
            }}</span>
          </p>
          <p style="display: flex; flex-direction: column">
            <span style="color: #40a9ff">公司传真</span>
            <span style="font-size: 26px; color: #ffffff; margin-top: 5px">{{
              details.company_fax
            }}</span>
          </p>
        </div>
        <div style="margin-left: 120px">
          <p style="display: flex; flex-direction: column">
            <span style="color: #40a9ff">地址</span>
            <span
              style="
                font-size: 12px;
                color: #ffffff;
                width: 265px;
                margin-top: 5px;
                line-height: 24px;
              "
            >
              {{ details.company_address }}
            </span>
          </p>
          <p style="display: flex; flex-direction: column; margin-top: 10px">
            <span style="color: #40a9ff">电子邮箱</span>
            <span
              style="
                font-size: 12px;
                color: #ffffff;
                margin-top: 5px;
                line-height: 24px;
              "
              >{{ details.email }}</span
            >
          </p>
        </div>
      </div>
      <div class="footer-bottom">
        <div
          style="
            width: 100%;
            padding-left: 70px;
            display: flex;
            justify-content: space-between;
          "
        >
          <p>
            <router-link to="/about/us" target="_blank">
              <span style="margin-right: 64px; color: #40a9ff">关于我们</span>
            </router-link>
            <router-link to="/about/platform" target="_blank">
              <span style="margin-right: 64px; color: #40a9ff">平台介绍</span>
            </router-link>
            <router-link to="/about/opinion" target="_blank">
              <span style="margin-right: 64px; color: #40a9ff">意见反馈</span>
            </router-link>
            <router-link to="/about/aftersale" target="_blank">
              <span style="margin-right: 64px; color: #40a9ff">联系我们</span>
            </router-link>
            <router-link to="/about/complaint" target="_blank">
              <span style="color: #40a9ff">投诉通道</span>
            </router-link>
          </p>
          <div>
            <a-tooltip
              placement="top"
              color="#ffffff"
              v-for="(item, index) in codeList"
              :key="index"
            >
              <template
                #title
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                  :src="item.qrcode"
                  alt=""
                  srcset=""
                  style="width: 138px; height: 138px"
                />
              </template>
              <img
                :src="item.icon"
                alt=""
                style="
                  width: 25px;
                  height: 25px;
                  margin-right: 18px;
                  border-radius: 50%;
                "
              />
            </a-tooltip>
          </div>
        </div>
        <div class="bottom-son">
          <img src="@/assets/images/copy.png" class="copy"/>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030702002388" target="_blank">{{ details.site_copy }}</a> 
          <a href="https://beian.miit.gov.cn/" target="_blank">{{ details.miitbeian }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { platCodeList } from "@/api/FirstPage";
import { ref, onMounted } from "vue";
export default {
  name: "AppFooter",
  setup() {
    const codeList = ref("");
    const details = ref("");
    const getCodelist = async () => {
      const res = await platCodeList();
      //console.log('res',res);
      codeList.value = res.data;
      details.value = res;
    };
    onMounted(() => {
      getCodelist();
    });
    return {
      codeList,
      details,
    };
  },
};
</script>

<style scoped lang="less">
.app-footer {
  width: 100%;
  height: 400px;
  background: #212325;
  .footer-content {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding-top: 104px;
    position: relative;
    .footer-top {
      width: 100%;
      padding: 0 0 0 76px;
      display: flex;
    }
    .footer-bottom {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
    .bottom-son {
      height: 41px;
      width: 100%;
      border-top: 1px solid #444444;
      margin-top: 13px;
      font-size: 16px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .copy{
        width: 20px;
        height: 20px;
        margin-right:4px;
      }
      a{
        color: #fff;
      }
    }
  }
}
</style>
