<template>
  <div class="xtx-dialog" v-show="modelValue" :class="{ fade: isShow }">
    <div class="wrapper" ref="target">
      <div class="header">
        <h3>{{title}}</h3>
        <a @click="hide" href="JavaScript:;" class="iconfont icon-close-new"></a>
      </div>
      <div class="body">
        <slot></slot>
      </div>
      <div class="footer">
      <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
export default {
  name: 'XtxDialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const isShow = ref(false)
    watch(() => props.modelValue, (bl) => {
      // 让 fade 类名经历从无到有的过程
      setTimeout(() => {
        isShow.value = bl
      }, 20)
    })

    const hide = () => {
      emit('update:modeValue', false)
    }
    const target = ref(null)
    onClickOutside(target, () => {
      hide()
    })
    return { isShow, hide, target }
  }
}
</script>

<style scoped lang="less">
.xtx-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8887;
  background: rgba(0, 0, 0, 0);
  &.fade {
    transition: all 0.4s;
    background: rgba(0, 0, 0, 0.5);
    // 如果父元素有 .fade 类名，wrapper 就显示到中间
    .wrapper {
      transition: all 0.4s;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
  .wrapper {
    width: 600px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    opacity: 0;

    .body {
      padding: 20px 40px;
      font-size: 16px;
      .icon-warning {
        color: @priceColor;
        margin-right: 3px;
        font-size: 16px;
      }
    }
    .footer {
      text-align: center;
      padding: 10px 0 30px 0;
    }
    .header {
      position: relative;
      height: 70px;
      line-height: 70px;
      padding: 0 20px;
      border-bottom: 1px solid #f5f5f5;
      h3 {
        font-weight: normal;
        font-size: 18px;
      }
      a {
        position: absolute;
        right: 25px;
        top: 25px;
        font-size: 24px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #999;
        &:hover {
          color: #666;
        }
      }
    }
  }
}
</style>
