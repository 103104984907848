<template>
  <div class="news">
    <div class="news-header">
      <div style="display: flex">
        <p
          class="news-header_list"
          v-for="(item, index) in navList"
          :key="index"
          :class="{ active: tabindex == index }"
          @click="changeTab(index)"
        >
          {{ item.name }}
        </p>
      </div>
      <p style="display: flex; align-items: center">
        <router-link to="/account/news-recycle">
          <img
            src="@/assets/images/icon15x15@2x.png"
            alt=""
            style="width: 15px; height: 15px; margin-right: 7px"
          />
          <span>回收站</span>
        </router-link>
      </p>
    </div>
    <div class="news-content">
      <div class="content-header">
        <a-checkbox
          v-model:checked="checkAll"
          :indeterminate="indeterminate"
          @change="onCheckAllChange"
        >
          全选
        </a-checkbox>
        <p class="btn" @click="Remove">删除</p>
        <p class="btn" @click="thoroughRemove">彻底删除</p>
        <p class="btn" @click="read">标记已读</p>
      </div>
      <a-checkbox-group
        v-model:value="checkedList"
        style="width: 100%"
        @change="onChange"
      >
        <div
          class="content-list"
          v-for="(item, index) in plainOptions"
          :key="index"
        >
          <a-checkbox v-model:value="item.id" />
          <span class="tag" :class="{ color: item.status == 2 }"></span>
          <span style="margin-left: 10px; width: 10%">系统通知</span>
          <p style="color: #808695; margin-left: 30px; width: 70%">
            {{ item.content }}
          </p>
          <span style="color: #808695; margin-left: 40px; width: 20%">{{
            item.create_at
          }}</span>
        </div>
      </a-checkbox-group>
      <XtxPagination
        v-if="total"
        :total="total"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="getCurrentPage"
      />
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from 'vue'
import { noticeList, saveNotice, delNotice, thoroughDelNotice } from '@/api/user'
export default {
  setup () {
    const navList = ref([{
      status: 0,
      name: '全部消息',
      number: 1
    },
    {
      status: 1,
      name: '未读消息',
      number: 1
    },
    {
      status: 2,
      name: '已读消息',
      number: 1
    }
    ])
    const tabindex = ref(0)
    // 评论总条数
    const total = ref()
    // 当前页码
    const currentPage = ref(1)
    //一页几条
    const pageSize = ref(15)
    const plainOptions = ref([])
    const getCurrentPage = val => {
      // console.log('当前子组件的页面为', val)
      currentPage.value = val
      getList()
    }
    const state = reactive({
      indeterminate: true,
      checkAll: false,
      checkedList: [],
    });
    const onChange = (checkedList) => {
      console.log(checkedList)
      state.indeterminate = !!checkedList.length && checkedList.length < plainOptions.value.length;
      state.checkAll = checkedList.length === plainOptions.value.length;
    }
    const onCheckAllChange = (e) => {
      console.log(e)
      const value = []
      plainOptions.value.forEach(item => {
        value.push(item.id)
      })
      console.log(value)
      console.log(plainOptions.value)
      Object.assign(state, {
        checkedList: e.target.checked ? value : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    };
    const getList = async () => {
      const res = await noticeList({
        page: currentPage.value,
        pagesize: pageSize.value,
        status: tabindex.value
      })
      plainOptions.value = res.data.list
      total.value = res.data.total
    }
    const changeTab = (index) => {
      console.log(index)
      tabindex.value = index
      getList()
    }
    const Remove = async () => {
      const res = await delNotice({
        state: 1,
        id: state.checkedList
      })
      if (res.code == 0) {
        getList()
      }
    }
    const read = async () => {
      const res = await saveNotice({
        id: state.checkedList
      })
      if (res.code == 0) {
        getList()
      }
    }
    const thoroughRemove = async () => {
      const res = await thoroughDelNotice({
        id: state.checkedList
      })
      if (res.code == 0) {
        getList()
      }
    }
    onMounted(() => {
      getList()
    })
    return {
      navList, tabindex, total, currentPage, pageSize, getCurrentPage, ...toRefs(state),
      plainOptions,
      onCheckAllChange, onChange, getList, changeTab, Remove, read, thoroughRemove
    }
  }
}
</script>
<style scoped lang="less">
.news {
  width: 940px;
  min-height: 860px;
  background: #ffffff;
  padding: 40px;
  .news-header {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #f1f1f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .news-header_list {
      width: 90px;
      height: 38px;
      margin-right: 32px;
      display: flex;
      justify-content: center;
      font-size: 18px;
      &.active {
        border-bottom: 1px solid #296df1;
        color: #296df1;
      }
    }
  }
}
.news-content {
  width: 100%;
  .content-header {
    width: 100%;
    height: 77px;
    border-bottom: 1px solid #f1f1f4;
    display: flex;
    align-items: center;
    padding-left: 19px;
    .btn {
      padding: 4px 18px;
      border: 1px solid #dcdee2;
      border-radius: 2px;
      font-size: 14px;
      color: #515a6e;
      margin-left: 10px;
    }
  }
  .content-list {
    width: 100%;
    min-height: 44px;
    border: 1px solid #f1f1f4;
    border-top: none;
    display: flex;
    align-items: center;
    padding: 5px 19px;
    .tag {
      display: block;
      width: 5px;
      height: 5px;
      background: #ff3c31;
      border-radius: 50%;
      margin-left: 22px;
      &.color {
        background: #ffffff;
      }
    }
  }
}
</style>