<template>
  <div class="restpwd">
    <div class="restpwd-header">支付密码</div>
    <div class="restpwd-content" v-if="is_set == 0">
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="设置支付密码：" name="new_pwd">
          <a-input
            v-model:value="formState.new_pwd"
            type="password"
            style="height: 30px; width: 282px; border: 1px solid #d9d9d9"
          />
        </a-form-item>
        <a-form-item label="再次确认密码：" name="new_repwd">
          <a-input
            v-model:value="formState.new_repwd"
            type="password"
            style="height: 30px; width: 282px; border: 1px solid #d9d9d9"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="restpwd-content" v-else>
      <a-form
        ref="restformRef"
        :rules="rules"
        :model="restformState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="修改支付密码：" name="new_pwd">
          <a-input
            v-model:value="restformState.new_pwd"
            type="password"
            style="height: 30px; width: 282px; border: 1px solid #d9d9d9"
          />
        </a-form-item>
        <a-form-item label="再次确认密码：" name="new_repwd">
          <a-input
            v-model:value="restformState.new_repwd"
            type="password"
            style="height: 30px; width: 282px; border: 1px solid #d9d9d9"
          />
        </a-form-item>
        <a-form-item label="验证码：" name="code">
          <a-input
            v-model:value="restformState.code"
            type="password"
            style="height: 30px; width: 282px; border: 1px solid #d9d9d9"
          />
        </a-form-item>
        <a-form-item label="手机号：">
          {{ phone }}
          <span style="color: #296df1" @click="sendCodeBtn">
            {{ number === 0 ? "发送验证码" : `${number}秒后发送` }}</span
          >
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="restSubmit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRaw, onMounted } from 'vue'
import { setPayPwd, isSetPwd, editPayPwd, sendCode } from '@/api/user'
import Message from '@/components/library/Message'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useIntervalFn } from '@vueuse/shared'
import schema from '@/utils/vee-validate-schema'
export default {
  components: {},
  setup () {
    const store = useStore()
    const router = useRouter()
    const formRef = ref();
    const restformRef = ref()
    const formState = reactive({
      new_pwd: '',
      new_repwd: ''
    });
    const restformState = reactive({
      new_pwd: '',
      new_repwd: '',
      code: ''
    })
    const number = ref(0)
    const tel = ref('')
    tel.value = store.state.user.userinfo.account
    const reg = /(\d{3})\d{4}(\d{4})/;
    const phone = ref()
    phone.value = tel.value.replace(reg, "$1****$2")
    console.log(phone.value)
    const is_set = ref(0)
    let validatePass2 = async (rule, value) => {
      if (value === '') {
        return Promise.reject('Please input the password again');
      } else if (value !== '') {
        if (formState.new_pwd && value !== formState.new_pwd) {
          console.log(value)
          return Promise.reject("Two inputs don't match!");
        } else if (restformState.new_pwd && value !== restformState.new_pwd) {
          console.log(value)
          return Promise.reject("Two inputs don't match!");
        }
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      new_pwd: [{ required: true, message: '设置支付密码', trigger: 'blur' }],
      new_repwd: [{ required: true, message: '再次确认密码', validator: validatePass2, trigger: 'change' }],
      code: [{ required: true, message: '输入验证码', trigger: 'blur' }],
    };
    const isSetPayPwd = async () => {
      const result = await isSetPwd()
      is_set.value = result?.is_set
      console.log(is_set.value)
    }
    const { resume, pause } = useIntervalFn(
      () => {
        number.value--
        if (number.value <= 0) {
          pause()
        }
      },
      1000,
      { immediate: false }
    )
    const sendCodeBtn = async () => {

      // 🔔上一个倒计时没有结束，直接退出
      if (number.value !== 0) return
      try {
        const res = await sendCode({
          phone: tel.value,
          type: 2
        })
        if (res.code == 0) {
          Message({ type: 'success', message: '验证码发送成功' })
          // 倒计时秒数重置
          number.value = 60
          // 调用倒计时
          resume()
          // 发送验证码
        }
      } catch (err) {
        console.log(err.response)
        Message({ type: 'error', message: '验证码已发送，稍后再试' })
      }

    }
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          console.log('values', formState, toRaw(formState));
          const res = await setPayPwd(formState)
          if (res.code == 0) {
            Message({ type: 'success', message: '设置成功' })
            isSetPayPwd()
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    const restSubmit = () => {
      restformRef.value
        .validate()
        .then(async () => {
          console.log('values', restformState, toRaw(restformState));
          const res = await editPayPwd({
            phone: tel.value,
            ...restformState
          })
          if (res.code == 0) {
            Message({ type: 'success', message: '重置成功' })
            setTimeout(() => {
              restformRef.value.resetFields()
              pause()
              number.value = 0
            }, 1000)
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
    onMounted(() => {
      isSetPayPwd()
    })
    return {
      formRef,
      formState,
      restformState,
      rules,
      onSubmit,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      restformRef,
      restSubmit,
      isSetPayPwd,
      is_set,
      phone,
      number,
      tel,
      sendCodeBtn
    }
  }
}
</script>
<style scoped lang="less">
.restpwd {
  width: 940px;
  min-height: 732px;
  .restpwd-header {
    width: 100%;
    height: 56px;
    background: #ffffff;
    padding: 16px 40px;
    font-size: 16px;
  }
  .restpwd-content {
    width: 100%;
    min-height: 670px;
    background: #ffffff;
    margin-top: 6px;
    padding: 43px 87px 0 54px;
  }
}
</style>