<template>
  <div>
    <!-- 1.顶部通栏布局 -->
    <AppTopnav />
    <div class="header-nav">
      <ul class="app-header-nav">
        <RouterLink to="/">
          <h1 class="logo">
            <img src="@/assets/images/wanmou_logo2@2x.png" alt="" />
          </h1>
        </RouterLink>
        <!-- <li class="home"><RouterLink to="/">首页</RouterLink></li>
        <li class="home"><a>个人中心</a></li>
        <li class="home"><a>消息</a></li> -->
        <div style="display: flex">
          <div class="inp">
            <a-input v-model:value="value" placeholder="请输入关键字" />
            <p class="inp_img">
              <img src="@/assets/images/icon-search@2x.png" alt="" />
            </p>
          </div>
          <router-link to="/order/cart">
            <div class="cart">
              <img
                src="@/assets/images/icon-cart@2x.png"
                style="width: 14px; height: 14px"
              />
              <span class="car_title">我的购物车</span>
              <span class="car_title1">{{ $store.getters["user/validTotal"] }}</span>
              <img
                src="@/assets/images/icons-left@2x.png"
                alt=""
                style="width: 10px; height: 10px"
              />
            </div>
          </router-link>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import AppTopnav from '@/components/app-topnav.vue'
import { ref, onMounted } from 'vue'
import store from '@/store'
export default {
  components: { AppTopnav },
  setup () {
    const carLength = ref(0)
    onMounted(() => {
      carLength.value = store.state.user.carLength
    })
    return {
      carLength
    }
  }
}
</script>
<style scoped lang="less">
.header {
  width: 100%;
  height: 30px;
  background: #f8f8f9;
  .header_content {
    width: 1174px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #808695;
    .header_content_right {
      margin-right: 14px;
    }
  }
}
.header-nav {
  width: 100%;
  height: 80px;
  background: #296df1;
  .app-header-nav {
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 67px;
    position: relative;
    z-index: 998;
    .logo {
      width: 156px;
      height: 35px;
      margin-right: 175px;
    }
    > li {
      margin-right: 47px;
      // width: 38px;
      text-align: center;
      > a {
        font-size: 16px;
        line-height: 78px;
        height: 68px;
        // border: 1px solid saddlebrown;
        display: block;
        color: #ffffff;
        font-size: 17px;
      }
      &:hover {
        > a {
          font-weight: 700;
          color: @xtxColor;
          border-bottom: 3px solid @xtxColor;
        }
      }
    }
  }
}
.inp {
  width: 214px;
  height: 32px;
  background: #ffffff;
  margin-left: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  .inp_img {
    width: 38px;
    height: 32px;
    background: #f8f8f9;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 12px;
      height: 12px;
    }
  }
}
.cart {
  width: 150px;
  height: 32px;
  background: #ffffff;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .car_title {
    font-size: 12px;
    color: #515a6e;
    margin-left: 9px;
    margin-right: 11px;
  }
  .car_title1 {
    width: 12px;
    height: 12px;
    background: #296df1;
    border-radius: 50%;
    font-size: 7px;
    text-align: CENTER;
    color: #ffffff;
    line-height: 12px;
    margin-right: 5px;
  }
}
</style>