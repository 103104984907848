<template>
  <div>
    <div class="account-box">
      <div class="form">
        <div class="form-item">
          <span>绑定手机号：</span>
          <div class="input">
            <a-input placeholder="请输入手机号" v-model:value="form.phone" />
          </div>
        </div>
        <div class="form-item">
          <span>图形验证码：</span>
          <div class="input">
            <a-input
              placeholder="请输入图形验证码"
              v-model:value="form.picCode"
            />
            <span class="code" @click="refreshCode">
              <security :identifyCode="identifyCode"></security>
            </span>
          </div>
        </div>
        <div class="form-item">
          <span>手机验证码：</span>
          <div class="input">
            <a-input placeholder="请输入手机号" v-model:value="form.code" />
            <span class="code" @click="sendCodeBtn">
              {{ number === 0 ? "发送验证码" : `${number}秒后发送` }}
            </span>
          </div>
        </div>
        <div class="btn-f">
          <a-button type="primary" class="btn" @click="nextStep">
            下一步
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
import schema from '@/utils/vee-validate-schema'
import security from './identify.vue'
import { useIntervalFn } from '@vueuse/shared'
import Message from '@/components/library/Message'
import { sendCode } from '@/api/user'
export default {
  name: 'LoginForm',
  props: {
    activeName: {
      type: String,
      default: ''
    }
  },
  components: { security },
  setup (props, { emit }) {
    // 用于手机表单数据的响应式对象
    const form = reactive({
      phone: null,
      code: null,
      picCode: null,
    })
    const identifyCode = ref('')
    const identifyCodes = ref('1234567890abcdef')
    const formCom = ref(null)
    const nextStep = () => {
      if (!form.phone) {
        Message({ message: '请输入手机号' })
        return
      }
      const mobileChecked = schema.mobile(form.phone)
      console.log(mobileChecked)
      if (mobileChecked != true) {
        Message({ type: 'error', message: mobileChecked })
        return
      }
      if (!form.picCode) {
        Message({ message: '请输入图形验证码' })
        return
      }
      if (form.picCode != identifyCode.value) {
        Message({ type: 'error', message: '图形验证码输入有误' })
        return
      }
      if (!form.code) {
        Message({ message: '请输入验证码' })
        return
      }
      emit('nextStep', form)
    }

    const number = ref(0)
    const { resume, pause } = useIntervalFn(
      () => {
        number.value--
        if (number.value <= 0) {
          pause()
        }
      },
      1000,
      { immediate: false }
    )

    const sendCodeBtn = async () => {
      // 校验手机号码 form.mobile
      const mobileChecked = schema.mobile(form.phone)
      console.log(mobileChecked)
      if (mobileChecked === true) {
        // 🔔上一个倒计时没有结束，直接退出
        if (number.value !== 0) return
        try {
          await sendCode({
            phone: form.phone,
            type: 2
          })
          Message({ type: 'success', message: '验证码发送成功' })
          // 倒计时秒数重置
          number.value = 60
          // 调用倒计时
          resume()
          // 发送验证码
        } catch (err) {
          console.log(err.response)
          Message({ type: 'error', message: '验证码已发送，稍后再试' })
        }
      } else {
        Message({ type: 'error', message: mobileChecked })
      }
    }
    onMounted(() => {
      identifyCode.value = ''
      makeCode(identifyCodes.value, 4)
    })
    // 验证码处理
    const randomNum = (min, max) => {
      return Math.floor(Math.random() * (max - min) + min)
    }
    // 刷新验证码
    const refreshCode = () => {
      identifyCode.value = ''
      makeCode(identifyCodes.value, 4)
    }
    const makeCode = (o, l) => {
      for (let i = 0; i < l; i++) {
        identifyCode.value += identifyCodes.value[
          randomNum(0, identifyCodes.value.length)
        ]
      }
      console.log(identifyCode.value)
    }
    return { schema, form, formCom, nextStep, sendCodeBtn, number, identifyCode, identifyCodes, randomNum, refreshCode, makeCode }
  }
}
</script>

<style lang="less" scoped>
.account-box {
  // padding: 24px 0;
  .toggle {
    text-align: right;
    a {
      color: @xtxColor;
      i {
        font-size: 14px;
      }
    }
  }
  .form {
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      .input {
        flex: 1;
        position: relative;
        height: 38px;
        > i {
          width: 34px;
          height: 34px;
          background: #cfcdcd;
          color: #fff;
          position: absolute;
          left: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 18px;
        }
        input {
          padding-left: 10px;
          border: 1px solid #cfcdcd;
          height: 36px;
          line-height: 36px;
          width: 100%;
          &.error {
            border-color: @priceColor;
          }
          &.active,
          &:focus {
            border-color: @xtxColor;
          }
        }
        .code {
          position: absolute;
          right: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 14px;
          background: #f5f5f5;
          color: #666;
          width: 90px;
          height: 34px;
          cursor: pointer;
        }
      }
      > .error {
        position: absolute;
        font-size: 12px;
        line-height: 28px;
        color: @priceColor;
        i {
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }
    .agree {
      a {
        color: #069;
      }
    }
    .btn-f {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn {
      display: block;
      width: 125px;
      height: 32px;
      color: #fff;
      text-align: center;
      background: @xtxColor;
      &.disabled {
        background: #cfcdcd;
      }
    }
  }
}
</style>
