<template>
  <div class="xtx-confirm">
    <div class="wrapper" ref="target">
      <div class="header">
        <h3>{{ title }}</h3>
        <a href="JavaScript:;" @click="cancelCallBack" class="iconfont icon-close-new"></a>
      </div>
      <div class="body">
        <i class="iconfont icon-warning"></i>
        <span>{{ text }}</span>
      </div>
      <div class="footer">
        <XtxButton @click="cancelCallBack" size="mini" type="gray">取消</XtxButton>
        <XtxButton @click="confirmCallback" size="mini" type="primary">确认</XtxButton>
      </div>
    </div>
  </div>
</template>

<script>
import XtxButton from '@/components/library/xtx-button.vue'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'
export default {
  name: 'XtxConfirm',
  components: { XtxButton },
  props: {
    title: {
      type: String,
      default: '温馨提示'
    },
    text: {
      type: String,
      default: ''
    },
    confirmCallback: {
      type: Function,
      default: () => {}
    },
    cancelCallBack: {
      type: Function,
      default: () => {}
    }
  },
  setup (props) {
    // 点击 target 目标元素外部相当于点击了取消
    const target = ref(null)
    onClickOutside(target, () => {
      props.cancelCallBack()
    })
    return { target }
  }

}

</script>

<style scoped lang="less">
.xtx-confirm {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8888;
  background: rgba(0, 0, 0, 0.5);
  .wrapper {
    width: 400px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .header,
    .footer {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
    }
    .body {
      padding: 20px 40px;
      font-size: 16px;
      .icon-warning {
        color: @priceColor;
        margin-right: 3px;
        font-size: 16px;
      }
    }
    .footer {
      text-align: right;
      .xtx-button {
        margin-left: 20px;
      }
    }
    .header {
      position: relative;
      h3 {
        font-weight: normal;
        font-size: 18px;
      }
      a {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #999;
        &:hover {
          color: #666;
        }
      }
    }
  }
}
</style>
