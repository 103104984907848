import request from '@/utils/request';
/**
 * 获取默认地址接口
 */
export const getDefaultAddress = (data) => {
  return request('/Orders/getDefaultAddress', 'post', data);
};
/**
 * 根据产品id获取犹豫期
 */
export const hesitateList = (data) => {
  return request('/Orders/hesitateList', 'post', data);
};
/**
 * 提交订单详情页面
 */
export const getGoodInfo = (data) => {
  return request('/Orders/getGoodInfo', 'post', data);
};
/**
 * 订单列表
 */
export const orderList = (data) => {
  return request('/Orders/orderList', 'post', data);
};
/**
 * 退款订单列表
 */
export const refundOrderList = (data) => {
  return request('/Orders/refundOrderList', 'post', data);
};
/**
 * 下单接口
 */
export const addOrder = (data) => {
  return request('/Orders/addOrder', 'post', data);
};
/**
 * 取消订单
 */
export const cancelOrder = (data) => {
  return request('/Orders/cancelOrder', 'post', data);
};
/**
 * 订单详情接口
 */
export const orderDetail = (data) => {
  return request('/Orders/orderDetail', 'post', data);
};
/**
 * 确认收货
 */
export const orderSure = (data) => {
  return request('/Orders/orderSure', 'post', data);
};
/**
 * 退款判断是否在犹豫期内
 */
export const is_hesitate = (data) => {
  return request('/Orders/is_hesitate', 'post', data);
};
/**
 * 退款申请
 */
export const refundApply = (data) => {
  return request('/Orders/refundApply', 'post', data);
};
/**
 * 直接订单退款
 */
export const refund = (data) => {
  return request('/Orders/refund', 'post', data);
};
/**
 * 退款状态
 */
export const queryRefundStatus = (data) => {
  return request('/Orders/queryRefundStatus', 'post', data);
};
/**
 * 订单支付
 */
export const Pay = (data) => {
  return request('/Orders/Pay', 'post', data);
};
/**
 * 支付宝支付状态查询
 */
export const queryOrderStatus = (data) => {
  return request('/Orders/queryOrderStatus', 'post', data);
};
/**
 * 隐私协议
 */
export const dangerList = (data) => {
  return request('/Orders/dangerList', 'post', data);
};