<template>
  <div>
    <!-- 1.顶部通栏布局 -->
    <AppTopnav />
    <div class="header_b">
      <div class="header_b_content">
        <img src="@/assets/images/wanmou_logo@2x.png" alt="" class="logo" />
        <span class="interval"></span>
        <span>{{ title }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import AppTopnav from '@/components/app-topnav.vue'
export default {
  components: { AppTopnav },
  props: {
    title: {
      type: String
    }
  },
  setup (props, { emit }) {

    return {

    }
  }
}
</script>
<style  scoped lang="less">
.header {
  width: 100%;
  height: 30px;
  background: #f8f8f9;
  .header_content {
    width: 1174px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #808695;
    .header_content_right {
      margin-right: 14px;
    }
  }
}
.header_b {
  width: 100%;
  height: 80px;
  background: #ffffff;
  .header_b_content {
    width: 1004px;
    height: 100%;
    margin: 0 auto;
    font-size: 20px;
    color: #515a6e;
    display: flex;
    align-items: center;
    .logo {
      width: 160px;
      height: 36px;
    }
    .interval {
      display: block;
      width: 1px;
      height: 24px;
      opacity: 0.2;
      border: 1px solid #000000;
      margin: 0 17px 0 24px;
    }
  }
}
</style>