<template>
  <div class="bindaccount">
    <div class="bindaccount-header">绑定账号</div>
    <div class="bindaccount-content">
      <div style="margin-bottom: 30px">
        <h3 class="bindaccount-content_h3">登录帐号</h3>
        <h3>登陆手机</h3>
        <a-input
          v-model:value="phone"
          style="
            height: 40px;
            width: 200px;
            margin-top: 10px;
            padding-left: 10px;
          "
          disabled="true"
        />
      </div>
      <h3 class="bindaccount-content_h3">第三方帐号绑定</h3>
      <a-row style="margin-bottom: 24px">
        <a-col :span="2">QQ：</a-col>
        <!-- <a-col :span="12">
          <span style="color: #ff3c31">去绑定</span>
        </a-col> -->
        <a-col :span="12">
          <span
            style="color: #296df1"
            v-if="is_bind_qq == 1"
            @click="relieveBind(2)"
            >解除绑定</span
          >
          <span style="color: #ff3c31; font-size: 12px" @click="bindQQ" v-else>
            <img
              src="@/assets/images/03_qq_symbol.png"
              alt=""
              srcset=""
              class="otherLogin_img"
            />
            <span class="otherLogin_text">去绑定</span>
          </span>
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 24px">
        <a-col :span="2">微信：</a-col>
        <a-col :span="12">
          <span
            style="color: #296df1"
            v-if="is_bind_wx == 1"
            @click="relieveBind(1)"
            >解除绑定</span
          >
          <span style="color: #ff3c31; font-size: 12px" @click="bindWx" v-else>
            <img
              src="@/assets/images/wchat@2x.png"
              alt=""
              class="otherLogin_img"
            />
            <span class="otherLogin_text">去绑定</span>
          </span>
        </a-col>
      </a-row>
      <!-- TODO 暂时隐藏-->
      <a-row style="margin-bottom: 24px">
        <a-col :span="2">支付宝：</a-col>
        <a-col :span="12">
          <span
            style="color: #296df1"
            v-if="is_bind_ali == 1"
            @click="relieveBind(3)"
            >解除绑定</span
          >
          <span style="color: #ff3c31; font-size: 12px" @click="bindAli" v-else>
            <img
              src="@/assets/images/alipay@2x.png"
              alt=""
              class="otherLogin_img"
            />
            <span class="otherLogin_text">去绑定</span>
          </span>
        </a-col>
      </a-row>
      <!-- <a-row style="margin-bottom: 24px">
        <a-col :span="2">邮箱：</a-col>
        <a-col :span="12">
          <span style="color: #ff3c31">去绑定</span>
        </a-col>
      </a-row> -->
    </div>
    <a-modal
      v-model:visible="visible"
      :title="title"
      :closable="false"
      :footer="null"
      width="350px"
    >
      <a-input
        v-model:value="value"
        placeholder="请输入"
        style="height: 30px; width: 282px; border: 1px solid #d9d9d9"
      />
      <div
        style="display: flex; justify-content: space-between; margin-top: 20px"
      >
        <p></p>
        <a-button type="primary" @click="handleOk">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { unbind, userInfo, bindWx2, aliLogin } from "@/api/user";
import Message from "@/components/library/Message";
export default {
  setup() {
    const visible = ref(false);
    const title = ref("绑定QQ");
    const phone = ref("");
    const is_bind_wx = ref("");
    const is_bind_qq = ref("");
    const is_bind_ali = ref("");

    const appid = ref("wxc24c8f1359a8eb35");
    const redirectUri = ref(
      "http%3A%2F%2Fwww.wanmou.top%2F%23%2Faccount%2Fbindaccount"
    );
    const code = ref();
    const store = useStore();
    const router = useRouter();
    // const userinfo = ref({});
    console.log("userinfo", store.state.user.userinfo);
    // userinfo.value = store.state.user.userinfo;
    phone.value = store.state.user.userinfo.account;
    is_bind_wx.value = store.state.user.userinfo.is_bind_wx;
    is_bind_qq.value = store.state.user.userinfo.is_bind_qq;
    is_bind_ali.value = store.state.user.userinfo.is_bind_ali;

    const bindQQ = async () => {
      window.open("http://admin.wanmou.top/api/User/qqAuth", "_self");
    };

    const bindAli = async () => {
      const res = await aliLogin();
      console.log("----res---", res);
      if (res.code == 0) {
        const url = res.url;
        window.open(url, "_blank");
      }
    };

    const bindWx = () => {
      const url = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid.value}&redirect_uri=${redirectUri.value}&response_type=code&scope=snsapi_login&state=weixin#wechat_redirect`;
      window.open(url);
    };
    const BindWX = async () => {
      const res = await bindWx2({
        code: code.value,
      });
      if (res.code == 0) {
        Message({ type: "success", message: "绑定成功" });
        getUserinfo();
      }
    };
    const handleOk = (e) => {
      console.log(e);
      visible.value = false;
    };
    const relieveBind = async (type) => {
      const res = await unbind({
        type,
      });
      if (res.code == 0) {
        Message({ type: "success", message: "解绑成功" });
        getUserinfo();
        if (type == 1) {
          is_bind_wx.value = 2;
        } else if (type == 2) {
          is_bind_qq.value = 2;
        } else if (type == 3) {
          is_bind_ali.value = 2;
        }
      }
    };
    const getUserinfo = async () => {
      const userinfo = await userInfo();
      store.commit("user/setUser", userinfo.data);
      is_bind_wx.value = store.state.user.userinfo.is_bind_wx;
      is_bind_qq.value = store.state.user.userinfo.is_bind_qq;
    };
    onMounted(() => {
      console.log("router--", router.currentRoute.value.query);

      if (
        router.currentRoute.value.query &&
        router.currentRoute.value.query.code
      ) {
        code.value = router.currentRoute.value.query.code;
        BindWX();
      }

      console.log("---state--userinfo", store.state.user.userinfo.is_bind_ali);
    });
    return {
      title,
      visible,
      bindWx,
      handleOk,
      phone,
      is_bind_wx,
      is_bind_qq,
      relieveBind,
      appid,
      redirectUri,
      code,
      BindWX,
      getUserinfo,
      bindQQ,
      bindAli,
      is_bind_ali,
    };
  },
};
</script>
<style scoped lang="less">
.bindaccount {
  width: 940px;
  min-height: 732px;
  .bindaccount-header {
    width: 100%;
    height: 56px;
    background: #ffffff;
    padding: 16px 40px;
    font-size: 16px;
  }
  .bindaccount-content {
    width: 100%;
    min-height: 670px;
    background: #ffffff;
    margin-top: 6px;
    padding: 30px 40px;
    .bindaccount-content_h3 {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}
.otherLogin_img {
  width: 40px;
  transform: translateY(-8px);
  margin-right: 10px;
}
</style>
