<template>
  <div class="wmbeans">
    <div class="wmbeans_header">
      <div class="wmbeans_header_son1">
        <img :src="userinfo.headimg" alt="" srcset="" />
        <p class="header_son1">
          <span>{{ userinfo.nickname }}</span>
          <span class="margin-top-xs">{{ userinfo.account }}</span>
        </p>
      </div>
      <div class="wmbeans_header_son2">
        <p>我的余额</p>
        <div class="flex align-center margin-top-xs">
          <p class="flex align-center">
            <span class="text-xl margin-right-m">{{
              userinfo.money_rest
            }}</span>
            <span class="text-m">元</span>
          </p>
          <p class="flex align-center" style="margin-left: 34px">
            <span class="text-xl margin-right-m">{{
              userinfo.balance_rest
            }}</span>
            <span class="text-m">个万谋豆</span>
            <router-link to="/account/wmbeans-chong">
              <span class="btn">充值</span>
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="wmbeans_content text-l">收支明细</div>
    <div style="color: #515a6e; height: 400px; overflow-y: auto">
      <div class="record_header">
        <div class="header_son1">订单详情</div>
        <div class="header_son1">时间</div>
        <div class="header_son2">金额 (元)</div>
        <div class="header_son2">万谋豆</div>
        <div class="header_son1">订单备注</div>
      </div>
      <div class="record_list" v-for="(item, index) in List" :key="index">
        <div class="header_son1">{{ item.order_no }}</div>
        <div class="header_son1">{{ item.create_at }}</div>
        <div class="header_son2">
          {{ item.money }}
        </div>
        <div class="header_son2">
          {{ item.amount }}
        </div>
        <div class="header_son1">
          {{
            item.type === 1
              ? "充值"
              : item.type === 2
              ? "购物订单"
              : item.type === 3
              ? "购物订单取消"
              : "购物订单退款"
          }}
        </div>
      </div>
    </div>
    <!-- <div
      class="wmbeans_content"
      style="font-size: 14px"
      v-for="(item, index) in List"
      :key="index"
    >
      <p class="wmbeans_content_left">
        <span>订单</span>
        <span>{{ item.create_at }}</span>
      </p>
      <p class="red text-l">-{{ item.amount }}</p>
    </div> -->
    <XtxPagination
      v-if="total"
      :total="total"
      :current-page="currentPage"
      :page-size="pageSize"
      @current-change="getCurrentPage"
    />
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { balanceRecordList, userInfo } from "@/api/user";
import { useStore } from "vuex";
export default {
  components: {},
  setup() {
    const store = useStore();
    // 总条数
    const total = ref();
    const totalPage = ref(1);
    // 当前页码
    const currentPage = ref(1);
    //一页几条
    const pageSize = ref(10);
    const List = ref();
    const userinfo = ref({});

    const getCurrentPage = (val) => {
      console.log("getCurrentPage当前子组件的页面为", val);
      currentPage.value = val;
      getList();
    };
    const getList = async () => {
      const res = await balanceRecordList({
        page: currentPage.value,
        pagesize: pageSize.value,
      });
      List.value = res.data.list;
      // 总共16个
      total.value = res.data.total;
      // 总共2页
      totalPage.value = res.data.num;
    };
    const user = async () => {
      const res = await userInfo();
      if (res.code == 0) {
        store.commit("user/setUser", res.data);
      }
      console.log(store.state.user.userinfo);
      userinfo.value = store.state.user.userinfo;
    };
    onMounted(() => {
      getList();
      user();
    });
    return {
      total,
      totalPage,
      currentPage,
      pageSize,
      getCurrentPage,
      userinfo,
      user,
      getList,
      List,
    };
  },
};
</script>
<style scoped lang="less">
.wmbeans {
  width: 940px;
  height: 732px;
  background: #ffffff;
  padding: 30px 40px;
  .wmbeans_header {
    width: 100%;
    height: 132px;
    background: url(~@/assets/images/wmbeans_header.png) no-repeat;
    background-size: 100%;
    padding: 30px 0;
    display: flex;
    .wmbeans_header_son1 {
      width: 280px;
      height: 100%;
      border-right: 1px solid #ebf2ff;
      padding-left: 36px;
      display: flex;
      img {
        width: 75px;
        height: 75px;
        margin-right: 22px;
        border-radius: 50%;
      }
      .header_son1 {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
      }
    }
    .wmbeans_header_son2 {
      flex: 1;
      padding-left: 40px;
      display: flex;
      flex-direction: column;
      .btn {
        width: 86px;
        height: 32px;
        background: #296df1;
        border-radius: 2px;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 40px;
      }
    }
  }
}
.wmbeans_content {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #f1f1f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 138px;
  .wmbeans_content_left {
    display: flex;
    flex-direction: column;
    color: #808695;
  }
  .red {
    color: #ff3c31;
  }
}
.record_header {
  width: 100%;
  height: 38px;
  background: #f8f8f9;
  // margin-top: 20px;
  display: flex;
  align-items: center;
}
.header_son1 {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_son2 {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.record_list {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #f1f1f4;
  display: flex;
  align-items: center;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.margin-top-xs {
  margin-top: 10px;
}
.margin-right-m {
  margin-right: 10px;
}
.text-m {
  font-size: 14px;
}
.text-l {
  font-size: 16px;
}
.text-xl {
  font-size: 32px;
}
</style>
