<template>
  <div class="content">
    <div class="content_detaisl">
      <XtxBread class="xtx-bread">
        <XtxBreadItem to="/" class="xtx-bread-item" norColor="#808695">
          我的订单
        </XtxBreadItem>
        <transition name="fade-right" mode="out-in"> 申请退款 </transition>
      </XtxBread>
      <div class="detaisl_son">
        <div class="step">
          <a-steps class="steps" :current="currentTab" size="small">
            <a-step title="申请退款" />
            <a-step title="平台处理" />
            <a-step title="退款成功" />
          </a-steps>
        </div>
        <div class="son">
          <a-form
            ref="formRef"
            :model="formState"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :rules="rules"
            v-if="currentTab == 0"
          >
            <a-form-item label="退款原因：" name="refund_reason">
              <a-select
                style="width: 264px"
                v-model:value="formState.refund_reason"
                @change="change"
              >
                <a-select-option value="1">不需要了</a-select-option>
                <a-select-option value="2">未按约定时间发货</a-select-option>
                <a-select-option value="3">商家服务不满意</a-select-option>
                <a-select-option value="4">其他</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="退款金额：" name="name">
              <a-input
                disabled
                v-model:value="formState.refund_price"
                style="
                  border: 1px solid #d9d9d9;
                  height: 30px;
                  width: 264px;
                  padding-left: 10px;
                "
              />
            </a-form-item>
            <a-form-item label="退款说明:">
              <a-textarea
                style="width: 450px"
                v-model:value="formState.refund_description"
                placeholder=""
                :rows="4"
              />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" @click="onSubmit">提交申请</a-button>
            </a-form-item>
          </a-form>
          <div v-if="currentTab == 1">
            <div class="result" v-if="is_refund == 1">
              <img
                src="@/assets/images/refund1.png"
                alt=""
                srcset=""
                style="width: 60px; height: 60px; margin-bottom: 30px"
              />
              <p>申请已提交，平台处理中</p>
            </div>
            <div class="result" v-if="is_refund == 2">
              <img
                src="@/assets/images/refund2.png"
                alt=""
                srcset=""
                style="width: 60px; height: 60px; margin-bottom: 30px"
              />
              <p>您提交的申请未通过审核，退款失败。</p>
              <p style="color: #999; margin-top: 10px">{{ check_reason }}</p>
            </div>
          </div>
          <div v-if="currentTab == 2">
            <div class="result">
              <img
                src="@/assets/images/refund3.png"
                alt=""
                srcset=""
                style="width: 60px; height: 60px; margin-bottom: 30px"
              />
              <p>退款成功</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { refundApply } from "@/api/Orders";
import { useRoute } from "vue-router";
import Message from "@/components/library/Message";
import { queryRefundStatus } from "@/api/Orders";
export default {
  components: {},
  setup() {
    const route = useRoute();
    const currentTab = ref(0);
    const formRef = ref();

    const formState = reactive({
      item_id: "",
      refund_price: "",
      refund_reason: "",
      refund_description: "",
    });
    const is_refund = ref("");
    const check_reason = ref("");

    const rules = {
      refund_reason: [
        { required: true, message: "请选择退款原因", trigger: "change" },
      ],
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          console.log("values", formState, toRaw(formState));
          const res = await refundApply(formState);
          if (res.code == 0) {
            Message({ type: "success", message: "提交成功" });
            getStatus();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const change = (e) => {
      console.log(e);
      formState.refund_reason = e;
    };
    const getStatus = async () => {
      const res = await queryRefundStatus({
        id: formState.item_id,
      });
      if (res.code == 0) {
        console.log("121212", res);
        is_refund.value = res.is_refund.is_refund;
        if (is_refund.value == 1 || is_refund.value == 2) {
          currentTab.value = 1;
          check_reason.value = res.is_refund.check_reason;
        } else if (is_refund.value == 3) {
          currentTab.value = 2;
        }
      }
    };
    onMounted(() => {
      console.log(JSON.parse(decodeURIComponent(route.query.data)));
      formState.refund_price = JSON.parse(
        decodeURIComponent(route.query.data)
      ).refund_price;
      formState.item_id = JSON.parse(
        decodeURIComponent(route.query.data)
      ).item_id;
      // is_refund.value = JSON.parse(decodeURIComponent(route.query.data)).is_refund
      getStatus();
    });
    return {
      formRef,
      formState,
      check_reason,
      rules,
      onSubmit,
      currentTab,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      change,
      is_refund,
      getStatus,
    };
  },
};
</script>
<style scoped lang="less">
.content {
  width: 100%;
  .content_detaisl {
    width: 1000px;
    min-height: 600px;
    margin: 0 auto;
    padding: 19px 0;

    .xtx-bread {
      font-size: 12px;
      color: #808695;
    }
    .detaisl_son {
      width: 100%;
      height: 600px;
      background: #ffffff;
      margin-top: 12px;
      padding: 50px 76px;
      .son {
        display: flex;
        justify-content: center;
      }
      .step {
        height: 103px;
        width: 100%;
      }
    }
  }
}
.result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
