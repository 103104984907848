<template>
  <div class="evaluate">
    <div class="evaluate_header">我的评价</div>
    <div class="evaluate_content">
      <div class="content_header">
        <div class="header_son">评价内容</div>
        <div class="header_son">评价产品</div>
      </div>
      <div class="evaluate_list" v-for="(item, index) in List" :key="index">
        <div class="evaluate_list_son1">
          <p class="evaluate_list_son1_left">
            <img src="@/assets/images/evaluate1.1.png" alt="" srcset="" />
            <span>{{
              item.type == 1 ? "好评" : item.type == 2 ? "中评" : "差评"
            }}</span>
          </p>
          <p class="evaluate_list_son1_left">
            <span>{{ item.content }}</span>
            <span style="font-size: 10px; color: #808695">{{
              item.create_at
            }}</span>
          </p>
        </div>
        <div class="evaluate_list_son2">
          <p style="color: #296df1">{{ item.name }}</p>
          <p style="color: #ff3c31; margin-top: 5px">
            ￥{{ item.price_selling }}-{{ item.max_price_selling }}
          </p>
        </div>
      </div>
      <XtxPagination
        v-if="total"
        :total="total"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="getCurrentPage"
      />
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { myCommentList } from '@/api/user'
export default {
  components: {},
  setup () {
    // 评论总条数
    const total = ref()
    // 当前页码
    const currentPage = ref(1)
    //一页几条
    const pageSize = ref(15)
    const getCurrentPage = val => {
      // console.log('当前子组件的页面为', val)
      currentPage.value = val
      getList()
    }
    const List = ref([])
    const getList = async () => {
      const res = await myCommentList({
        page: currentPage.value,
        pagesize: pageSize.value
      })
      List.value = res.data.list
      total.value = res.data.total
    }
    onMounted(() => {
      getList()
    })
    return { total, currentPage, pageSize, getCurrentPage, List, getList }
  }
}
</script>
<style scoped lang="less">
.evaluate {
  width: 940px;
  min-height: 642px;
  .evaluate_header {
    width: 100%;
    height: 56px;
    background: #ffffff;
    padding: 16px 40px;
    font-size: 16px;
  }
  .evaluate_content {
    width: 100%;
    min-height: 642px;
    background: #ffffff;
    margin-top: 6px;
    padding: 30px 40px;
    .content_header {
      width: 100%;
      height: 39px;
      background: #f8f8f9;
      border: 1px solid #f1f1f4;
      display: flex;
      color: #515a6e;
      .header_son {
        width: 50%;
        height: 100%;
        padding-left: 125px;
        display: flex;
        align-items: center;
      }
    }
  }
}
.evaluate_list {
  width: 100%;
  min-height: 69px;
  border: 1px solid #f1f1f4;
  border-top: none;
  display: flex;
  .evaluate_list_son1 {
    width: 50%;
    display: flex;
    align-items: center;
    padding-left: 42px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 60px;
    }
    .evaluate_list_son1_left {
      display: flex;
      flex-direction: column;
    }
  }
  .evaluate_list_son2 {
    width: 50%;
    padding-left: 125px;
    display: flex;
    // align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
</style>