<template>
  <div class="goods-image">
    <!-- 放大图 -->
    <div class="large" v-show="!isOutside">
      <img
        :src="images[activeIndex]"
        alt=""
        :style="{ left: largePos.x, top: largePos.y }"
      />
    </div>
    <!-- 主图 -->
    <div class="middle" ref="target">
      <img
        :src="images[activeIndex]"
        alt=""
        style="width: 100%; height: 100%"
      />
      <!-- 主图上方的遮罩盒子 -->
      <div
        class="layer"
        v-show="!isOutside"
        :style="{ left: layerPos.x, top: layerPos.y }"
      ></div>
    </div>
    <!-- 图片列表 -->
    <!-- <div class="small">
      <img
        src="@/assets/images/arrow_left@2x.png"
        alt=""
        class="arrow_img"
        @click="curIdx = 0"
        :class="{ disabled: curIdx === 0 }"
      />
      <ul class="small_son" :style="tranX">
        <li
          v-for="(item, index) in images"
          :key="index"
          @mouseenter="activeIndex = index"
          :class="{ fade: activeIndex === index }"
        >
          <img :src="item" alt="" style="width: 88px; height: 88px" />
        </li>
        <li
          v-for="(item, index) in images"
          :key="index"
          @mouseenter="activeIndex = index"
        >
          <img :src="item" alt="" style="width: 88px; height: 88px" />
        </li>
        <li
          v-for="(item, index) in images"
          :key="index"
          @mouseenter="activeIndex = index"
        >
          <img :src="item" alt="" style="width: 88px; height: 88px" />
        </li>
        <li
          v-for="(item, index) in images"
          :key="index"
          @mouseenter="activeIndex = index"
        >
          <img :src="item" alt="" style="width: 88px; height: 88px" />
        </li>
      </ul>
      <img
        src="@/assets/images/arrow_right@2x.png"
        alt=""
        class="arrow_img"
        @click="curIdx = 1"
        :class="{ disabled: curIdx === 1 }"
      />
    </div> -->
    <div class="slider">
      <span class="btn" @click="last">
        <img
          src="@/assets/images/arrow_left@2x.png"
          alt=""
          class="arrow_img"
          style="margin-right: 10px"
        />
      </span>
      <div style="overflow: hidden; width: 380px">
        <ul class="slide-transform-warp" :style="{ left: activeLeft + 'px' }">
          <li
            v-for="(item, index) in images"
            :key="index"
            class="slide-item"
            :class="{ active: activeIndex == index }"
            @mouseenter="activeIndex = index"
          >
            <img :src="item" alt="" style="width: 88px; height: 88px" />
          </li>
        </ul>
      </div>
      <span class="btn" @click="next">
        <img
          src="@/assets/images/arrow_right@2x.png"
          alt=""
          class="arrow_img"
          style="margin-left: 10px"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { useMouseInElement } from '@vueuse/core'
import { reactive, ref, watch, computed } from 'vue'
export default {
  name: 'GoodsImage',
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const activeIndex = ref(0)
    const activeLeft = ref(0)
    const num = ref(0)
    const target = ref(null)
    // console.log(target)
    // elementX/elementY    鼠标在盒子内的坐标
    // isOutside            表示目标元素外面/里面
    const { elementX, elementY, isOutside } = useMouseInElement(target)

    // 定义响应式数据，更新数据的时候更新视图
    const layerPos = reactive({ x: 0, y: 0 })
    const largePos = reactive({ x: 0, y: 0 })

    // 监听鼠标的两个坐标值
    watch([elementX, elementY], () => {
      // -100 鼠标和盒子中心对齐
      let x = elementX.value - 100
      let y = elementY.value - 100

      // 上右下左边界判断
      if (y < 0) y = 0
      if (x < 0) x = 0
      if (y > 200) y = 200
      if (x > 200) x = 200

      // 遮罩盒子坐标，注意 px 单位
      layerPos.x = x + 'px'
      layerPos.y = y + 'px'

      // 大图坐标， -1 反方向移动，2 是两倍比例移动
      largePos.x = -1 * 2 * x + 'px'
      largePos.y = -1 * 2 * y + 'px'
    })
    const curIdx = ref(0)
    // 为什么想到再去定义一个变量呢,有个禁用,肯定要有个值作比较
    const tranX = computed(() => {
      const t = -1240 * curIdx.value  // 1240是我定义的版心宽度，具体看你版心定义的多少了
      return `transform:translateX(${t}px)`
    })
    const last = () => {
      num.value++
      if (activeLeft.value > 113 * (props.images.length - 2)) {
        activeLeft.value = 0
        num.value = 0
      } else {
        activeLeft.value = 113 * num.value
      }
      // console.log(activeLeft.value)
    }
    const next = () => {
      num.value--
      if (activeLeft.value < -113 * (props.images.length - 2)) {
        activeLeft.value = 0
        num.value = 0
        // console.log(activeLeft.value)
      } else {
        activeLeft.value = 113 * num.value
        // console.log(activeLeft.value)
      }

    }
    return {
      activeIndex,
      target,
      isOutside,
      layerPos,
      largePos,
      curIdx,
      tranX,
      last,
      next,
      activeLeft,
      num
    }
  }

}
</script>

<style scoped lang="less">
.goods-image {
  width: 440px;
  height: 440px;
  position: relative;
  // display: flex;
  // 大图预览
  .large {
    position: absolute;
    top: 0;
    left: 432px;
    width: 440px;
    height: 440px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-size: 800px 800px;
    background-color: #f8f8f8;
    overflow: hidden;
    z-index: 401;
    img {
      max-width: 800px;
      max-height: 800px;
      width: 800px;
      height: 800px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .middle {
    width: 440px;
    height: 440px;
    // background: #f5f5f5;
    position: relative;
    cursor: move;
    .layer {
      width: 200px;
      height: 200px;
      background: rgba(0, 0, 0, 0.2);
      left: 0;
      top: 0;
      position: absolute;
    }
  }
  .small {
    margin-top: 20px;
    width: 440px;
    display: flex;
    align-items: center;
    // overflow: hidden;
    .small_son {
      width: 400px;
      display: flex;
    }

    li {
      width: 88px;
      height: 88px;
      // margin-bottom: 15px;
      img {
        width: 100%;
        height: 100%;
      }
      cursor: pointer;
      &:hover,
      &.active {
        border: 2px solid @xtxColor;
      }
    }
  }
}
.slider {
  margin-top: 20px;
  width: 440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0 20px;
  .btn {
    cursor: pointer;
    width: 20px;
    height: 88px;
    .arrow_img {
      width: 20px;
      height: 88px;
    }
  }
}
.slide-transform-warp {
  margin: 0;
  white-space: nowrap;
  transition: left 0.5s ease;
  position: relative;
  // left: 0;
}
.slide-transform-warp .slide-item {
  cursor: pointer;
  display: inline-block;
  width: 88px;
  height: 88px;
  margin-right: 10px;
  &.active {
    border: 1px solid #00be7b;
  }
}
</style>
