<template >
  <div style="padding: 30px 40px; background: #f8f8f9">
    <p style="text-align: center; font-weight: 700px; font-size: 32px">
      {{ name }}
    </p>
    <div
      v-if="type == 2"
      v-html="content"
      style="font-size: 18px; margin: 0 auto; margin-top: 20px; width: 1124px"
    ></div>
    <div v-if="type == 1" class="files">
      <p v-for="(item, index) in fileArry" :key="index">
        <a :href="item.attach" target="_blank" style="color: #1890ff">
          {{ item.filename }}.{{ suffix(item.attach) }}
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'
import { lawDetail } from '@/api/FirstPage'
export default {
  setup () {
    const content = ref('')
    const name = ref('')
    const type = ref('')
    const id = ref('')
    const route = useRoute()
    const fileArry = ref([])
    const getDetails = async () => {
      const res = await lawDetail({
        type: type.value,
        id: id.value
      })
      if (res.code == 0) {
        if (type.value == 2) {
          content.value = res.data.detail
          name.value = res.data.name
        } else {
          fileArry.value = res.data.list
          name.value = res.data.name

        }
      }
    }
    const suffix = (content) => {
      var pos = content.split('/')
      var filename = pos.slice(pos.length - 1, pos.length).toString(String).split('.')[1]
      return filename
      // console.log(filename)
    }
    onMounted(() => {
      console.log(route.params)
      type.value = route.params.type
      id.value = route.params.id
      getDetails()
    })
    return {
      content,
      id,
      type,
      name,
      fileArry,
      suffix
    }
  }
}
</script>
<style scoped lang="less">
.files {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  p {
    line-height: 48px;
  }
}
</style>